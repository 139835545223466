export const totem = [
	{
		'widget': 'DoubleIcon',
		'size': 'full',
		'icon': 'totem',
		'icon2': 'bell-on',
		'label': 'Összes totem / Aktív totemek',
		'value': '0 / 0',
		'unit': '',
	},
	{
		'widget': 'IconValue',
		'size': 'half',
		'value': "0",
		'icon': 'bolt',
		'unit': '',
		'label': 'Feszültség alatt'
	},
	{
		'widget': 'IconValue',
		'size': 'half last',
		'value': "0",
		'icon': 'network-wired',
		'unit': '',
		'label': 'Aktív adatkapcsolat'
	},
	{
		'widget': 'IconValue',
		'size': 'full',
		'value': "0",
		'icon': 'lock-alt',
		'unit': '',
		'label': 'Ajtó zárva'
	},
	{
		'widget': 'Divider',
		'size': 'full',
		'label': ''
	},
	{
		'widget': 'IconValue',
		'size': 'half',
		'value': "0",
		'icon': 'thermometer-three-quarters',
		'unit': '°C',
		'label': 'Belső hőmérséklet'
	},
	{
		'widget': 'IconValue',
		'size': 'half last',
		'value': "0",
		'icon': 'stopwatch',
		'unit': 'P',
		'label': 'Belső nyomás'
	},
	{
		'widget': 'IconValue',
		'size': 'full',
		'value': "0",
		'icon': 'humidity',
		'unit': '%',
		'label': 'Belső páratartalom'
	},
];