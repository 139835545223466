import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import locales from './locales';
// not like to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init

i18n
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
  	react: { 
      useSuspense: false,
      wait: true
    },
    fallbackLng: {
    	default: ['hu']
    },
    lng: 'hu',
    defaultNS: "common",
    keySeparator: false,
    debug: false,
    
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });

i18n.addResources("hu", "common", locales.hu.common);
i18n.addResources("en", "common", locales.en.common);


export default i18n;