import React from 'react';
import { Link } from 'react-router-dom';

const MissingRoute = () => (
    <div style={{ position: 'fixed', height: '100%', width: '100%' }}>
        <div style={{ position: 'relative', height: '100%', width: '100%' }}>
            <div style={{ 'position': 'absolute', 'height': '270px', 'top': '50%', 'marginTop': '-175px', 'textAlign': 'center', 'width': '100%' }}>
                <h1 style={{ 'fontSize': '10em', 'fontWeight': 'bold', 'color': 'white', 'textShadow': '1px 1px 16px #1f3551' }}>404</h1>
                <h2 style={{ 'fontSize': '2em', 'fontWeight': 'bold', 'color': '#1f3551' }}>Page not found</h2>
                <p>The page you are looking for doesn&apos;t exist or an other error occurred.</p>
                <p>Go to the <Link to="/">Home</Link> page to choose a new direction</p>
            </div>
        </div>
    </div>
);

export default MissingRoute;
