import Config from "../config/config";
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const apikey = cookies.get("apikey");

export class ThirdPartyController {

    private static instance: ThirdPartyController;

    /**
     * Can't create the object without the instance method to prevent direct init
     */
    private constructor() {
    }

    /**
     * Controlling the singleton
     */
    public static getInstance(): ThirdPartyController {
        if (!ThirdPartyController.instance) {
            ThirdPartyController.instance = new ThirdPartyController();
        }
        return ThirdPartyController.instance;
    }

    public getThirdPartyResources() {

    }

    public getAllThirdPartyResources() {
        const url = Config.endpoints.base + Config.endpoints.list.thirdparty.getAll;

        return new Promise((resolve, reject) => {
            fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'DOLAPIKEY': apikey,
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Credentials": "true",
                    "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
                },
                method:"GET",
            }).then((res) => {
                if(res.status === 200) {
                    return res.json()
                }else {
                    reject({statusCode:500, data:"Failed to fetch data"})
                }
            }).then(response => {
                resolve({statusCode:200, data:response})
            })
        })
    }

    public createThirdPartyResource(data:any) {
        const url = Config.endpoints.base + Config.endpoints.list.thirdparty.create;

        return new Promise((resolve, reject) => {
            fetch(url, {
                method:"POST",
                headers: {
                    'DOLAPIKEY': apikey,
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Credentials": "true",
                    "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
                },
                body:data
            }).then((res) => {
                if(res.status === 200) {
                    return res.json()
                }else {
                    reject({statusCode:500, data:"Failed to fetch data"})
                }
            }).then(response => {
                resolve({statusCode:200, data:response})
            });
        });
    }

    public updateThirdPartyResource(id:string) {
    
    }

    public deleteThirdPartyResource(id:string) {
    
    }

    public getThirdPartyFormOptions() {
        const url = Config.endpoints.base + Config.endpoints.list.thirdparty.formdata.get;

        return new Promise((resolve, reject) => {
            fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'DOLAPIKEY': apikey,
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Credentials": "true",
                    "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
                },
                method:"GET",
            }).then((res) => {
                if(res.status === 200) {
                    return res.json()
                }else {
                    reject({statusCode:500, data:"Failed to fetch data"})
                }
            }).then(response => {
                resolve({statusCode:200, data:response})
            })
        })
    }
    
};
