import React , { useState, useEffect } from 'react';
import BrowseLayout from '../../screens/layouts/BrowseLayout';

import connect from 'react-redux/es/connect/connect';
import {getLoadingData, toggleView, getLoadingMessage} from '../../state/reducers/ui';
import DashboardBox from "../../components/dashboard/DashboardBox";
import { Row, Col } from "react-bootstrap";

function DashboardMobileApp(props){
	props.toggleViewState("mobileapp");

	return (
		<BrowseLayout>
			<div className="dashboard-container dashboard-connection-container">
				<Row className="dasboard-container-row">
					<Col className="col-log col-lg-3 col-md-4 col-sm-12">
						<a href={'https://hibabejelento.bekescsaba.manageyourcity.com/#/'} target="_blank" rel="noopener noreferrer">
							<DashboardBox title="" type="" body="<p style='margin-top: 70px;'><img src='logos/googleplay.jpg' style='width: 80px;' /><img src='logos/appstore.jpg' style='width: 80px; margin-left: 10px;' /></p>">
                                <div className="img"><img alt="OWLET" src="logos/LOG_logo1080.png" /></div>
							</DashboardBox>
						</a>
					</Col>
					<Col className="col-survey col-lg-3 col-md-4 col-sm-12">
						<a href={'https://hibabejelento.bekescsaba.manageyourcity.com/#/'} target="_blank" rel="noopener noreferrer">
							<DashboardBox title="" type="" body="<p style='margin-top: 20px;'>Az applikáció Android rendszerű mobileszkozokre telepíthető. A folyamathoz a külső forrásból származó program telepítését engedélyezni kell, melyet a legtöbb típus esetében a fejlesztői menü aktiválás után lehetséges.</p>">
                                <div className="img"><img alt="OWLET" src="logos/SURVEY_logo.png" /></div>
							</DashboardBox>
						</a>
					</Col>
				</Row>
			</div>
		</BrowseLayout>
	);
};

DashboardMobileApp.propTypes = {};

const mapStateToProps = state => ({
  	loading : getLoadingData(state),
	loadingMessage: getLoadingMessage(state)
});

const mapDispatchToProps = (dispatch) => ({
	toggleViewState: (id) => {
	  dispatch(toggleView(id));
	}
  });

export default connect(
    mapStateToProps,
	mapDispatchToProps
)(DashboardMobileApp);
