import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { history } from '../../state/store';

const DoubleIcon = ({label, size, value, unit, icon, icon2, color, detailedRoute}) => {

	function handleRouting() {
		let type = "/10";

		if(label === "Hőmérséklet") {
			type = "/11";
		}else if(label === "Pára") {
			type = "/12";

		}else if(label === "PM10") {
			type = "/10";
		}

		history.push(detailedRoute + type)
	}


    return (
       <div className={'double-icon widget justify-content-center align-items-center '+ size } onClick={handleRouting}>
       	<div className="display justify-content-center align-items-center">
       	<i className="icon" style={{color:color}}>
       		<FontAwesomeIcon icon={['fal', icon]} />
       	</i>
       	<div className="value">{value} {unit}</div>
       	<i className="icon-right" style={{color:color}}>
       		<FontAwesomeIcon icon={['fal', icon2]} />
       	</i>
       	</div>
       	
       	<span>{label}</span>
       </div>
    );
};

DoubleIcon.propTypes = {};

export default DoubleIcon;