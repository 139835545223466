export const BSmart = {
    layers: [
        {
            path: 'Bsmart/01.json',
            stroke: {
                color: 'rgba(185,0,0,1)',
                width: 1.4
            }
        },
        {
            path: 'Bsmart/02.json',
            stroke: {
                color: 'rgba(185,0,0,1)',
                width: 1.4
            }
        },
        {
            path: 'Bsmart/03.json',
            stroke: {
                color: 'rgba(211,103,237,1)',
                width: 1.4
            }
        },
        {
            path: 'Bsmart/04.json',
            stroke: {
                color: 'rgba(233,141,196,1)',
                width: 1.4
            }
        },
        {
            path: 'Bsmart/05.json',
            stroke: {
                color: 'rgba(2,205,160,1)',
                width: 1.4
            }
        },
        {
            path: 'Bsmart/06.json',
            stroke: {
                color: 'rgba(0,0,0,1)',
                width: 1.4
            }
        },
        {
            path: 'Bsmart/07.json',
            stroke: {
                color: 'rgba(255,227,0,1)',
                width: 1.4
            }
        },
        {
            path: 'Bsmart/08.json',
            stroke: {
                color: 'rgba(99,153,218,1)',
                width: 0.46
            },
            fill: 'rgba(99,153,218, 0.5)'
        },
        {
            path: 'Bsmart/09.json',
            stroke: {
                color: 'rgba(33,127,155,1)',
                width: 0.46
            },
            fill: 'rgba(33,127,155, 0.5)'
        },
        {
            path: 'Bsmart/10.json',
            stroke: {
                color: 'rgba(0,255,255,1)',
                width: 0.46
            },
            fill: 'rgba(0,255,255, 0.5)'
        },
        {
            path: 'Bsmart/11.json',
            stroke: {
                color: 'rgba(105,65,10,1)',
                width: 1
            }
        },
        {
            path: 'Bsmart/12.json',
            stroke: {
                color: 'rgba(172,172,172,1)',
                width: 1
            }
        },
        {
            path: 'Bsmart/13.json',
            stroke: {
                color: 'rgba(217,217,217,1)',
                width: 1
            }
        }
    ],
    markers: [
        {
            name: 'lights',
            hightlightfill: 'rgba(0,255,255, 0.2)',
            icon: {
                
                anchor: [0.5,1],
                src: 'markers/lights.png',
                scale: 1,
                opacity: 1,
            },
            smart_ready: {
               
                anchor: [0.5,1],
                src: 'markers/lights_smart_ready.png',
                scale: 1,
                opacity: 1,
            },
            smart: {
                
                anchor: [0.5,1],
                src: 'markers/lights_smart.png',
                scale: 1,
                opacity: 1,
            },

            icon_highlight: {
                
                anchor: [0.5,1],
                src: 'markers/lights_h.png',
                scale: 1,
                opacity: 1,
            },
            smart_ready_highlight: {
               
                anchor: [0.5,1],
                src: 'markers/lights_smart_ready_h.png',
                scale: 1,
                opacity: 1,
            },
            smart_highlight: {
                
                anchor: [0.5,1],
                src: 'markers/lights_smart_h.png',
                scale: 1,
                opacity: 1,
            },
            clustericon: {
                
                anchor: [0.5,1],
                src: 'markers/lights_cluster.png', 
                scale: 1,
                opacity: 1,
            },
            clustericon_highlight: {
                
                anchor: [0.5,1],
                src: 'markers/lights_cluster_h.png', 
                scale: 1,
                opacity: 1,
            }
        },
        {
            path: 'data/smart_egyesitett.geojson',
            name: 'smart',
            icon: {
                anchor: [0.5,0.5],
                src: 'markers/wifi.png',
                scale: 1,
            },
            kkm_icon: {
                anchor: [0.5,0.5],
                src: 'markers/kkm.png',
                scale: 1,
            },
            tot_icon: {
                anchor: [0.5,0.5],
                src: 'markers/tot.png',
                scale: 1,
            },
            osz_icon: {
                anchor: [0.5,0.5],
                src: 'markers/ksz.png',
                scale: 1,
            },
            tfk_icon: {
                anchor: [0.5,0.5],
                src: 'markers/tfk.png',
                scale: 1,
            },
            clustericon: {
                anchor: [0.5,0.5],
                src: 'markers/wifi_cluster.png',
                scale: 1,
            }
        },
    ]
};

