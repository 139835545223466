import React, {useState, useEffect} from 'react';
import connect from 'react-redux/es/connect/connect';
import {Row, Col, Modal, Button} from "react-bootstrap";
import Config from '../../config/config';

//Map imports
import VectorSource from 'ol/source/Vector';
import OlSourceXYZ from 'ol/source/XYZ';
import OlMap from "ol/Map";
import OlView from "ol/View";
import OlLayerTile from "ol/layer/Tile";
import {defaults as defaultInteractions, DragRotateAndZoom} from 'ol/interaction';
import {fromLonLat} from 'ol/proj';
import VectorLayer from 'ol/layer/Vector';
import { dynamicCadastreStyleFunction } from '../Map/_styles/styles'
import {getUserCadastreData} from '../../state/reducers/user';

function NewProject(props) {

	const [center, setCenter] = useState(Config.map.center); // eslint-disable-line
	const [zoom, setZoom] = useState(Config.map.zoom); // eslint-disable-line
	const [mapLoaded, setMapLoaded] = useState(false);
	const [olmap, setOlmap] = useState({});
 
	let url = 'https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png';
	
	//Setup layers
    const layers = {};
    layers.tile = {};
    layers.tile.source = new OlSourceXYZ({ url });
    layers.tile.layer = new OlLayerTile({
        source: layers.tile.source
	});

	layers.features = {};
	layers.features.source = new VectorSource({crossOrigin: 'Anonymous'});
	layers.features.layer = new VectorLayer({
		source: layers.features.source,
	});


    useEffect(() => {
		let olmap = new OlMap({
			interactions: defaultInteractions().extend([
				new DragRotateAndZoom()
			]),
			target: null,
			layers: [
				layers.tile.layer
			],
			view: new OlView({
				center: fromLonLat(center),
				zoom: zoom,
			})
		});
		setOlmap(olmap);
		setMapLoaded(true);

	}, []) // eslint-disable-line
	
	useEffect(() => {
		if(mapLoaded) {
			olmap.setTarget("inner-map2");

			// props.highlighted.forEach(feature => {
			// 	const layerData = Config.layers[feature.geometryName_ === "geometry" ? "lights" : feature.geometryName_]

			// 	const style = new Style({
			// 		image: layerData.marker ? new Icon(layerData.marker) : null
			// 	});

			// 	feature.setStyle(style)
			// })

			layers.features.source.addFeatures(props.highlighted);
			layers.features.layer.setStyle(dynamicCadastreStyleFunction(props.cadastreData))
			olmap.addLayer(layers.features.layer);
		}
	}, [mapLoaded]) // eslint-disable-line

	return (
		<Modal show={props.show} onHide={() => props.setShow(false)} dialogClassName="new-project-modal">
			<Modal.Header closeButton>
				<Modal.Title>Új projekt létrehozása</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="box" style={{backgroundColor: 'white',textAlign: 'left',padding: '25px'}}>
					<Row>
						<Col xs={6}>
							<h3 style={{marginBottom: '20px'}}>Új Projekt</h3>
								<Row className="form-group">
									<Col md={6}>
										<label>Projekt kezdete:</label>
										<input type="date" className="form-control" />
									</Col>
									<Col md={6}>
										<label>Projekt vége:</label>
										<input type="date" className="form-control" />
									</Col>
								</Row>
							<div className="form-group">
								<label>Projekt neve</label>
								<input type="text" className="form-control" />
							</div>
							<div className="form-group">
							<label>Beavatkozás típusa</label>
								<input type="text" className="form-control" />
							</div>
							<div className="form-group">
							<label>Felelős</label>
								<select className="form-control">
									<option>User1</option>
									<option>User2</option>
									<option>User3</option>
								</select>
							</div>
							<div className="fileuploader">
								<div className="dropzone">
									{/* <DropZoneUpload /> */}
									<div>Húzza ide a projekthez tartozó fontosabb dokumentumokat</div>

								</div>
							</div>
						</Col>
						<Col md={6}>
							<div id="inner-map2" className="inner-map2" style={{height:"450px", width:"100%"}}/>
						</Col>
						<Col md={12} style={{marginTop: '25px'}}>
							<Button variant="primary" size="block">Project létrehozása</Button>
						</Col>	
				</Row>
			</div>
			</Modal.Body>                
		</Modal>
	);
}
const mapStateToProps = state => ({
	cadastreData : getUserCadastreData(state),
});

const mapDispatchToProps = dispatch => ({
});

export default connect(
    mapStateToProps,
  mapDispatchToProps
)(NewProject);