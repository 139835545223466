import React, {useState} from 'react';
import { Button,  Modal } from 'react-bootstrap';
import { MessageHandler } from '../../../controllers/MessageHandlerController.ts';
import moment from 'moment';
import { TaskUpdateController } from '../../../controllers/TaskUpdateController';
import connect from 'react-redux/es/connect/connect';

function ReportProblemPopup(props) {

    const [comment, setComment] = useState("");
    const [feedback, setFeedback] = useState({});

    const MessageHandlerInst = MessageHandler.getInstance();
    const TaskUpdateControllerInst = TaskUpdateController.getInstance();

    function handleReport() {
        saveComment();
        updateTask();
    }

    function saveComment() {
        let task = [props.currentTask.task];

        MessageHandlerInst.createMessage(comment, task, "").then(response => {

            if(response.statusCode === 200) {
                let temp = {};
                let payload = response.data.data
                temp[payload.id] = {
                    user_email: payload.user_email,
                    message: payload.message,
                    ticket_id: payload.ticket_id,
                    created_at: payload.created_at,
                    child_notes: []
                }
                setComment("");
                setFeedback({message:"Probléma  bejelentve.", color:"#6dcf77"});
            }else {
                setFeedback({message:"Probléma bejelentése sikertelen.", color:"#cf6d6d"});

                console.log("Sikertelen hozzászólás",  response)
            }
        })

    }

    function updateTask() {

        const data = {};

        const attribute = 'done';
        data[attribute] = 2;

        if(data[attribute]) {
            data[attribute + "_by"] = sessionStorage.username;
            data[attribute + "_at"] = moment().format("YYYY-MM-DD HH:mm:ss");
        }

        TaskUpdateControllerInst.updateTask(props.currentTask.project, props.currentTask.task, data).then(res => {
            if(res.statusCode === 200) {
                props.updateTask(props.currentTask.id, attribute, data[attribute]);
                props.update(true);
            }else {
                setFeedback({message:"Probléma bejelentése sikertelen.", color:"#cf6d6d"});

            }
        });
    }

    return(
        <Modal className="confirm" show={props.show} onHide={() => props.setShow(false)} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header>
                Probléma bejelentése
            </Modal.Header>
            <Modal.Body>
                <div>
                <p> <span  className="task-update-validate">Sorszám:</span> <span  className="task-update-validate-value">{props.currentTask !== null ? props.currentTask.task : "Invalid"}</span></p>
                </div>
                <br />
                <p>Megjegyzés: </p>
                <div className="comment">
                    <textarea rows="3" className="form-control" onChange={e => setComment(e.target.value)} value={comment}></textarea>
                </div>
                {feedback.hasOwnProperty("message") &&
                <div style={{textAlign:"center", color:feedback.color, padding:"10px"}}>
                    <p>{feedback.message}</p>
                </div>}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="dark" onClick={() => {
                setComment("");
                props.setShow(false) 
                }}>Bezárás</Button>
                <Button variant="primary" style={{backgroundColor:"#ff3d3d"}} onClick={() => {
                setComment("");
                handleReport();
                }} disabled={!comment.length}>{"Bejelentés"}</Button>
            </Modal.Footer>
        </Modal>
    );
}

const mapDispatchToProps = (dispatch) => ({});
export default connect(
    null,
    mapDispatchToProps
)(ReportProblemPopup);