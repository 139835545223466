import React from 'react';

const Divider = ({label,size, value, unit, icon, color}) => {

    return (
       <div className={'divider widget justify-content-center align-items-center '+ size }>
       	<span>{label}</span>
       </div>
    );
};

Divider.propTypes = {};

export default Divider;