import React from 'react';

const Footer = () => {
    const currentYear = (new Date()).getFullYear();

    return (
        <footer>
            <div className="container">
                <p className="copyright">&copy; SmartCity {currentYear}</p>
            </div>
        </footer>
    );
};

Footer.propTypes = {};

export default Footer;
