import moment from 'moment';

export const project = ({lastImage, lightProgress}) => [
	{
		'widget': 'HorizontalProgress',
		'size': 'half',
		'icon': 'poll-h',
		'label': 'Tervezés',
		'value': '0',
		'unit': '%',
	},
	{
		'widget': 'HorizontalProgress',
		'size': 'half last',
		'icon': 'candle-holder',
		'label': 'Világítás',
		'value': lightProgress,
		'unit': '%',
	},
	{
		'widget': 'HorizontalProgress',
		'size': 'half',
		'icon': 'shovel',
		'label': 'Alépítmény',
		'value': '0',
		'unit': '%',
	},
	{
		'widget': 'HorizontalProgress',
		'size': 'half last',
		'icon': 'smart',
		'label': 'SMART',
		'value': '0',
		'unit': '%'
	},
	{
		'widget': 'HorizontalProgress',
		'size': 'full',
		'label': 'Utolsó feltöltés',
		'value': moment(lastImage.ts).format("YYYY-MM-DD HH:mm:ss"),
		'icon': 'clock'

	},
	{
		'widget': 'ImageWidget',
		'size': 'full',
		'url': lastImage.url
	}
];