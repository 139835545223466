import React from 'react';
import { Line } from 'react-chartjs-2'

export default function MultiLineChart(props) {

    const data = {
        labels: props.labels,
        datasets: [
          {
            label: props.labelName[1],
            data: props.data.min,
            fill: props.fill,
            backgroundColor: '#78D1FF',
            borderColor: "#78D1FF",
            yAxisID: 'y-axis-1',
          },
          {
            label: props.labelName[0],
            data: props.data.max,
            fill: props.fill,
            backgroundColor: '#FF7878',
            borderColor: '#FF7878',
            yAxisID: 'y-axis-1',
          },
          {
            label: props.labelName[2],
            data: props.data.avg,
            fill: false,
            backgroundColor: '#47BF3F',
            borderColor: '#47BF3F',
            yAxisID: 'y-axis-1',
          }
        ],
      }
      
      const options = {
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              type: 'linear',
              display: true,
              position: 'left',
              id: 'y-axis-1',
              ticks: {
                beginAtZero: true,
                min: 0,
              },
            },
          ],
        },
      }

    return(
        <div>
            <div className="chartinfo-multiline">
                <p>{props.title}</p>
                <div className="button-group">
                        {props.types.map(type => {
                            return(
                                <button onClick={() => props.setDateType(type.value)} style={{backgroundColor: props.dateType === type.value ? '#a1a1a1' : '#c9c9c9'}}>{type.title}</button>
                            );
                        })}
                    </div>
            </div>
            <div style={{height:"300px"}}>
                <Line data={data} options={options} height={300}/>
            </div>
        </div>
    );
}