import React from 'react';

const SimpleValueUnit = ({label,size, value, unit}) => {

    return (
       <div className={'simple-value-unit widget justify-content-center align-items-center '+ size }>
       	<div className="display justify-content-center align-items-center">
	       	<div className="value">{value}</div>
	       	<div className="unit">{unit}</div>
	       	<span>{label}</span>
       	</div>
       	
       </div>
    );
};

SimpleValueUnit.propTypes = {};

export default SimpleValueUnit;