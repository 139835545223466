import React, {useEffect, useState} from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import { MessageHandler } from '../../controllers/MessageHandlerController.ts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function CommentSection(props) {

    const [comment, setComment] = useState("");
    const [data, setData] = useState(null);
    const [warning, setWarning] = useState(false);
    const [edit, setEdit] = useState(0);
    const [update, setUpdate] = useState(false);
    const [feedback, setFeedback] = useState({});
    const [problem, setProblem] = useState(false);
    const MessageHandlerInst = MessageHandler.getInstance();


    useEffect(() => {
        setTimeout(() => {
            setFeedback({});
        }, [8000])
    }, [feedback])

    useEffect(() => {
        if(props.showCommentPopup) {
            MessageHandlerInst.getMessagesByTask([], props.currentTask.task).then((response) => {
                let temp = {};
    
                if(response.statusCode === 200) {
                    response.data.forEach((note) => {
                        if(!temp.hasOwnProperty(note.id)) {
                            temp[note.id] = {
                                user_email: note.user_email,
                                message: note.message,
                                ticket_id: note.ticket_id,
                                created_at: note.created_at,
                                child_notes: []
                            }
                        }
                    })
                }
                else if(response.statusCode === 404) {
                    setData({})
                }
                else {
                    console.log("warning", response)
                }
                setData(temp);
                setUpdate(false);
            }).catch(error => {
                setWarning("Loading comments failed. Please try again later.")
                setUpdate(false);
            });
        }
    }, [props.showCommentPopup, update])  // eslint-disable-line

    function saveComment() {
        let task = [props.currentTask.task];

        MessageHandlerInst.createMessage(comment, task, "").then(response => {

            if(response.statusCode === 200) {
                let temp = {};
                let payload = response.data.data
                temp[payload.id] = {
                    user_email: payload.user_email,
                    message: payload.message,
                    ticket_id: payload.ticket_id,
                    created_at: payload.created_at,
                    child_notes: []
                }
                setData({...data, ...temp})
                setComment("");
                setFeedback({message:"Megjegyzés sikeresen hozzáadva !", color:"#6dcf77"});
            }else {
                setFeedback({message:"Megjegyzés hozzáadása sikertelen !", color:"#cf6d6d"});

                console.log("Sikertelen hozzászólás",  response);
            }
        })

    }

    function editComment(messageId) {
        setFeedback({});
        let task = [props.currentTask.task];

        MessageHandlerInst.modifyMessage(comment, task, "", messageId).then(response => {
            if(response.statusCode === 200) {
                setUpdate(true);
                setComment('');
                setFeedback({message:"Megjegyzés sikeresen frissitve !", color:"#6dcf77"});

            }else{
                console.log("error", response);
            }
        })
        setEdit(0)
    }

    function deleteMessage(messageId) {
        setFeedback({});
        MessageHandlerInst.deleteMessage(messageId).then(response => {
            if(response.statusCode === 200) {
                setData(prev => {
                    let temp = Object.assign({}, prev)
                    delete temp[messageId];
                    return temp;
                });
            }else{
                console.log("response", response);
            }
        }) 
    }

    return(
        <React.Fragment>
            <Modal 
            show={props.showCommentPopup} 
            onHide={() => {props.setShowCommentPopup(false)}} 
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName="modal-90w"
            centered
            >
        {data !== null ?
        <div>
            <Modal.Header closeButton>
                Megjegyzések
            </Modal.Header>
            <Modal.Body>
                <div className="comment-modal">
                    {!Object.keys(data).length ?
                        <p>Még nincsenek megjelenitendő hozzászólások</p>
                    :
                    <div className="comment-container">
                        <ul>
                            {Object.entries(data).map(([key,value]) => {  // eslint-disable-line

                                if(edit) {
                                    if(edit === key) {
                                        return(<li key={key}>
                                        <p>{value.user_email}</p>
                                        <Form.Control className="comment-section-message-update" as="textarea" rows="1" onChange={evt => {
                                            setComment(evt.target.value)
                                        }}
                                        value={comment}
                                        />
                                    </li>
                                    );
                                    }
                                }else {
                                    return(
                                    <li key={key}>
                                        <p>{value.user_email}</p>
                                        <div className="comment-section-message">
                                            {value.message}
                                        </div>
                                        <div className="comment-actions">
                                            <span role="button" title="Üzenet szerkesztése" onClick={() => {
                                                setComment(value.message);
                                                setEdit(key);
                                            }} className="comment-section-message-edit"><FontAwesomeIcon size="lg" icon={['fas', 'pen']} />
                                            </span>
                                            <span role="button" className="comment-section-message-delete" title="Üzenet törlése" onClick={() => { deleteMessage(key)}}><FontAwesomeIcon size="lg" icon={['fas', 'trash']} />
                                            </span> 
                                        </div>

                                    </li>
                                    );
                                }
                            })}
                        </ul>
                    </div>
                    }
                    {!edit && <div className="new-comment">
                        <h3>Új megjegyzés</h3>
                        <div className="comment">
                            <textarea rows="3" className="form-control" onChange={e => setComment(e.target.value)} value={comment}></textarea>
                        </div>
                        <div style={{paddingTop:'10px'}}>
                            <p>Probléma bejelentés: <span style={{paddingLeft:"10px"}}><input type="checkbox" value={{problem}} onChange={() => {setProblem(!problem)}}/></span></p>
                        </div>
                        {feedback.hasOwnProperty("message") &&
                        <div style={{textAlign:"center", color:feedback.color, padding:"10px"}}>
                            <p>{feedback.message}</p>
                        </div>}
                    </div>}
                </div>
            </Modal.Body>
        </div>
        :
        <div className="loader-container">
            {warning && <p>{warning}</p> }
            <div className="loader"></div>
        </div>
        }
        <Modal.Footer>
        {!edit ?
            <div> <Button variant="secondary" onClick={() => {
                    setComment("");
                    setFeedback({});
                    props.setShowCommentPopup(false)
                }}>Bezárás</Button>
                <Button variant="primary" style={{backgroundColor:problem ? "#ff3d3d" : "#4caeef"}} onClick={() => {
                    setComment("");
                    saveComment();
                }} disabled={!comment.length}>{problem ? "Probléma bejelentése" : "Megjegyzés hozzáadása"}</Button>
            </div>
            :
            <div>
                <Button variant="secondary" onClick={() => {setEdit(0)}}>Mégse</Button>
                <Button variant="primary" style={{backgroundColor:problem ? "#ff3d3d" : "#4caeef"}} onClick={() => { editComment(edit) }} disabled={!comment.length}>Megjegyzés módositása</Button>
            </div>
            }
        </Modal.Footer>
        </Modal>
        </React.Fragment>
    )
}