import React, {useState, useEffect} from 'react';
import { withRouter } from 'react-router-dom'
import {getProjectState,getIsDataChanged, startMapSearch, setMapType, toggleDataChanged, closeSidebar, toggleView, getNavigationState} from '../../state/reducers/ui';
import {getUserRole} from '../../state/reducers/user';

import connect from 'react-redux/es/connect/connect';

//DataTable
import DataTableWrapper from "./ui/DataTableWrapper";
/**
 * Main Project komponent
 * 
 * Basic Functionality :
 *  - This component is responsible for the whole project view.
 * 	- The main structure is 2 nested data tables, filled with data from the JSON.
 * 
 * **/
function Project(props) {

	const [data, setData] = useState([]);
	function handleAction(row){
		const item = row.task;
		props.history.push('/map');
		props.selectedTask(item);
	}

	/**
	 * Load data by category
	 * **/
	function loadData() {
		const { tasks } = props;
		setData(tasks)
	}


	/**
	 * If project selected, we filter for that specific project
	 * If all selected load all tasks
	 * **/
	useEffect(() => {
		const { tasks } = props;
		setData(tasks);
	}, [props.tasks]) // eslint-disable-line

	//First mount useEffect
	useEffect(() => {
		const { closeSidebar, toggleView } = props;
		toggleView("project");
		closeSidebar();
		loadData();
	}, []) // eslint-disable-line

	const {userRole} = props;

	return (
		<React.Fragment>
			<DataTableWrapper data={data} userRole={userRole} navigateToMap={handleAction} fetchingData={props.fetchingData} limit={props.limit} page={props.page} setPage={props.setPage} setLimit={props.setLimit} maxPage={props.maxPage} totalTasks={props.totalTasks}/>
		</React.Fragment>
	);
}

const mapStateToProps = state => ({
	projectState: getProjectState(state),
	userRole: getUserRole(state),
	isDataChanged:getIsDataChanged(state),
	navigationState:getNavigationState(state),

});
const mapDispatchToProps = dispatch => ({
	selectedTask: (item) => {
		dispatch(startMapSearch(item));
	},
	setMapType: (type) => {
		dispatch(setMapType(type))
	},
  	closeSidebar: () => {
    	dispatch(closeSidebar());
	},
	toggleView: (id) => {
		dispatch(toggleView(id));
	},
	toggleDataChanged:() => {
		dispatch(toggleDataChanged());
	}
});
export default connect(
    mapStateToProps,
	mapDispatchToProps
)(withRouter(Project));


