import React, {useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import moment from 'moment';
import "../../styles/ui-elements/_input.scss";
import connect from 'react-redux/es/connect/connect';
import {getUserToken, getUserProjects, getUserRole, getUserName, getUserPass, addUserAuthWarning} from '../../state/reducers/user';
import {toggleDataChanged} from '../../state/reducers/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TaskUpdateController } from '../../controllers/TaskUpdateController';

/**
 * Button Helper Component for API call when a task is complete
 *
 * Basic Functionality :
 *  - Call API when clicked
 *  - Provide task ID, username/password for API to validate the request
 *  -
 *
 * **/
function AcceptButton(props) {
    const [show, setShow] = useState(false);
    const [validateShow, setValidateShow] = useState(false);
    const [warning, setWarning] = useState(""); // eslint-disable-line
    const [isLoading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    const role = props.role;
    const attribute = role === "PROJECTADMIN" ? 'done' : 'approved';

    const action = role === "PROJECTADMIN" ? props.row[attribute] ? "visszavonja" : "elfogadja" : props.row[attribute] ? "visszavonja" : "jóváhagyja";
    const actionPast = role === "PROJECTADMIN" ? !props.row[attribute] ? "visszavonta" : "elfogadta" : !props.row[attribute] ? "visszavonta" : "jóváhagyta";

    const TaskUpdateControllerInst = TaskUpdateController.getInstance();

    const handleClose = () => {
        setShow(false)

    };
    const handleShow = () => setShow(true);

    function validateRequest() {
        setValidateShow(true);
    }

    function handleClick() {
        setLoading(true);
        const data = {};

        const attribute = props.userRole === "PROJECTADMIN" ? 'done' : 'approved';
        data[attribute] = props.row[attribute] === 1 ? 0 : 1;

        if(data[attribute]) {
            data[attribute + "_by"] = sessionStorage.username;
            data[attribute + "_at"] = moment().format("YYYY-MM-DD HH:mm:ss");
        }

        TaskUpdateControllerInst.updateTask(props.row.project, props.row.id, data).then(res => {
            if(res.statusCode === 200) {
                setLoading(false);
                setSuccessMessage("Feladat sikeresen frissítve.");
            }else {
                setWarning("Hiba a feladat frissítése közben.");
                handleShow();
            }
        });
    }

    const AcceptButtonComplex = (props) => {
        let attribute = props.data.userRole === "PROJECTADMIN" ? 'done' : 'approved';
        if(props.data.userRole === 'PROJECTADMIN') {

           if( props.data.row[attribute] ) {
            return (<Button className="table-button" size="sm" title="Visszavonom" onClick={validateRequest} disabled={props.data.row['approved'] ? true : false}>
                <FontAwesomeIcon className="acceptIcon" size="1x" color="#ffffff" icon={['fas', 'times'] }/>   
            </Button>)
           } else {
            return (<Button className="table-button" size="sm" title="Jóváhagyom" onClick={validateRequest} disabled={props.data.row['approved'] ? true : false}>
                <FontAwesomeIcon className="acceptIcon" size="1x" color="#ffffff" icon={['fas', 'check-circle'] }/>   
            </Button>)
           }
        } else {
           if(props.data.row[attribute] ) {
            return (<Button className="table-button" size="sm" title="Visszavonom" onClick={validateRequest}>
                <FontAwesomeIcon className="acceptIcon" size="1x" color="#ffffff" icon={['fas', 'times'] }/>   
            </Button>)
           } else {
            return (<Button className="table-button" size="sm" title="Jóváhagyom" onClick={validateRequest} disabled={props.data.row['done'] ? false : true}>
                <FontAwesomeIcon className="acceptIcon" size="1x" color="#ffffff" icon={['fas', 'check-circle'] }/>   
            </Button>)
           }
        }
    }

    return(
        <div >
            
            <AcceptButtonComplex data={props} />

            <Modal className="confirm" show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    Sikeresen {actionPast} a feladatot !
                </Modal.Header>
                <Modal.Body>
                <p><span className="task-update-validate">LPT Azonosító:</span> <span className="task-update-validate-value">{props.row.id}</span></p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>Bezárás</Button>
                </Modal.Footer>
            </Modal>

            <Modal className="confirm" show={validateShow} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                Biztosan  {action} a feladatot ? 
                </Modal.Header>
                <Modal.Body>
                <p><span className="task-update-validate">LPT Azonosító:</span> <span className="task-update-validate-value">{props.row.id}</span></p>
                <p><span className="task-update-validate">Helyszín:</span> <span className="task-update-validate-value">{props.row.place}</span></p>
                <p><span className="task-update-validate">Feladat tipusa:</span> <span className="task-update-validate-value">{props.row.planned_part}</span></p>
                <p style={{textAlign:'center'}}><span style={{color:'green'}}>{successMessage}</span></p>
                </Modal.Body>
                <Modal.Footer>
                    {successMessage === '' ?
                    <div >
                       <Button variant="danger" onClick={() => {
                        setValidateShow(false)                   
                        setSuccessMessage("")
                        }}>Nem</Button>
                        <div className="divider"/>
                        <Button
                        variant="primary"
                        disabled={isLoading}
                        onClick={!isLoading ? handleClick : null}                    
                        >
                        {isLoading ? 'Adatok frissítése' : 'Igen'}
                        </Button>
                    </div>
                    :
                    <Button variant="danger" onClick={() => {
                        setValidateShow(false)                   
                        setSuccessMessage("")
                        }}>Bezár
                    </Button>
                    }
                    
                </Modal.Footer>
            </Modal>
        </div>
    );

}

const mapStateToProps = state => ({
    username: getUserName(state),
    userpass: getUserPass(state),
    userToken: getUserToken(state),
    projects: getUserProjects(state),
    userRole: getUserRole(state),
});

const mapDispatchToProps = (dispatch) => ({
    toggleDataChanged: () => {
        dispatch(toggleDataChanged());
    },
    addUserAuthWarning: (warning) => {
        dispatch(addUserAuthWarning(warning))
    },
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AcceptButton);
