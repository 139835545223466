import React from "react";
import { Button, Modal, Table } from 'react-bootstrap';
import moment from "moment";
/**
 * 
 * @param {show, setShow,  action, makeAction} props
 * - show - bool - show modal or not
 * - action - String - Type of action thats need to be validated.
 *   - Usage: Shown to user as String
 * - makeAction -  Function - The action call which we validating
 */

export default function validationPopUp(props) {
    return(
        <React.Fragment>
            <Modal className="confirm" show={props.show} onHide={props.setShow} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    {props.action}
                </Modal.Header>
                <Modal.Body>
                {props.type === "multiple" && 
                    <div className="table-responsive">
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                <th>Azonostók</th>
                                </tr>
                            </thead>
                            <tbody className="text-center tableBody">
                                {props.selectedTasks.map(task => {
                                    return(
                                        <tr key={task.id} className="text-center">
                                            <td>{task.lpt_id}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </div>}
                
                    {/* Setup validation if only comment changed, only date changed, or there are multiple actions  */}
                    {props.type === "comment" ? 
                    <>
                        <p><span className="task-update-validate">LPT Azonosító:</span> <span  className="task-update-validate-value">{props.currentTask !== null ? props.currentTask.id : ""}</span></p>
                        <p><span className="task-update-validate">Comment:</span> <span  className="task-update-validate-value">{props.comment}</span></p>
                    </>
                    :
                    
                    props.type === "date" ?
                    <>
                        <p><span className="task-update-validate">LPT Azonosító:</span> <span  className="task-update-validate-value">{props.currentTask !== null ? props.currentTask.id : ""}</span></p>
                        <p><span className="task-update-validate">Kezdete:</span> <span  className="task-update-validate-value">{props.startDate !== null ? props.startDate : props.currentTask !== null ? moment(props.currentTask.dateo).format("YYYY-MM-DD HH:mm:ss") : ""}</span></p>

                        <p><span className="task-update-validate">Vége:</span> <span  className="task-update-validate-value">{props.endDate !== null ? props.endDate : props.currentTask !== null ? moment(props.currentTask.datee).format("YYYY-MM-DD HH:mm:ss") : ""}</span></p>
                    </>
                    :
                    <>
                    {props.startDate !== null ?
                    <>
                    <h6>Új Dátum</h6>
                        <p><span className="task-update-validate">Kezdete:</span> <span  className="task-update-validate-value">{props.startDate !== null ? props.startDate : ""}</span></p>
                        <p><span className="task-update-validate">Vége:</span> <span  className="task-update-validate-value">{props.endDate !== null ? props.endDate : ""}</span></p>
                    </>
                    :
                    null
                    }
                    {props.comment !== null ?
                    <>
                        <h6>Komment</h6>
                        <p style={{}}>{props.comment}</p>
                    </>
                    :
                    null
                    }
                    {props.warning.length === 2 ? <p className="input-warning-validation">{"Nem történt változás, kérem kezdje újra a frissitést"}</p> : <p style={{textAlign:"center", color:"red"}}>{props.warning[0]}</p>}
                    </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => {props.setShow()}}>Nem</Button>
                    <Button variant="primary" disabled={props.warning !== undefined ? props.warning.length === 2 : true} onClick={() => {props.makeAction()}}>Igen</Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}