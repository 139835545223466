import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getNavigationState, toggleDraw, setMapInteraction, getSelectedMapInteraction } from '../../../state/reducers/ui';
import connect from 'react-redux/es/connect/connect';

function MapButton(props) {

	function toggleDraw(event) {
		if(props.download === undefined) {
			if(props.type) {
				props.setInteraction(props.type)
			}
		}
	    event.preventDefault();
	    props.toggle();
	}

	//Add extended class for button position if right <Details sidebar is active
	function modifyButtonClass() {
		if(props.mapState.isFiltering){
			if(props.mapState.projectSidebar) {
				return props.buttonClass + "_extended";
			}
			return props.buttonClass + "_search";

		}
		else{
			return props.buttonClass
		}
	}

	return (
        <div title={props.tooltip} className={modifyButtonClass()} onClick={toggleDraw} style={{backgroundColor: props.interaction === props.type ? "#e3e3e3" : " #f4f4f4"}}>
        	<i className="icon"><FontAwesomeIcon icon={props.icon} /></i>
        </div>
	    );
}

const mapStateToProps = state => ({
  mapState: getNavigationState(state),
  interaction: getSelectedMapInteraction(state)
});

const mapDispatchToProps = (dispatch) => ({
	toggleDraw: () => {
		dispatch(toggleDraw());
	},
	setInteraction: (interaction) => {
		dispatch(setMapInteraction(interaction));
	},
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MapButton);