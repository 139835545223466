import common_en from '../locales/en/common.json';
import common_hu from '../locales/hu/common.json';



// import validationEN from './validation.en.json';

export default {
    'en': {
        'common': common_en,
        
    },
    'hu': {
        'common': common_hu,
    }
};
