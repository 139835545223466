import React, {useEffect, useState} from 'react';
import connect from 'react-redux/es/connect/connect';
import { getSmartMode, getLoadingData, toggleView, getLoadingMessage, updateLoadedDynamicCadastreLayers, setLoadedCadastreLayers, setAllLoadedLayers, getMapLayerOptions, getNavigationState } from '../../state/reducers/ui';
import BrowseLayout from '../../screens/layouts/BrowseLayout';
import { getUserCadastreAuth, getUserSettings, setUserSettings, setUserFavCadastre, getUserCadastreData } from "../../state/reducers/user";

import { Row, Col, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import KataszterBox from "../_helper/kataszterBox";
import KataszterModal from "../_helper/kataszerModal";
import Kataszer from '../../components/Kataszter/kataszter'

import { KataszterController } from "../../controllers/KataszterController";
import { UserController } from '../../controllers/UserController';

const DashboardKataszter = (props) => {

    const [show, setShow] = useState(false);
    const [detailed, setDetailed] = useState(null);

    const [showall, setShowall] = useState(false);
    const [showGroup, setShowGroup] = useState(false);
    const [showProject, setShowProject] = useState(false);

    const [allCadastre, setAllCadastre] = useState([]);
    const [allCadastreGroups, setAllCadastreGroups] = useState([]);

    const [searchWord, setSearchWord] = useState("");

    const [favourite, setFavourite] = useState([]);

    const KataszterControllerInst = KataszterController.getInstance();
    const UserControllerInst = UserController.getInstance();

    useEffect(() => {

        let temp_settings = {...props.userSettings};
        UserControllerInst.saveSettings(temp_settings).then(response => {
            console.log("response", response);
        });
    }, [favourite]); // eslint-disable-line

    useEffect(() => {
        if(props.navigationState.activeCadastre !== null) {
            setDetailed(props.navigationState.activeCadastre)
        }
    }, [props.navigationState.activeCadastre]) // eslint-disable-line
    

    useEffect(() => {
        KataszterControllerInst.getAllCadastre().then(response => {
            if(response.statusCode === 200) {
                //Get only one from the response.
                let temp = [];
                response.data.forEach(cadastre => {
                    if(!temp.filter(x => x.cadastre_ref === cadastre.cadastre_ref).length) {
                        temp.push(cadastre);
                    }
                });
                setAllCadastre(temp.filter(x => Object.keys(props.cadastreAuth).includes(x.cadastre_ref)).sort((a,b) => a.cadastre_label.localeCompare(b.cadastre_label)));
            }
        });

        KataszterControllerInst.getAllCadastreGroups().then(response => {
            if(response.statusCode === 200) {
                setAllCadastreGroups(response.data)
            }
        });

        if(props.userSettings.cadastre.favourite) {
            setFavourite(props.userSettings.cadastre.favourite);
        }

        props.toggleViewState("kataszter");

    }, []) // eslint-disable-line

    function handleSelect(cadastre) {
        setSearchWord("");
        setDetailed(cadastre)
    }

    function getCadasters() {
        return allCadastre.filter(x => x.cadastre_label.toLowerCase().includes(searchWord.toLowerCase())).map(cadastre => {
            return <KataszterBox cadastre={cadastre} name={cadastre.cadastre_label} handleSelect={handleSelect} cadastres={[]} favourite={favourite} setFavourite={setFavourite} setUserFavCadastre={props.setUserFavCadastre}/>

        })
    }

    function getFavCadasters() {
        return allCadastre.filter(x => favourite.includes(x.cadastre_ref)).map((cadastre, index) => {
            return (
                    <KataszterBox key={index} cadastre={cadastre} name={cadastre.cadastre_label} handleSelect={handleSelect} cadastres={[]} favourite={favourite} setFavourite={setFavourite} setUserFavCadastre={props.setUserFavCadastre}/>
                )
        })
    }

    function setupCadastreGroups() {
        let groups = {};

        allCadastreGroups.forEach(group => {
            if(group.parent_id !== null) {
                if(group.parent_id in groups) {
                    groups[group.parent_id].childGroups.push(group);
                }else {
                    groups[group.parent_id] = {...allCadastreGroups.find(x => x.rowid === group.parent_id), childGroups:[]}
                }
            }
        });

        if(showGroup) {
            return Object.values(groups).map(mainGroup => {
                return(<Row style={{width:'auto', backgroundColor: '#ffffff', borderRadius:'10px', margin: '0 10px 15px 10px', boxShadow: '0px 2px 20px rgb(0 0 0 / 5%)'}}>
                            <p style={{width:'100%', marginLeft:'40px', fontWeight:'500', fontSize:'20px', marginTop:'20px'}}>{mainGroup.label}</p>
                            {mainGroup.childGroups.length ? mainGroup.childGroups.map(child => {
                                return <KataszterBox 
                                    cadastre={child} 
                                    name={child.cadastre_label} 
                                    handleSelect={handleSelect} 
                                    cadastres={allCadastre.filter(x => x.cadastre_group_ref === child.ref)} 
                                    favourite={favourite} 
                                    setFavourite={setFavourite} 
                                    group={true}
                                    updateLoadedDynamicCadastreLayers={props.updateLoadedDynamicCadastreLayers}
                                    setLoadedCadastreLayers={props.setLoadedCadastreLayers}
                                    addLayer={props.addLayer}
                                    layerOptions={props.layerOptions}
                                    setUserFavCadastre={props.setUserFavCadastre}
                                    />
                            }) :
                            <p style={{marginLeft:'20px', color:'grey', fontWeight:'200'}}>Ehhez a csoporthoz nem tartoznak kataszterek.</p>}
                        </Row>)
            });
        }
    }

	return (
	    <BrowseLayout>
            {detailed !== null ?
            <div className="dashboard-kataszter-sub-conatiner">
                <Kataszer setDetailed={setDetailed} cadastre={props.cadastreData[detailed.cadastre_id]} layerOptions={props.layerOptions} updateLoadedDynamicCadastreLayers={props.updateLoadedDynamicCadastreLayers} setLoadedCadastreLayers={props.setLoadedCadastreLayers} setUserFavCadastre={props.setUserFavCadastre} addLayer={props.addLayer}/>
            </div>
            :
            <div className="dashboard-kataszter-main-conatiner">

                <div className="kataszter-accordion kataszter-accordion--all favourite-cadastres">
                    <h4>Kedvencek</h4>
                    {getFavCadasters().length ?
                        <Row>
                            {getFavCadasters()}
                        </Row>
                    :
                    <p>Még nem jelölt ki kedvencet! </p>
                    }
                </div>
                <div className="kataszter-accordion kataszter-accordion--all">
                    <Row className="kataszter-accordion-title">
                        <h2>
                            <span>Összes kataszter:</span>
                            <span>{allCadastre.length} kataszter</span>
                            {showall && <form>
                                <input value={searchWord} type="text" name="search" placeholder='Keresés' onChange={(event) => {setSearchWord(event.target.value)}}/>
                            </form>}
                            <span className="color-circle" onClick={() => {if(allCadastre.length) {setShowall(!showall)}}}>
                                <FontAwesomeIcon role="button" size="1x" icon={['fas', showall ? 'chevron-up' : 'chevron-down']}/>
                            </span>
                        </h2>
                    </Row>

                    {showall && 
                    <Row>
                        {getCadasters()}
                    </Row>
                    }
                </div>

                <div className="kataszter-accordion kataszter-accordion--group">
                    <Row className="kataszter-accordion-title">
                        <h2>
                            <span>Kataszter csoportok:</span>
                            <span>{allCadastreGroups.length} csoport</span>
                            <span className="color-circle" onClick={() => setShowGroup(!showGroup)}>
                                <FontAwesomeIcon size="1x" icon={['fas', showGroup ? 'chevron-up' : 'chevron-down']}/>
                            </span>
                        </h2>
                    </Row>
                    {setupCadastreGroups()}
                </div>

                <div className="kataszter-accordion kataszter-accordion--project">
                    <Row className="kataszter-accordion-title">
                        <h2>
                            <span>Project kataszterek:</span>
                            <span>0 projekt</span>
                            <span className="color-circle" onClick={() => setShowProject(!showProject)}>
                                <FontAwesomeIcon size="1x" icon={['fas', showProject ? 'chevron-up' : 'chevron-down']}/>
                            </span>
                        </h2>
                    </Row>

                </div>
                <div className="dashboard-kataszter-main-conatiner" style={{paddingTop: '0px', marginTop: '0px'}}>
                    <div className="kataszter-accordion kataszter-accordion--all">
                        <Row>                
                        <Container className="dashboard-kataszter-container kataszter-button-container">
                            <div className="dashboard-kataszter-container-inside">
                                <Row className="dashboard-kataszter-container-header-row">
                                    <Col lg="12" className="dashboard-add-kataszter-button">
                                        <i role="button" onClick={() => setShow(true)}>
                                            <FontAwesomeIcon size="2x" icon={['fas', "plus-circle"]} />
                                            <span>Új kataszter hozzáadása</span>
                                        </i>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                        </Row>
                    </div>
                </div>  
            </div>}                     
        <KataszterModal show={show} setShow={setShow}/>
        </BrowseLayout>
	);
};

DashboardKataszter.propTypes = {};

const mapStateToProps = state => ({
    smartmode: getSmartMode(state),
    loading : getLoadingData(state),
    loadingMessage: getLoadingMessage(state),
    cadastreAuth: getUserCadastreAuth(state),
    layerOptions: getMapLayerOptions(state),
    userSettings: getUserSettings(state),
    navigationState: getNavigationState(state),
    cadastreData:getUserCadastreData(state)
});

const mapDispatchToProps = (dispatch) => ({
	toggleViewState: (id) => {
	  dispatch(toggleView(id));
	},
    updateLoadedDynamicCadastreLayers: (cadastre) => {
        dispatch(updateLoadedDynamicCadastreLayers(cadastre))
    },
    setLoadedCadastreLayers : (cadastre_ref) => {
		dispatch(setLoadedCadastreLayers(cadastre_ref))
	},
    addLayer: (selected) => {
        dispatch(setAllLoadedLayers(selected));
    },
    setUserSettings: (settings) => {
        dispatch(setUserSettings(settings))
    },
    setUserFavCadastre: (cadastre) => {
        dispatch(setUserFavCadastre(cadastre))
    }
  });

export default connect(
    mapStateToProps,
	mapDispatchToProps
)(DashboardKataszter);
