import React, {useState} from  'react';
import { Button, Modal, Form } from 'react-bootstrap';
import Config from '../../../config/config';
import { KataszterController } from '../../../controllers/KataszterController';


export default function KataszterUpdateModal(props) {

    const [selected, setSelected] = useState({});
    const KataszterControllerInst = KataszterController.getInstance();

    function updateSelected(value, id, key) {
        let temp = selected;
        if(selected.hasOwnProperty(id)) {
            if(selected[id].hasOwnProperty(key)) {
                if(value === "null" || value === "") {
                    delete temp[id][key];
                    if(!Object.keys(temp[id]).length){
                        delete temp[id];
                    }
                }else {
                    let obj = {}
                    obj[key] = value;
    
                    temp[id] = {
                        ...temp[id],
                        ...obj
                    }
                }
            }else {
                temp[id][key] = value
            }
        }else {
            let obj = {}
            obj[key] = value;

            temp[id] = {
                ...obj
            }
        }
        setSelected(temp);
    }


    function updateTasks() {
        KataszterControllerInst.updateKataszterById(props.rowid, selected[props.rowid]).then(response => {
            if(response.statusCode === 200) {
                setSelected({})
                props.setObjectChanged(true)
                props.setShow(false)

            }
        })
    }

    function setupForm()  {
        let config = Config.kataszter[props.kataszterId]
        let mapping = Config.mapping.kataszter[props.kataszterId]

        return Object.entries(config.modifiable).map(([key, value]) => {
            if(value === "select"){
                return(
                    <div>
                        <Form.Label>{mapping.attribute[key]}</Form.Label>
                        <Form.Control as="select" className="form-control "
                        onChange={(event) => {updateSelected(event.target.value, props.rowid, key)}} >
                            <option value="null">---</option>
                            {mapping.value.hasOwnProperty(key) ? 
                                Object.entries(mapping.value[key]).map(([k, v]) => {
                                return <option value={k}>{v}</option>
                                })
                                :
                                null
                            }
                        </Form.Control>
                    </div>
                );
            }else if(value === "text") {
                return(
                    <div>
                        <Form.Label>{mapping.attribute[key]}</Form.Label>
                        <Form.Control as="input" className="form-control " onChange={(event) => {updateSelected(event.target.value, props.rowid, key)}}>
                        </Form.Control>
                    </div>
                );

            }else {
                return(<p>invalid</p>)
            }
        })

    }

    return(
        <Modal show={props.show} className="confirm" onHide={() => {props.setShow(false)}} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
            Feladat módositása
            </Modal.Header>
            <Modal.Body>
                {props.kataszterId !== undefined && setupForm()}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => {
                    props.setShow(false);
                    setSelected({});
                    }}>Bezárás</Button>
                <Button variant="primary" onClick={() => {updateTasks()}} >Mentés</Button>
            </Modal.Footer>
        </Modal>
    );
}