//Import interfaces
import Config from "../config/config";


export class MapController {

    private static instance: MapController;

    /**
     * Can't create the object without the instance method to prevent direct init
     */
    private constructor() {}

    /**
     * Controlling the singleton
     */
    public static getInstance(): MapController {
        if (!MapController.instance) {
            MapController.instance = new MapController();
        }
        return MapController.instance;
    }

    public async getTiles(location:any) {
        const url = Config.endpoints.base + Config.endpoints.list.map.tiles.replace('{location}', location);

        return new Promise((resolve, reject) => {
            fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            }).then(res => {
                if(res.status === 200) {
                    return res.json()
                }else {
                    reject({statusCode:404, error:"Could not load measurement data"})
                }
            }).then(res => {
                resolve({statusCode:200, data:res})
            })
        });
    }

    public getLayerTiles(location:any) {
        const url = Config.endpoints.base + Config.endpoints.list.map.layerTiles.replace('{location}', location);

        return new Promise((resolve, reject) => {
            fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            }).then(res => {
                if(res.status === 200) {
                    return res.json()
                }else {
                    reject({statusCode:404, error:"Could not load measurement data"})
                }
            }).then(res => {
                resolve({statusCode:200, data:res})
            })
        });
    }

    public getTitleLayerById(id:any) {
        const url = Config.endpoints.base + Config.endpoints.list.map.titleLayerById.replace('{id}', id);

        return new Promise((resolve, reject) => {
            fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            }).then(res => {
                if(res.status === 200) {
                    return res.json()
                }else {
                    reject({statusCode:404, error:"Could not load measurement data"})
                }
            }).then(res => {
                resolve({statusCode:200, data:res})
            })
        });

    }
    
};
