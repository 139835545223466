import React, { useEffect, useState } from 'react';
import { Modal, Row ,Col, Form} from 'react-bootstrap';
import { ThirdPartyController } from "../../controllers/ThirdPartyController";

export default function NewContactModal(props){

    const ThirdPartyControllerInst = ThirdPartyController.getInstance();
    const [formOptions, setFormOptions] = useState({type:{}, visibility:{}, status:{}});

    //Data
    const [label, setLabel] = useState("");
    const [description, setDescription] = useState("");
    const [url, setUrl] = useState("");
    const [visibility, setVisibility] = useState(1);
    const [type, setType] = useState(1);
    const [logo, setLogo] = useState({});

	useEffect(() => {
		ThirdPartyControllerInst.getThirdPartyFormOptions().then(response => {
            if(response.statusCode === 200) {
                setFormOptions(response.data);
            }
        });
    }, []); // eslint-disable-line
    
    function setupThirdPartyData() {

        let data = new FormData();
        data.append('label', label);
        data.append('description', description);
        data.append('url', url);
        data.append('visibility', visibility);
        data.append('type', type);
        data.append('logo', logo);

        props.createNewThirdParty(data);
    };

    return (                
            <React.Fragment>
            <Modal className="new-contact-modal" show={props.show} onHide={() => props.setShow(false)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title><h3>Új kapcsolat létrehozása</h3></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Row>
                <Col lg="6">
                <p style={{paddingBottom:"12px", fontWeight: "500"}} >Csempe adatok</p>
                    <Row>
                        <Col>
                        <Form.Group>    
                        <Form.Text>Kapcsolat neve</Form.Text>
                            <input type="text" className="dashboard-input-modal" onChange={(evt) => {setLabel(evt.target.value)}} value={label}></input> 
                        </Form.Group>
                        </Col>                           
                    </Row>
                    <Row>
                        <Col>
                        <Form.Group>
                        <Form.Text>Kapcsolat típusa</Form.Text>
                            <select name="type" id="type" className="dashboard-input-modal" onChange={(evt) => {setType(evt.target.value)}} value={type}>
                                {Object.entries(formOptions.type).map(([key, value]) => {
                                    return(
                                        <option key={key} value={key}>{value}</option>
                                    )
                                })}
                            </select>
                        </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <Form.Group>
                        <Form.Text>URL</Form.Text>
                            <input type="text" className="dashboard-input-modal" onChange={(evt) => {setUrl(evt.target.value)}} value={url}></input> 
                        </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <Form.Group>
                        <Form.Text>Láthatóság</Form.Text>
                            <select name="type" id="type" className="dashboard-input-modal" onChange={(evt) => {setVisibility(evt.target.value)}} value={visibility}>
                            {Object.entries(formOptions.visibility).map(([key, value]) => {
                                    return(
                                        <option key={key} value={key}>{value}</option>
                                    )
                                })}
                            </select>
                        </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                    <Col>
                    <Form>                   
                    <Form.Group style={{paddingTop:"10px", paddingBottom:"10px"}}>                    
                        <Form.Text>Logó feltöltése</Form.Text>
                            <Row>                        
                                <Col lg="12">
                                    <Form.File                                            
                                        id="custom-file"
                                        label={logo.name ? logo.name : "Kérem válasszon logót"}                                            
                                        custom
                                        onChange={(evt) => {setLogo(evt.target.files[0])}}
                                    />
                                </Col>
                            </Row>                        
                    </Form.Group>
                    </Form>
                    </Col>
                    </Row>
                    </Col>
                    <Col lg="6">
                    <Row className="description">
                        <Col>
                            <Form.Text>Kapcsolat leírása</Form.Text>
                            <Form.Control as="textarea" rows="11" className="request-text-area" onChange={(evt) => {setDescription(evt.target.value)}} value={description}/>
                        </Col>
                    </Row>
                        <button className="request-send-button" disabled={!(label !== "" && description !== "" && url !== "" && logo !== {})} onClick={() => setupThirdPartyData()}>Új kapcsolat létrehozása</button>
                    </Col>
                    </Row>
                </Modal.Body>
                
            </Modal>
            </React.Fragment>
        );   
}