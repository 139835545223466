import { getLength, getArea } from 'ol/sphere';
import Overlay from 'ol/Overlay';
import 'ol/ol.css';
import Style from 'ol/style/Style';
import Stroke from 'ol/style/Stroke';
import Fill from 'ol/style/Fill';
import {LineString, Polygon} from 'ol/geom';
import Draw, { createRegularPolygon } from 'ol/interaction/Draw';
import CircleStyle from 'ol/style/Circle';
import {unByKey} from 'ol/Observable';
import {Text} from "ol/style";

let measureTooltip;
let measureTooltipElement;
let sketch;
let listener;

let overLays = [];

function formatLength(line) {
    const length = getLength(line);
    let output;
    if (length > 100) {
      output = (Math.round((length / 1000) * 100) / 100 ).toString() + ' km';
    } else {
      output = (Math.round(length * 100) / 100).toString() + ' m';
    }
    return output;
}

var formatArea = function (polygon) {
    var area = getArea(polygon);
    var output;
    if (area > 10000) {
      output = (Math.round((area / 1000000) * 100) / 100).toString() + ' km<sup>2</sup>';
    } else {
      output = (Math.round(area * 100) / 100).toString() + ' m<sup>2</sup>';
    }
    return output;
  };
  

export function createMeasureTooltip(map) {
    if (measureTooltipElement) {
      measureTooltipElement.parentNode.removeChild(measureTooltipElement);
    }
    measureTooltipElement = document.createElement('div');
    measureTooltipElement.className = 'ol-tooltip ol-tooltip-measure';
    measureTooltip = new Overlay({
      element: measureTooltipElement,
      offset: [0, -15],
      positioning: 'bottom-center',
    });
    overLays.push(measureTooltip);
    map.addOverlay(measureTooltip);
}

export function drawStylefunction(feature) {

    let geom = feature.getGeometry();
    let output;
    if (geom instanceof Polygon) {
        output = formatArea(geom);
    } else if (geom instanceof LineString) {
        output = formatLength(geom);
    }
    let refactored = output.replace("km<sup>2</sup>", 'km²')

    let style = new Style({
        fill: new Fill({
            color: 'rgba(255, 255, 255, 0.2)',
        }),
        stroke: new Stroke({
            color: '#ffcc33',
            width: 2,
        }),
        image: new CircleStyle({
            radius: 7,
            fill: new Fill({
                color: '#ffcc33',
            }),
        }),
        text: new Text({
            text:refactored,
            textAlign:'center',
            font: '17px sans-serif',
            fill: new Fill({
                color: '#444444'
            }),
            backgroundFill : new Fill({
                color: 'rgba(255, 204, 51, 0.5)'
            }),
        })
    })

    return style;
}
 
export function initMeasure(type, layers, map) {
    layers.draw.measure = new Draw({
        source: layers.draw.measureSource,
        type: type === "Square" ? "Circle" : type,
        geometryFunction: type === "Square" ? createRegularPolygon(4) : null,
        geometryName: type,
        style: new Style({
            fill: new Fill({
                color: 'rgba(255, 255, 255, 0.2)',
            }),
            stroke: new Stroke({
                color: 'rgba(0, 0, 0, 0.5)',
                lineDash: [10, 10],
                width: 2,
            }),
            image: new CircleStyle({
                radius: 5,
                stroke: new Stroke({
                color: 'rgba(0, 0, 0, 0.7)',
                }),
                fill: new Fill({
                color: 'rgba(255, 255, 255, 0.2)',
                }),
            }),
        })
    });

    layers.draw.measure.on("drawstart", function (evt) {
        // set sketch
        sketch = evt.feature;

        let tooltipCoord = evt.coordinate;
    
        listener = sketch.getGeometry().on('change', function (evt) {
            let geom = evt.target;
            let output;
            if (geom instanceof Polygon) {
                output = formatArea(geom);
                tooltipCoord = geom.getInteriorPoint().getCoordinates();
            } else if (geom instanceof LineString) {
                output = formatLength(geom);
                tooltipCoord = geom.getLastCoordinate();
            }
            measureTooltip.setPosition(tooltipCoord);

            measureTooltipElement.innerHTML = output;
        });
    })

    layers.draw.measure.on("drawend", function (evt) {
        measureTooltipElement.className = 'ol-tooltip ol-tooltip-static';
        measureTooltip.setPosition(undefined);
        // unset sketch
        sketch = null;
        // unset tooltip so that a new one can be created
        measureTooltipElement = null;
        createMeasureTooltip(map);
        unByKey(listener);
    })
}

export function clearTooltip(map) {
    if(overLays.length) {
        overLays.forEach(overlay => {
            map.removeOverlay(overlay);
        })
    }
}