import React from 'react';
import LoginContainer from '../containers/Login'
import Config from "../config/config";

const Login = () => {
    return (
        <LoginContainer 
            position={Config.screens.login.background_map.position} 
            center={Config.screens.login.background_map.center} 
            zoom={Config.screens.login.background_map.zoom} />
    );
};

Login.propTypes = {};

export default Login;
