import React, {useEffect, useState} from 'react';
import { Form, Col, Row, Modal, Button } from 'react-bootstrap';
import { addMapFilter, getNavigationState, resetMapFilter, setLoadedProjectLayers, setLoadedCadastreLayers, getMapLayerOptions } from '../../../state/reducers/ui';
import connect from 'react-redux/es/connect/connect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getUserSettings, setUserSettings } from '../../../state/reducers/user';import { UserController } from '../../../controllers/UserController';

function SavedFilterComponent(props) {

    const [filterGroupName, setFilterGroupName] = useState("");
    const [filterNameWarning, setFilterNameWarning] = useState("");
    const [selectedSavedFilter, setSelectedSavedFilter] = useState("null");
    const [filterSaveWarning, setFilterSaveWarning] = useState("");

    //modals
    const [showFilterDelete, setShowFilterDelete] = useState(false);
    const [showFilterSave, setShowFilterSave] = useState(false);

    //const
    const filters = props.navigationState.query.searchFilters;
    const UserControllerInst = UserController.getInstance();

    function getSavedFilters() {
        let avalibleFilters = props.userSettings.map.savedFilters;
        return avalibleFilters ? avalibleFilters : {};
    }

    function deleteSavedFilter() {
        let temp = {...props.userSettings};

        delete temp.map.savedFilters[selectedSavedFilter];
        UserControllerInst.saveSettings(temp).then(response => {
            setUserSettings(temp)
        });

        setSelectedSavedFilter("null")
        setShowFilterDelete(false);
    };

    function saveFilters() {
        let temp = {...props.userSettings};
        let filterid = filterGroupName.replace(" ", "-").replace(".", "-");
        
        if(temp.map.hasOwnProperty("savedFilters")) {
            if(temp.savedFilters.hasOwnProperty(filterid)) {
                setFilterNameWarning("Ilyen névvel már van mentve keresés. Kérem válasszon másikat.")
            }else {
                temp.savedFilters[filterid] = {filters:filters, layerData:props.layerOptions, filterName:filterGroupName};
                UserControllerInst.saveSettings(temp).then(response => {
                    setUserSettings(temp)
                });
                //save layers
                setSelectedSavedFilter(filterGroupName)
                setFilterNameWarning("");
                setFilterSaveWarning("");
            }
        }else {
            temp.map["savedFilters"] = {};
            temp.map["savedFilters"][filterid] = {filters:filters, layerData:props.layerOptions, filterName:filterGroupName};    
            UserControllerInst.saveSettings(temp).then(response => {
                setUserSettings(temp);
            });

            //save layers
            setSelectedSavedFilter(filterGroupName)
            setFilterNameWarning("");
            setFilterSaveWarning("");
        }        
        setShowFilterSave(false);
    }

    const addFilter = (filterObj) => {
        props.addMapFilter(filterObj);
    }

    useEffect(() => {
        Object.keys(getSavedFilters()).forEach(key => {
            if(filters.hasOwnProperty("state")) {
                delete filters["state"]
            }
        })

        if(selectedSavedFilter !== "null") {
            props.resetMapFilter();
            let loadFilter = getSavedFilters()[selectedSavedFilter.replace(" ", "-").replace(".", "-")];
            if(loadFilter.hasOwnProperty("filters")) {
                addFilter(loadFilter.filters);
                let ld = loadFilter.layerData
                if(ld !== undefined) {    
                    if(ld.loadedProjectLayers.length) {
                        props.setLoadedProjectLayers(ld.loadedProjectLayers);
                    }
                }
            }else {
                addFilter(loadFilter)
            }
        }

    }, [selectedSavedFilter]) // eslint-disable-line react-hooks/exhaustive-deps

    return(
        <div>
            <div className="select-saved-filters">

            <Form.Group as={Row}>
                    <Form.Label column sm="12" lg="12">
                    Mentett szűrési feltételek:
                    </Form.Label>
                    {Object.keys(getSavedFilters()).length ?
                    <>
                    <Form.Control as="select" className="form-control saved-filters" placeholder="Osztály" column lg="8"
                    onChange = {(event) => {
                        setSelectedSavedFilter(event.target.value);
                        if(event.target.value === 'null') {
                            props.resetMapFilter();
                        }
                    }}
                    value={selectedSavedFilter}
                    disabled={!Object.keys(getSavedFilters()).length}>
                    <option value="null">---</option>
                    {Object.keys(getSavedFilters()).map( function(key, index) {
                            return <option value={key}>{key}</option>
                        })}

                    </Form.Control>
                        <Col xs={1} lg={1} className="saved-filter-button">
                            <i role="button"  onClick={() => setShowFilterDelete(true)}  disabled={!Object.keys(getSavedFilters()).length} ><FontAwesomeIcon color="#db5353" size="1x" icon={['fas', "trash"]} /></i>
                        </Col>
                    </>
                    :
                    <p style={{fontSize:"12px", fontStyle: "italic"}}>Még nem mentett szűrési feltételt.</p>}
                </Form.Group>

                </div>
                {props.active ? <div className="view-filters">
                    <div className="align-self-start">
                        <button className="filtersavebutton" variant="primary" onClick={() => {Object.keys(filters).length !== 0 ? setShowFilterSave(true) : setFilterSaveWarning("Nincsenek aktív keresési feltételek")}}>Szűrési feltételek mentése</button>
                        {filterSaveWarning !== "" ? <p style={{fontSize:"11px", color:"#ff5959"}}>{filterSaveWarning}</p> : null}
                    </div>
                </div> : null}

                <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={showFilterSave} onHide={() => setShowFilterSave(false)}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Szürés elnevezése: </Form.Label>
                        <Form.Control type="text" placeholder="Név" value={filterGroupName} onChange={(e) => setFilterGroupName(e.target.value)}/>
                        <Form.Text className="text-muted">
                        Tartalmazhat betűket és számokat
                        </Form.Text>
                    </Form.Group>
                    {filterNameWarning !== "" ? <p>{filterNameWarning}</p> : null}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setShowFilterSave(false)}>Bezárás</Button>
                    <Button onClick={() => {saveFilters()}}>Mentés</Button>
                </Modal.Footer>
            </Modal>

            <>
                <Modal show={showFilterDelete} onHide={() => setShowFilterDelete(false)}>
                    <Modal.Body>
                    <p>Biztosan törli az alábbi mentett keresési feltételt ?</p>
                    <p>Név : {selectedSavedFilter}</p>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowFilterDelete(false)}>
                        Nem
                    </Button>
                    <Button variant="primary" onClick={deleteSavedFilter}>
                        Igen
                    </Button>
                    </Modal.Footer>
                </Modal>
            </>
            </div>
    );
}

const mapStateToProps = state => ({
    navigationState:getNavigationState(state),
    layerOptions: getMapLayerOptions(state),
    userSettings: getUserSettings(state)
});

const mapDispatchToProps = (dispatch) => ({
    addMapFilter: (filterObj) => {
      dispatch(addMapFilter(filterObj));
    },
    resetMapFilter: () => {
        dispatch(resetMapFilter());
    },
    setLoadedProjectLayers: (projectid) => {
		dispatch(setLoadedProjectLayers(projectid))
	},
	setLoadedCadastreLayers : (cadastre_ref) => {
		dispatch(setLoadedCadastreLayers(cadastre_ref))
	},
    setUserSettings: (settings) => {
        dispatch(setUserSettings(settings))
    },
  });

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SavedFilterComponent);