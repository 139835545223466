import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { customStyle, getStyles } from "../_styles/styles"
import MoreDetails from './MoreDetails';
import connect from 'react-redux/es/connect/connect';
import {getUserCadastreData} from '../../../state/reducers/user';

function MoreDetailsTable(props) {

    const cols = getStyles({markerdata:props.markerdata, removeSelectedItem: props.removeSelectedItem, cadastre_id:props.cadastre_id, cadastreData:props.cadastreData})
    const [markerDataTable, setMarkerDataTabele] = useState([]);
    
    useEffect(() => {
        setMarkerDataTabele(props.markerdata.filter(feature => feature.values_.cadastre_id === props.cadastre_id));
    }, [props.markerdata]) // eslint-disable-line

    return(
        <div className="item-list">
            {Array.isArray(markerDataTable) ?
                <DataTable
                expandableRows
                expandableRowsHideExpander={true}
                expandOnRowClicked={true}
                columns={cols}
                data={markerDataTable}
                wrap={false}
                noHeader={true}
                compact={true}
                allowOverflow={true}
                customStyles={customStyle}
                pagination={true}
                paginationDefaultPage={1}
                expandableRowsComponent={<MoreDetails navigateSelected={props.navigateSelected} zoomToFeature={props.zoomToFeature} features={props.markerdata}/>}
                paginationPerPage={25}
                highlightOnHover={false}
            />
            :
            <MoreDetails navigateSelected={props.navigateSelected} data={markerDataTable} zoomToFeature={props.zoomToFeature} />}
        </div>
    );
}

const mapStateToProps = state => ({
	cadastreData: getUserCadastreData(state)
});

export default connect(
	mapStateToProps,
	null,
)(MoreDetailsTable);