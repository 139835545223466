import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { 
    getMapLayerOptions,
    setMapLayerOptionsFilter,
    setMapLayerOptionsFilterSearchword,
    setLoadedCadastreLayerStyle,
} from '../../../state/reducers/ui';

import { 
    setLoadedDynamicCadastreLayerStyle,
    getUserSettings,
    setUserSettings
} from '../../../state/reducers/user';

import {getUserCadastreData} from '../../../state/reducers/user';
import connect from 'react-redux/es/connect/connect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RangeSlider from 'react-bootstrap-range-slider';
import { HuePicker, AlphaPicker } from 'react-color';
import Config from '../../../config/config';
import { UserController } from '../../../controllers/UserController';

function CadastreFilterComponent(props) {

    const [searchWord, setSearchWord] = useState({});
    const [styling, _setStyling] = useState({stroke: 0, strokeColor:"", fillColor:{r:"", g:"", b:"", a:"0.5"}});
    const UserControllerInst = UserController.getInstance();

    const stylingRef = React.useRef(styling);
	const setStyling = data => {
		stylingRef.current = data;
		_setStyling(data);
	};

    //Save styling in unmount.
    useEffect( () => () => {
        if((stylingRef.current.stroke !== 0 || stylingRef.current.strokeColor !== "") && stylingRef.current.fillColor.r !== "") {
            props.setLoadedDynamicCadastreLayerStyle({id:props.cadastre.cadastre_id, style:stylingRef.current});

            let temp_settings = {...props.userSettings};
            temp_settings.map.cadastreStyles[props.cadastre.cadastre_id] = {id:props.cadastre.cadastre_id, style:stylingRef.current}
            props.setUserSettings(temp_settings);
            UserControllerInst.saveSettings(temp_settings).then(response => {
                console.log("response", response);
            });
        }
    }, [] ); // eslint-disable-line

    useEffect(() => {
        let configData = Config.layers[props.cadastre.cadastre_ref]
        let cadastre = props.cadastreData[props.cadastre.cadastre_id];
        let loadedStaticLayer = props.layerOptions.loadedCadastreLayers.filter(x => x.name === props.cadastre.cadastre_ref)[0]

        if(cadastre && cadastre.data_source === "dynamic" && cadastre.style) {
            setStyling(cadastre.map_settings.style);
        }else if(loadedStaticLayer){
            setStyling(cadastre.map_settings.style);
        }
        else
        {
            if(Config.layers.hasOwnProperty(props.cadastre.cadastre_ref)) {
                if(configData.modifyStlye) {
                    let color = configData.stroke.color.slice(5, -1).split(",");
                    setStyling({...styling, fillColor:{...styling.fillColor, r:color[0], b:color[1], g:color[2]}})
                }
            }
        }
    }, []); // eslint-disable-line

    useEffect(() => {

        let type = props.cadastre.data_source === "dynamic" ? "dynamic" : "static"
        let savedLayer = props.layerOptions.loadedCadastreLayers.filter(x => x.name === props.cadastre.cadastre_ref)[0];
        let active = props.layerOptions.loadedCadastreLayers.map(x => x.name).includes(props.cadastre.cadastre_ref);
        let cadastre = props.cadastre;

        if(props.cadastre.data_source === "dynamic") {
            if(styling.stroke !== 0 || styling.strokeColor !== ""  || styling.fillColor.r !== "") {
                if(props.modifyStyle) {
                    if(cadastre.map_settings.style !== "") {
                        if(cadastre.map_settings.style.stroke !== styling.stroke || cadastre.map_settings.style.strokeColor !== styling.strokeColor || cadastre.map_settings.style.fillColor !== styling.fillColor) {
                            props.modifyStyle(props.cadastre.cadastre_id, styling, type);
                        }
                    }else {
                        props.modifyStyle(cadastre.cadastre_id, styling, type);
                    }
                }
            }

        } else {
            if((styling.stroke !== 0 || styling.strokeColor !== ""  || styling.fillColor.r !== "") && props.modifyStyle) {
                if(active && savedLayer.style !== "") {
                    if(savedLayer.style.stroke !== styling.stroke || savedLayer.style.strokeColor !== styling.strokeColor || savedLayer.style.fillColor !== styling.fillColor) {
                        props.modifyStyle(props.cadastre.cadastre_ref, styling, type);
                    }
                }else {
                    props.modifyStyle(props.cadastre.cadastre_ref, styling, type);
                }
            }
        }
    }, [styling]) // eslint-disable-line

    function updateLayerFilter(key, event) {

        let temp = {};
        let filterObject = {};
        filterObject[key] = event;
        let id = props.cadastre.cadastre_id

        if(props.layerOptions.searchFilters.hasOwnProperty(id)) {
            if(props.layerOptions.searchFilters[id].hasOwnProperty(key)) {
                if(event !== "null") {
                    temp[id] = {...props.layerOptions.searchFilters[id]}
                    temp[id][key] = event
                }else {
                    delete props.layerOptions.searchFilters[id][key];
                }
                if(!Object.keys(props.layerOptions.searchFilters[id]).length) {
                    delete props.layerOptions.searchFilters[id]
                }
            }else {
                temp[id] = {...props.layerOptions.searchFilters[props.cadastre.cadastre_id], ...filterObject};
            }
        }else {
            temp[props.cadastre.cadastre_id] = {...filterObject};
        }
        props.addLayerFilter(temp)
    }

    function handleSubmit(e, key) {
        e.preventDefault();
        let value = searchWord[key] !== "" ? searchWord[key] : "null"
        updateLayerFilter(key, value);

    }

    function handleSearchChange(evt) {
        const value = evt.target.value
        setSearchWord({
            ...searchWord,
            [evt.target.name]: value
          });
    }

    function getValue(value, type) {
        let cadastre_id = props.cadastre.cadastre_id;
        if(type === 'list') {
            if(props.layerOptions.searchFilters.hasOwnProperty(cadastre_id)){
                if(props.layerOptions.searchFilters[cadastre_id].hasOwnProperty(value)) {
                    return props.layerOptions.searchFilters[cadastre_id][value];
                }
            }else {
                return "null";
            }
        }
        else if(type === 'string' || type === 'number') {
            if(searchWord.hasOwnProperty(value)) {
                return searchWord[value]
            }
            else if(props.layerOptions.searchFilters.hasOwnProperty(cadastre_id)) {
                if(props.layerOptions.searchFilters[cadastre_id].hasOwnProperty(value)) {
                    return props.layerOptions.searchFilters[cadastre_id][value];
                }
            }
            else {
                return '';
            }
        }
    }

    function setupStyling() {
        return(
            <div className="cadastre-styling-container">
                <div className="range-slider">
                    <p>Keret vastagsága</p>
                    <RangeSlider value={styling.stroke} onChange={event => { setStyling({...styling, stroke:event.target.value}) }} min={0} max={10} step={0.1} />
                </div>
                <div className="color-picker">
                    <p>Keret színe</p>
                    <div>
                        <HuePicker width="100%" color={ styling.strokeColor } onChange={(color) => setStyling({...styling, strokeColor:color.hex}) }/>
                    </div>
                </div>
                <div className="color-picker">
                    <p>Kitöltés színe</p>
                    <div>
                        <HuePicker width="100%" color={ styling.fillColor } onChange={(color) => setStyling({...styling, fillColor:{...styling.fillColor, r:color.rgb.r, b:color.rgb.b, g:color.rgb.g}}) }/>
                    </div>
                </div>
                <div className="color-picker">
                    <p>Kitöltés átlátszósága</p>
                    <div>
                        <AlphaPicker width="100%" color={ styling.fillColor } onChange={(color) => setStyling({...styling, fillColor:{...styling.fillColor, a:color.rgb.a}}) }/>
                    </div>
                </div>
            </div>
        );
    }

    function setupFilters() {

        if(props.cadastre.form_json !== null) {
            return props.cadastre.form_json.map(cadastre_field => { // eslint-disable-line
                if(cadastre_field.filterable) {
                    if(cadastre_field.input === "list") {
                        return(
                            <div>
                                <Form.Label style={{fontSize: "12px"}} >{cadastre_field.label}</Form.Label>
                                <Form.Control as="select" className="form-control" column lg="10" value={getValue(cadastre_field.name, 'list')} onChange={(evt) => updateLayerFilter(cadastre_field.name, evt.target.value)}>
                                    <option value="null">---</option>
                                    {Object.entries(cadastre_field.values).map(([key, value]) => {
                                            return <option value={key}>{value}</option>
                                    })}
                                </Form.Control>
                            </div>
                        );
                    }

                    if(cadastre_field.input === "string") {
                        return(
                            <form className="" onSubmit={(e) => handleSubmit(e, cadastre_field.name)}>
                                <Form.Label style={{fontSize: "12px"}}>{cadastre_field.label}</Form.Label>
                                <div className="filter-search-container">
                                <input type="text" name={cadastre_field.name} className="small form-control filter-search" placeholder="Keresés" value={getValue(cadastre_field.name, 'string')} onChange={handleSearchChange}/>
                                <i role="button" onClick={(e) => handleSubmit(e, cadastre_field.name)}><FontAwesomeIcon icon={['far', 'search']} /></i>
                                </div>
                            </form>
                        );
                    }

                    if(cadastre_field.input === 'number') {
                        return(
                            <form className="" onSubmit={(e) => handleSubmit(e, cadastre_field.name)}>
                                <Form.Label style={{fontSize: "12px"}}>{cadastre_field.label}</Form.Label>
                                <div className="filter-search-container">
                                <input type="number" name={cadastre_field.name} className="small form-control filter-search" placeholder="Keresés" value={getValue(cadastre_field.name, 'number')} onChange={handleSearchChange}/>
                                <i role="button" onClick={(e) => handleSubmit(e, cadastre_field.name)}><FontAwesomeIcon icon={['far', 'search']} /></i>
                                </div>
                            </form>
                        );
                    } 
                }
            })
        }
    }

    function checkGeometryType() {
        let gtypes = props.cadastre.map_settings.geometry_types;
        if((gtypes.Point || gtypes.MultiPoint) && !gtypes.LineString && !gtypes.MultPolygon && !gtypes.MultiLineString && !gtypes.Polygon) {
            return false
        }
        else {
            return true;
        }
    }

    return(
        <div className="cadastre-filter-component-container">
            {props.header && <div className="cadastre-filter-header">
                <span role="button" onClick={() => {props.setEnableFilter(false)}} className="back-span"><FontAwesomeIcon className="" role="button" icon={['fas', "chevron-left"]}/><span className="back">Vissza</span></span>
                <p>{props.cadastre.cadastre_label}</p>
            </div>}
            {checkGeometryType() && props.header && <div className="cadastre-style-modifier">
                {setupStyling()}
            </div>}
            <div className="cadastre-filters">
                {setupFilters()}
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    layerOptions: getMapLayerOptions(state),
    cadastreData : getUserCadastreData(state),
    userSettings: getUserSettings(state)
});

const mapDispatchToProps = (dispatch) => ({
    addLayerFilter: (filterObject) => {
        dispatch(setMapLayerOptionsFilter(filterObject));
    },
    addSearchWord: (query) => {
        dispatch(setMapLayerOptionsFilterSearchword(query))
    },
    setLoadedCadastreLayerStyle: (style) => {
        dispatch(setLoadedCadastreLayerStyle(style));
    },
    setLoadedDynamicCadastreLayerStyle: (style) => {
        dispatch(setLoadedDynamicCadastreLayerStyle(style))
    },
    setUserSettings: (settings) => {
        dispatch(setUserSettings(settings))
    },
});

export default connect(
   mapStateToProps,
   mapDispatchToProps,
)(CadastreFilterComponent);