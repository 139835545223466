import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Style from 'ol/style/Style';
import Stroke from 'ol/style/Stroke';
import Fill from 'ol/style/Fill';
import CircleStyle from 'ol/style/Circle';
import Icon from 'ol/style/Icon';
import { BSmart } from '../../../helpers/geojson';
import {Text} from 'ol/style';
import reduxStore from '../../../state/store';
import Point from 'ol/geom/Point';

export function getStyles(props) {

    let cadastre = props.cadastreData[props.cadastre_id]

    let columnsKozvill = [
		{
			name: '',
			selector: 'delete',
			sortable: false,
			minWidth: '30px',
			maxWidth: '30px',
		    right: false,
		    center: true,
		    cell: row => <i onClick={() => {
                props.removeSelectedItem(props.markerdata, row.ol_uid)
                }} title="Kijelölés törlése"><FontAwesomeIcon color="red" icon={['fal', 'minus-circle'] } /></i>
		}
	];
    
    cadastre.form_json.forEach(field => {
        if(field.visible.visible_header) {
            columnsKozvill.splice(field.visible.order_header, 0, 
            {
                selector:(row) => {
                    if(row.values_.hasOwnProperty(field.name)) {
                        return row.values_[field.name]
                    }else if(row.values_.hasOwnProperty("json")) {
                        if(row.values_.json.hasOwnProperty(field.name)) {
                            return row.values_.json[field.name]
                        }
                    }else {
                        return 'N/A'
                    }
                },
                name:field.label,
                sortable:false,
                right:false,
                center:true,
            })
        }
    });
    return columnsKozvill.slice(0, 5);
    
}

export const customStyle = {
    headRow: {
        style: {
            minHeight: '39px',

        },
    },
    headCells: {
        style: {
            paddingLeft: '15px', // override the cell padding for head cells
            paddingRight: '15px',
            fontSize: '11px',
            background: '#515151',
            color: '#ffffff',
            '&:hover': {
                color: '#ffffff',
            },
          
        },
    },
    rows: {
          
        highlightOnHoverStyle: {
            backgroundColor: '#4CAFF1',
            transitionDuration: '0.15s',
            transitionProperty: 'background-color',
            color: 'white'
              
        }
    },
    cells: {
        style: {
            paddingLeft: '15px', // override the cell padding for data cells
            paddingRight: '15px',
            fontSize: '12px',
          

        },
    },
};

export function getCadastreLayerStyle(layerData, data) {
    let style;
    if(data !== "") {
        style = new Style({
            stroke: new Stroke({
                color: data.strokeColor !== "" ? data.strokeColor : layerData.stroke.color,
                width: data.stroke !== "" ? parseInt(data.stroke) : layerData.stroke.width
            }),
            fill: new Fill({
                color: data.fillColor !== "" ? "rgba(" + data.fillColor.r  +","+ data.fillColor.g + "," + data.fillColor.b +  "," + data.fillColor.a + ")" : layerData.fill
            }),
        });
    }
    else {
        style = new Style({
            stroke: new Stroke({
                color: layerData.stroke.color,
                width: layerData.stroke.width
            }),
            fill: new Fill({
                color: layerData.fill
            }),
        });
    }
    return style;
}
// style:{stroke: "3.4", strokeColor:"#00c3ff", fillColor:{r:0, b:196, g:255, a:0.22}}

let styles = {
    'LineString': {
      stroke: new Stroke({
        color: 'green',
        width: 1,
      }),
    },
    'MultiLineString': {
      stroke: new Stroke({
        color: 'green',
        width: 1,
      }),
    },
    'MultiPolygon':{
      stroke: new Stroke({
        color: 'yellow',
        width: 1,
      }),
      fill: new Fill({
        color: 'rgba(255, 255, 0, 0.1)',
      }),
    },
    'Polygon': {
      stroke: new Stroke({
        color: 'blue',
        lineDash: [4],
        width: 3,
      }),
      fill: new Fill({
        color: 'rgba(0, 0, 255, 0.1)',
      }),
      zIndex:10
    },
    'GeometryCollection':{
      stroke: new Stroke({
        color: 'magenta',
        width: 2,
      }),
      fill: new Fill({
        color: 'magenta',
      }),
      image: new CircleStyle({
        radius: 10,
        fill: null,
        stroke: new Stroke({
          color: 'magenta',
        }),
      }),
    },
    'Circle': {
      stroke: new Stroke({
        color: 'red',
        width: 2,
      }),
      fill: new Fill({
        color: 'rgba(255,0,0,0.2)',
      }),
    },
};

export const arrowStyleFunction = function (feature) {
    let geometry = feature.getGeometry();
    let styles = [
        // linestring
        new Style({
            stroke: new Stroke({
            color: 'black',
            width: 3,
            }),
        }) ];
  
    geometry.forEachSegment(function (start, end) {
        let dx = end[0] - start[0];
        let dy = end[1] - start[1];
        let rotation = Math.atan2(dy, dx);
        // arrows
        styles.push(
            new Style({
            geometry: new Point(end),
            image: new Icon({
                src: 'markers/arrow.png',
                anchor: [0.75, 0.5],
                rotateWithView: true,
                rotation: -rotation,
            }),
            })
        );
    });
  
    return styles;
};

const styleCache = {};


export const dynamicCadastreStyleFunction = (cadastreData, vectorLayer) => (feature) => {

    const isCluster = !!feature.get('features');
    let reduxLoadedLayers = reduxStore.getState().ui.mapState.layerOptions.allLoadedLayers;
    let index = reduxLoadedLayers.indexOf(feature.values_.cadastre_id);

    if(isCluster) {
        const clusterSize = feature.get('features').length;
        let highlighted = feature.get('features').filter(x => x.properties.highlighted === true).length
        return new Style({
            image: highlighted ? new Icon(BSmart.markers[0].clustericon_highlight) : new Icon(BSmart.markers[0].clustericon),
            text: new Text({
                text: clusterSize.toString(),
                placement: 'point',
                textAlign: 'center',
                offsetY: -25,
                font: '12px sans-serif',
                fill: new Fill({
                    color: '#444444'
                })
            })
        });
    }else {
        let icon = {}

        if((feature.values_.cadastre_id === 55 || feature.values_.cadastre_id === '55') && feature.values_.hasOwnProperty("task")) {
            let src = 'markers/week_1-4.png';

            if(feature.values_.task && (feature.values_.task.approved === 1 || feature.values_.task.app === "1")){
                src = 'markers/week_5-8.png';
            }else if(feature.values_.task && (feature.values_.task.done === 1 || feature.values_.task.app === "1")){
                src = 'markers/week_9-12.png';
            }else if(feature.values_.task && (feature.values_.task.app === 1 || feature.values_.task.app === "1")){
                src = 'markers/week_13-16.png';
            }
            else{
            }

            icon = {
                    anchor: [0.5,1],
                    src: src,
                    scale: 0.6,
                    opacity: 1,
                }
            if(vectorLayer) {
                vectorLayer.setZIndex((100-index));
            }

            return new Style({image:new Icon(icon), zIndex: (100-index)})

        }
        if(feature.getGeometry().getType() === 'Point') {

            let reduxLoadedLayers = reduxStore.getState().ui.mapState.layerOptions.allLoadedLayers;
            let index = reduxLoadedLayers.indexOf(feature.values_.cadastre_id);
            const markerStyles = feature.properties.highlighted ? ['highlight'] : [];

            //todo this is a fix for Andris, have to be removed in the future
            if(feature.values_.hasOwnProperty("cadastre_ref")) {
                if(feature.values_.hasOwnProperty('live')) {
                    if(feature.values_.live !== null) {
                        if(feature.values_.live.is_broken){
                            markerStyles.unshift('', 'inactive');
                        }else {
                            markerStyles.unshift('', 'active');
                        }
                    }
                    else {
                        markerStyles.unshift('', 'active');
                    }
                }
                icon = {
                    anchor: [0.5,1],
                    src: "markers/" + cadastreData[feature.values_.cadastre_id].cadastre_ref + (markerStyles.join('_')) + ".png",
                    scale: 1
                }

            }else if(feature.values_.cadastre_id !== null && feature.values_.cadastre_id !== undefined) {
                if(feature.values_.hasOwnProperty('live')) {
                    if(feature.values_.live !== null) {
                        if(feature.values_.live.is_broken){
                            markerStyles.unshift('', 'inactive');
                        }else {
                            markerStyles.unshift('', 'active');
                        }
                    }
                    else {
                        markerStyles.unshift('', 'active');
                    }
                }
                icon = {
                    anchor: [0.5,1],
                    src: "markers/" + cadastreData[feature.values_.cadastre_id].cadastre_ref + (markerStyles.join('_')) + ".png",
                    scale: 1
                }
            }else {
                icon = {
                    anchor: [0.5,1],
                    src: "markers/map_marker" + (markerStyles.join('_')) + ".png",
                    scale: 1
                }
            }
            if(feature.values_.hasOwnProperty('on_same_place')) {
                const onSamePlace = feature.values_.on_same_place;
                const count = 360 / onSamePlace.length;
                const rotation = onSamePlace.length > 1 ? onSamePlace.indexOf(feature.values_.id)*count : 0; // evenly dividing items
                icon.rotation = Math.PI / 180 * rotation; // we need rotation in radians
            }
            const image = new Icon(icon);

            styleCache[feature.values_.cadastre_id] = new Style({image, zIndex: (100-index)});
            if(vectorLayer) vectorLayer.setZIndex((100-index));


            return new Style({image, zIndex: (100-index)})

        }else {
            if(vectorLayer) {
                vectorLayer.setZIndex((99-index));
            }

            if(feature.values_.hasOwnProperty("cadastre_id")) {
                let cadastre_id = feature.values_.cadastre_id
                if(cadastre_id !== null && cadastre_id !== undefined) {
                    let style = cadastreData[cadastre_id].map_settings.style
                    let unique = cadastreData[cadastre_id].map_settings.uniqueStyle;
                    let refactStyle;
                    if(unique !== null) {
                        let color = "rgba(" + unique.values[feature.values_.json[unique.key]] + ")";
                        refactStyle = new Style({
                            stroke: new Stroke({
                                color: color,
                                width: style.stroke !== "" ? parseInt(style.stroke) : "1"
                            }),
                            fill: new Fill({
                                color: color
                            }),
                        });
                    }else {
                        refactStyle = new Style({
                            stroke: new Stroke({
                                color: style.strokeColor !== "" ? style.strokeColor  : "blue",
                                width: style.stroke !== "" ? parseInt(style.stroke) : "1"
                            }),
                            fill: new Fill({
                                color: style.fillColor !== "" ? "rgba(" + style.fillColor.r  +","+ style.fillColor.g + "," + style.fillColor.b +  "," + style.fillColor.a + ")" : 'rgba(255, 255, 0, 0.1)'
                            }),
                        });
                    }
                    return refactStyle;
                }else {
                    return new Style(styles[feature.getGeometry().getType()]);
                }
            } else {
                return new Style(styles[feature.getGeometry().getType()]);
            }
        }
    }
};

export const TitleLayerStyleFunction = (layerStyle) => (feature) => {
    let fontType = layerStyle.bold ? 'bold' : layerStyle.italic ? 'italic' : ''
    return new Style({
        text: new Text({
            text: feature.values_.text.toString(),
            placement: 'point',
            textAlign: 'center',
            // offsetY: -25,
            font:  `${fontType} ${layerStyle.textsize}px sans-serif`,
            fill: new Fill({
                color: layerStyle.color
            }),
            rotation: Math.PI / 180 * feature.values_.rotation
        })
    });
}