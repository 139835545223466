import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const VerticalProgress = ({label,size, value, unit, icon,color}) => {

    return (
      <div className={'vertical-progress widget '+ size }>
        <div>
          <span>{label}</span>
       	  <div className="display">
       	    <i className="icon" style={{color:color}}>
			        { icon && <FontAwesomeIcon icon={['fal', icon]} />}
       	    </i>
       	    <div className="value">{value}</div>
            <div className="unit">{unit}</div>
       	  </div>
        </div>
       	<div className="progress" style={{backgroundColor:color,height:value+'%'}}></div>
      </div>
    );
};

VerticalProgress.propTypes = {};

export default VerticalProgress;