//Import interfaces
import Config from "../config/config";

export class MeasurementController {

    private static instance: MeasurementController;

    /**
     * Can't create the object without the instance method to prevent direct init
     */
    private constructor() {}

    /**
     * Controlling the singleton
     */
    public static getInstance(): MeasurementController {
        if (!MeasurementController.instance) {
            MeasurementController.instance = new MeasurementController();
        }
        return MeasurementController.instance;
    }

    public getHumidityStatistics(fromDate:any, toDate:any, id:string) {
        const url = Config.endpoints.base + Config.endpoints.list.measurement.humidity.get.replace('{from}', fromDate).replace('{to}', toDate);
        let params:string = "";

        if(id) {
            params = params + "&id=" + id;
        }

        return new Promise((resolve, reject) => {
            fetch(url + params, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            }).then(res => {
                if(res.status === 200) {
                    return res.json()
                }else {
                    reject({statusCode:404, error:"Could not load measurement data"})
                }
            }).then(res => {
                resolve({statusCode:200, data:res})
            })
        })
    }

    public getDustStatistics(fromDate:any, toDate:any, id:string) {
        const url = Config.endpoints.base + Config.endpoints.list.measurement.dust.get.replace('{from}', fromDate).replace('{to}', toDate);
        let params:string = "";

        if(id) {
            params = params + "&id=" + id;
        }

        return new Promise((resolve, reject) => {
            fetch(url + params, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            }).then(res => {
                if(res.status === 200) {
                    return res.json()
                }else {
                    reject({statusCode:404, error:"Could not load measurement data"})
                }
            }).then(res => {
                resolve({statusCode:200, data:res})
            })
        })

    }

    public getDevices(){
        const url = Config.endpoints.base + Config.endpoints.list.measurement.devices.get;

        return new Promise((resolve, reject) => {
            fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            }).then(res => {
                if(res.status === 200) {
                    return res.json()
                }else {
                    reject({statusCode:404, error:"Could not load devices data"})
                }
            }).then(res => {
                resolve({statusCode:200, data:res})
            })
        })
    }

    public getTemperature(id:string, fromDate:any, toDate:any){
        const url = Config.endpoints.base + Config.endpoints.list.measurement.temperature.get.replace('{from}', fromDate).replace('{to}', toDate);
        let params:string = "";

        if(id !== "") {
            params = params + "&id=" + id;
        }

        return new Promise((resolve, reject) => {
            fetch(url + params, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            }).then(res => {
                if(res.status === 200) {
                    return res.json()
                }else {
                    reject({statusCode:404, error:"Could not load devices data"})
                }
            }).then(res => {
                resolve({statusCode:200, data:res})
            })
        })
    }

    public getWifiSummary(){
        const url = Config.endpoints.base + Config.endpoints.list.measurement.wifi.summary;

        return new Promise((resolve, reject) => {
            fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            }).then(res => {
                if(res.status === 200) {
                    return res.json()
                }else {
                    reject({statusCode:404, error:"Could not load devices data"})
                }
            }).then(res => {
                resolve({statusCode:200, data:res})
            })
        })
    }

    public getWifiHistoricalData(from:any, to:any) {
        let url = Config.endpoints.base + Config.endpoints.list.measurement.wifi.historical.replace('{from}', from).replace('{to}', to);
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            }).then(res => {
                if(res.status === 200) {
                    return res.json()
                }else {
                    reject({statusCode:404, error:"Could not load devices data"})
                }
            }).then(res => {
                resolve({statusCode:200, data:res})
            })
        })
    };

    public getCOSummary(from:any, to:any, id:any) {
        const url = Config.endpoints.base + Config.endpoints.list.measurement.co.get.replace('{from}', from).replace('{to}', to);
        let params:string = "";

        if(id !== "") {
            params = params + "&id=" + id;
        }

        return new Promise((resolve, reject) => {
            fetch(url + params, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            }).then(res => {
                if(res.status === 200) {
                    return res.json()
                }else {
                    reject({statusCode:404, error:"Could not load co data"})
                }
            }).then(res => {
                resolve({statusCode:200, data:res})
            })
        })

    }

    public getNoiseSummary(from:any, to:any, id:any) {
        let url = Config.endpoints.base + Config.endpoints.list.measurement.noise.get.replace('{from}', from).replace('{to}', to);

        if(id) {
            url += "&id=" + id
        }

        return new Promise((resolve, reject) => {
            fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            }).then(res => {
                if(res.status === 200) {
                    return res.json()
                }else {
                    reject({statusCode:404, error:"Could not load co data"})
                }
            }).then(res => {
                resolve({statusCode:200, data:res})
            })
        })
    }

    public getWifiApsData() {
        let url = Config.endpoints.base + Config.endpoints.list.measurement.wifi.wifiaps;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            }).then(res => {
                if(res.status === 200) {
                    return res.json()
                }else {
                    reject({statusCode:404, error:"Could not load co data"})
                }
            }).then(res => {
                resolve({statusCode:200, data:res})
            })
        })

    }
    
}
