import React from 'react';
import PropTypes from 'prop-types';

const AppLayout = ({ children }) => (
    <div>
        {children}
    </div>
);

AppLayout.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.any,
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element)
    ])
};

AppLayout.defaultProps = {
    children: []
};

export default AppLayout;
