//Import interfaces
import Config from "../config/config";

export class ParkController {

    private static instance: ParkController;

    private constructor() {}

    /**
     * Controlling the singleton
     */
    public static getInstance(): ParkController {
        if (!ParkController.instance) {
            ParkController.instance = new ParkController();
        }
        return ParkController.instance;
    }


    public getParkPictures(devices: any) {
        const picURL1 = Config.endpoints.parkpicture.replace('{deviceID}',devices[0]);
        const picURL2 = Config.endpoints.parkpicture.replace('{deviceID}',devices[1]);

        return [
            picURL1+this.makeRandomString(12),
            picURL2+this.makeRandomString(12)
        ];
    }


    public getParkingList() {
        const url = Config.endpoints.base + Config.endpoints.list.parkingLocationsList;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: 'GET', 
                headers: {
                    'Content-Type': 'application/json'
                },
            }).then(res => {
                if (res.status === 200) {
                    return res.json()
                }
                else {
                    reject({statusCode: 404, error: 'Could not load parking places list data'})
                }
            }).then(res => {
                resolve({statusCode: 200, data: res})
            })
        });
    }


    public makeRandomString(length:number) {
        let result           = '';
        const characters       = '0123456789';
        let charactersLength = characters.length;
        for ( let i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }


    public getParkData(id: number) {
        const parameteredURLBlock = Config.endpoints.list.park.replace('{id}',id);
        const url = Config.endpoints.base + parameteredURLBlock;

        return new Promise((resolve, reject) => {
            fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            }).then(res => {
                if(res.status === 200) {
                    return res.json()
                }else {
                    reject({statusCode:404, error:"Could not load parking data"})
                }
            }).then(res => {
                resolve({statusCode:200, data:res})
            })
        });
    }
    
}
