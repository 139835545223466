import React, { useState, useEffect } from "react";
import { Button, Row, Modal, Col, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ValidatioPopup from "../validationPopup"
import moment from "moment";
import {addUserAuthWarning} from '../../../../state/reducers/user';
import connect from 'react-redux/es/connect/connect';
import {getUserRole} from '../../../../state/reducers/user';
import { TaskUpdateController } from '../../../../controllers/TaskUpdateController';
import { MessageHandler } from '../../../../controllers/MessageHandlerController.ts';

function MqttMultipleActionPopup(props) {

    const [comment, setComment] = useState(null)

    //Popups
    const [validationPopupShow, setValidationPopupShow] = useState(false);
    const [showFeedback, setShowFeedback] = useState(false);
    const [feedback, setFeedback] = useState(null);

    //State for new start and end date, and if the date is invalid
    const [startDate, setstartDate] = useState(null);
    const [endDate, setendDate] = useState(null);
    const [invalidDate, setInvalidDate] = useState(false);

    const [warning, setWarning] = useState([])

    const TaskUpdateControllerInst = TaskUpdateController.getInstance();
    const MessageHandlerInst = MessageHandler.getInstance();

    //Table
    
    //task list
    const [data, setData] = useState([]);

    useEffect(() => {
        setData(props.selectedTasks)
        getTable();
    }, [props.selectedTasks]) // eslint-disable-line

    //Func for resetting the date states and close to popup
    const handleClose = () => {
        setValidationPopupShow(false);
        setstartDate(null);
        setendDate(null);
        setComment(null);
        setInvalidDate(false);
    };

    function deleteElement(id) {
        setData(data.filter(task => task.id !== id))
    }

    //Check the date inputs from user, if startdate is older than the end its invalid.
    useEffect(() => {
        let start = new Date(startDate)
        let end = new Date(endDate)

        if(end === null || start === null){
            setInvalidDate(true)
        }
        if(end < start) {
            setInvalidDate(true)
        }
        else {
            setInvalidDate(false)
        }
    }, [startDate, endDate])


    function getTable() {
        const items = []
        if(data !== null) {
            data.forEach((task, index) => {
                items.push(<tr key={index} className="text-center">
                    <td>{task.lpt_id}</td>
                    <td>{task.device.label}</td>
                    <td>{task.task}</td>
                    <td>{moment(task.dateo).format("YYYY-MM-DD")}</td>
                    <td>{moment(task.datee).format("YYYY-MM-DD")}</td>
                    <td><i role="button" onClick={() => deleteElement(task.id)}><FontAwesomeIcon icon={['fas', 'trash-alt']}/></i></td>
                    </tr>
                )
            })
        }
        return items;
    }

    function handleValidation() {

        props.setShowUpdatePopup(false)
        if(props.type === "update") {
            if(startDate === null && endDate === null) {
                setWarning(oldArray => [...oldArray, "A dátumot nem állitotta be igy nem kerül frissitésre. "]);
            }
            else {
                if(warning !== null && warning.length) {
                    let filtered = warning.filter(x => !x.includes("dátum"))
                    setWarning(filtered);
                }
            }
            if(comment === "" || comment === null) {
                setWarning(oldArray => [...oldArray, "Nem adott hozzá kommentet igy nem kerül frissitésre"]);
            }else {
                if(warning !== null && warning.length) {
                    let temp = warning;
                    setWarning(temp);
                }
            }
        }
        setValidationPopupShow(true)

    }


    function handleDateUpdate() {
        let updateList = [];    

        if(comment !== null) {
            updateComments();
        }

        if(props.type === "accept" || props.type === "revert") {
            updateStatus();
        }


        if(startDate !== null && endDate !== null) {
            data.forEach(element => {
                const final = {}
                const data = {}
                final["taskid"] = element.id
    
                if(props.type !== "update") {
                    const attribute = props.userRole === "PROJECTADMIN" ? 'done' : 'approved';
    
                    if(props.type === "accept") {
                        data[attribute] = 1;
                    }else {
                        data[attribute] = 0;
                    }
    
                }else {
                    if(startDate !== null && endDate !== null) {
                        let dateo = new Date(startDate).toISOString()
                        let datee = new Date(endDate).toISOString()
        
                        data["dateo"] = moment(dateo).format("YYYY-MM-DD");
                        data["datee"] = moment(datee).format("YYYY-MM-DD");
    
                    }
                }
                final["data"] = data
                updateList.push(final) 
            })
            TaskUpdateControllerInst.updateTasks(data[0].project, updateList).then(res => {
                if(res.statusCode === 200) {
                    setValidationPopupShow(false);
                    setFeedback("Feladatok sikeresen frissitve")
                    setShowFeedback(true)
                    props.updateMultiple(updateList);
                    props.update(true);
                }else {
                    console.log("Error while updating tasks with status code: ", res.statusCode);
                    setValidationPopupShow(false);
                    setFeedback("Hiba történt, kérem próbálja újra.")
                    setShowFeedback(true)                
                }
            })
        }
    }

    function updateComments() {
        let projectTasks = data.map(x => x.id);
        MessageHandlerInst.createMessage(comment, projectTasks, "").then(response => {
            if(response.statusCode === 200){
                setValidationPopupShow(false);
                setFeedback("Feladatok sikeresen frissitve")
                props.updateMultiple(projectTasks);
                setShowFeedback(true)
            }else {
                setValidationPopupShow(false);
                setFeedback("Hiba történt, kérem próbálja újra.")
                setShowFeedback(true)           
            }
        })
    }


    function updateStatus() {
        let updateList = [];    
        const attribute = props.userRole === "PROJECTADMIN" ? 'done' : 'approved';

        data.forEach(element => {
            const data = {}
            const final = {}
            final["taskid"] = element.id


            if(props.type === "accept") {
                data[attribute] = 1;
            }else {
                data[attribute] = 0;
            }

            final["data"] = data
            updateList.push(final) 
        })

        TaskUpdateControllerInst.updateTasks(data[0].project, updateList).then(res => {
            if(res.statusCode === 200) {
                setValidationPopupShow(false);

                props.updateMultiple(updateList, attribute);

                setFeedback("Feladatok sikeresen frissitve");
                setShowFeedback(true)
            }else {
                setValidationPopupShow(false);
                setWarning("Hiba a feladat frissítése közben.");
                setShowFeedback(true)
            }
        });
    }

    // const canEdit = props.useRole === 'SUPERADMIN';
    const canEdit = true;


    return(
        <React.Fragment>
            <Modal 
            show={props.showUpdatePopup} 
            onHide={() => {props.setShowUpdatePopup(false)}} 
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName="modal-90w"
            centered
            >
                <Modal.Header closeButton>
                    Feladatok módositása
                </Modal.Header>
                <Modal.Body>
                <div className="table-responsive">
                    <Table striped bordered hover className="project-view" style={{fontSize: "13px"}}>
                        <thead>
                            <tr>
                            <th>Azonosito</th>
                            <th>Projekt</th>
                            <th>Beavatkozás</th>
                            <th>Kezdete</th>
                            <th>Vége</th>
                            <th>Törlés</th>
                            </tr>
                        </thead>
                        <tbody className="text-center tableBody">
                        {getTable()}
                        </tbody>
                    </Table>
                </div>
                {props.type === "update" ?
                    <div className="gantt-data-container">
                        {canEdit && <p className="gantt-date-header">Dátum módositása</p>}
                        {canEdit && <div className="gant-popup-datepicker">
                            <Row className="justify-content-md-center">
                                <Col lg={6} sm={12}>
                                    <form>
                                    <label>Kezdete:  </label>
                                    <input  type="date"
                                            disabled={!canEdit}
                                            id="dateo" 
                                            name="start_date" 
                                            onChange={(event) => setstartDate(event.target.value)}
                                            />
                                    </form>
                                </Col>
                                <Col lg={6} sm={12}>
                                    <form>
                                    <label>Vége:  </label>
                                    <input  type="date" 
                                            id="datee" 
                                            name="end_date" 
                                            onChange={(event) => setendDate(event.target.value)}
                                            />
                                    </form>                
                                </Col>
                            </Row>
                        </div>}
                        {invalidDate ?
                        <div style={{textAlign:"center", margintTop:20}}>
                        <p style={{marginTop:13, marginBottom:0, color:"red", fontSize:12}}>A dátum nem megfelelő !</p>
                        </div>
                        :
                        null
                        }
                        Megjegyzés : 
                        <Row>
                        <textarea rows="3" className="form-control" onChange={(evt) => {setComment(evt.target.value)}}></textarea>                
                        </Row>
                    </div>
                :
                props.type === "accept" ?
                <p className="input-accept">Biztosan elfogadja a feladatokat ?</p>
                :
                <p className="input-reject">Biztosan visszavonja a feladatokat ?</p>
                }

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {props.setShowUpdatePopup(false)}}>Bezárás</Button>
                    <Button variant="primary" onClick={() => {handleValidation()}} 
                        disabled={invalidDate}>Feladatok frissitése</Button>
                </Modal.Footer>
            </Modal>

            {validationPopupShow ? 
                <ValidatioPopup show={validationPopupShow} 
                setShow={handleClose} 
                action={"Biztosan frissiti a feladatokat ?"} 
                makeAction={() => handleDateUpdate()} 
                type={"multiple"} 
                selectedTasks={data}
                startDate={startDate}
                endDate={endDate}
                comment={comment} 
                warning={warning}
            />
            :
            null
            }

            <Modal className="confirm" show={showFeedback} onHide={() => setShowFeedback(false)} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                <p>{feedback}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="dark" onClick={() => {setShowFeedback(false)}}>Bezárás</Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    );
}

const mapStateToProps = state => ({
	userRole: getUserRole(state),
});

const mapDispatchToProps = (dispatch) => ({
    addUserAuthWarning: (warning) => {
        dispatch(addUserAuthWarning(warning))
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MqttMultipleActionPopup);
