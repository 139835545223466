import React, { useState, useEffect } from 'react';
import Box from "../../components/dashboard/Box";
import { DashboardController } from '../../controllers/DashboardController';
import { MeasurementController } from '../../controllers/MeasurementController';
import moment from 'moment';

export default function WifiBox(props) {

	const [data, setData] = useState(false);  // eslint-disable-line
	const [widgets, setWidgets] = useState(false);

    const DashboardControllerInst = DashboardController.getInstance();
    const MeasurementControllerInst = MeasurementController.getInstance();

    let from = moment().subtract(1, 'days').valueOf()
    let to = moment().valueOf()


    useEffect(() => {
        let historicalData = MeasurementControllerInst.getWifiHistoricalData(from, to)

        let wifiData = DashboardControllerInst.getWidgetData('wifi');

        Promise.all([historicalData, wifiData]).then((values) => {
            let temp = [...props.widgets];
            temp[0].value = "45/" + values[1].data.online;
            temp[2].value = Object.values(values[0].data.traffic).map(x => x.total/1024);
            temp[2].labels = Object.keys(values[0].data.traffic).map(x => x.slice(11, 16));

            temp[1].value = Object.values(values[0].data.clients).map(x => x.total);
            temp[1].labels = Object.keys(values[0].data.clients).map(x => x.slice(11, 16));

            setWidgets(temp);

        });

    }, []); // eslint-disable-line

    return (
        <Box key={0} {...props}  widgets={widgets ? widgets : props.widgets}/>
    );
};
