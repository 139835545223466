import React, {useState, useEffect} from 'react';
import { Modal, Row, Col, Form } from 'react-bootstrap';
import { KataszterController } from '../../controllers/KataszterController';

export default function KataszterModal(props) {

    const KataszterControllerInst = KataszterController.getInstance();
    const userData = JSON.parse(sessionStorage.getItem('userDetails'));
    const [validateShow, setValidateShow] = useState(false)
    const [cadastreFormData, setCadastreFormData] = useState({
        firstName: userData.firstname, 
        lastName: userData.lastname, 
        email: userData.email, 
        phone: userData.user_mobile, 
        requestType: '', 
        description: ''});
    //const [attachments, setAttachments] = useState([]);
    //let dateValue = new Date();
    //dateValue.setDate(dateValue.getDate()+30);
    //const min_date = dateValue.toISOString().split('T')[0];

    function saveCadastreForm() {
        KataszterControllerInst.sendKataszterRequest(cadastreFormData).then(response => {
            if(response.statusCode === 200) {
                props.setShow(false);
                setValidateShow(true);
            }
        })
    }

    /*function attachFile(e) {
        let fileList = [];
        for(let i=0; i<e.target.files.length; i++) {
            fileList.push(e.target.files[i]);
        }
        setAttachments(fileList);
    }*/

    return(
        <React.Fragment>
        <Modal show={props.show} onHide={() => props.setShow(false)} size="lg">
            <Modal.Header closeButton>
            <Modal.Title>Kataszter kérelem</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Row className="kataszter-request-form-body-row">
                <Col lg="6">
                <p style={{paddingTop:"18px", paddingBottom:"5px"}} >Kérelmező adatai</p>
                    <Row>
                        <Col>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Text className="text-muted" >Vezetéknév </Form.Text>
                            <input type="text" className="dashboard-input-modal" value={cadastreFormData.firstName} onChange={(evt) => setCadastreFormData({...cadastreFormData, firstName:evt.target.value})}></input>
                        </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Text className="text-muted" >Keresztnév</Form.Text>
                            <input type="text" className="dashboard-input-modal" value={cadastreFormData.lastName} onChange={(evt) => setCadastreFormData({...cadastreFormData, lastName:evt.target.value})}></input>
                        </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Text className="text-muted" >Email</Form.Text>
                            <input type="email" className="dashboard-input-modal" value={cadastreFormData.email} onChange={(evt) => setCadastreFormData({...cadastreFormData, email:evt.target.value})}></input>
                        </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Text className="text-muted" >Telefonszám</Form.Text>
                            <input type="tel" className="dashboard-input-modal" value={cadastreFormData.phone} onChange={(evt) => setCadastreFormData({...cadastreFormData, phone:evt.target.value})}></input>
                        </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Text className="text-muted" >Feladat tipusa</Form.Text>
                            <select name="requestType" id="requestType" className="dashboard-input-modal" onChange={(evt) => setCadastreFormData({...cadastreFormData, requestType:evt.target.value})}>
                                <option value="null"></option>
                                <option value="kataszter felülírás">kataszter felülírás</option>
                                <option value="kataszter bővítés">kataszter bővítés</option>
                                <option value="új kataszter">új kataszter</option>
                            </select>
                        </Form.Group>
                        </Col>
                    </Row>
                </Col>
                <Col lg="6">
                    <Form.Text className="text-muted" style={{paddingTop:"65px"}}>Leirás</Form.Text>
                    <Form.Control as="textarea" maxLength="2500" rows="10" className="request-text-area" onChange={(evt) => setCadastreFormData({...cadastreFormData, description:evt.target.value})}/>
                    <button onClick={() => {saveCadastreForm()}} className="request-send-button">Kérelem beküldése</button>
                </Col>
            </Row>
            </Modal.Body>
        </Modal>


        <Modal show={validateShow} onHide={() => setValidateShow(false)} centered size="lg">
            <Modal.Body style={{paddingLeft:"50px", paddingRight:"50px"}}>
                <p className="kataszter-validate-header">Kataszter kérelem</p>
                <p className="kataszter-validate-body">Köszönjük, a kérelmét feldolgozzuk és hamarosan kollégáink felveszik Önnel a kapcsolatot.</p>
                <p className="kataszter-validate-body">Üdvözlettel az UMS csapata</p>
                <button onClick={() => {
                    setValidateShow(false)}
                    } className="request-send-button">Vissza az UTU-hoz</button>

            </Modal.Body>
        </Modal>
        </React.Fragment>
    );
}