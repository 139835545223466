import React from 'react';
import { Bar } from 'react-chartjs-2';


const ChartValue = ({label,labels,size,value,color}) => {
    
  const data = {
    labels: labels,
    datasets: [
      {
        label: '',
        backgroundColor: color,
        borderColor: color,
        borderWidth: 0,
        hoverBackgroundColor: 'rgba(255,99,132,0.4)',
        hoverBorderColor: 'rgba(255,99,132,1)',
        data: value
      }
    ]
  };

  const options = {
    maintainAspectRatio: false, 
    legend: {display: false},
    title: {display: false},
    tooltips: {enabled: false},

    scales: {
        xAxes: [{
            gridLines: {
                drawOnChartArea: false
            },
            ticks: {
              display: true,
              fontSize:9
            }
        }],
        yAxes: [{
            gridLines: {
                drawOnChartArea: false,

            },
            ticks: {
                display: false
            }   
        }]
    },
    layout: {
            padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0
            }
        }
};

    return (

      <div className={'chart-value widget justify-content-center align-items-center '+ size }>
       	<div className="display justify-content-center align-items-center">
       	  <Bar 
            data={data}
            height={60}
            responsive={true}
            options={options}
          />
       	</div>
        <span>{label}</span>
      </div>
    );
};

ChartValue.propTypes = {};

export default ChartValue;