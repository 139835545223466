export const wifi_details = [
	{
		'widget': 'DoughnutChart',
		'size': 'fourth',
		'value': [9000,2000],
		'icon': null,
		'unit': '',
		'label': 'Helyszínek',
		'iconType': 'fal',
		'iconColor': '#FFC303'
	},
	{
		'widget': 'DoughnutChart',
		'size': 'fourth',
		'value': [5000,200],
		'icon': null,
		'unit': '',
		'label': 'Smart Ready',
		'iconType': 'fal',
		'iconColor': '#FFC303'
	},
	{
		'widget': 'DoughnutChart',
		'size': 'fourth',
		'value': [2000,10],
		'icon': null,
		'unit': '',
		'label': 'Smart',
		'iconType': 'fal',
		'iconColor': '#FFC303'

	},
	{
		'widget': 'IconValue',
		'size': 'fourth last',
		'icon': 'lightbulb',
		'value': '07:00',
		'unit': '',
		'color': '#999999',
		'label': 'Csatlakozott kliensek száma'
	},
	{
		'widget': 'ChartValue',
		'size': 'half',
		'type': 'bar',
		'value': [0, 0, 0, 0, 0, 0, 0],
		'label': 'Csatlakozott AP-k (elmúlt 24 óra)',
		'labels' : ['','','','','','','','','','','','','','','','','','','','','','','','']
	},
	{
		'widget': 'ChartValue',
		'size': 'half last',
		'type': 'line',
		'value': [0, 0, 0, 0, 0, 0, 0],
		'label': 'Adatforgalom (elmúlt 24 óra)',
		'labels': ['','','','','','','','','','','','','','','','','','','','','','','','']
	}
];

export let wifi = [
	{
		'widget': 'DoubleIcon',
		'size': 'full',
		'value': '4389 / 0',
		'icon': 'router',
		'icon2': 'plug',
		'unit': '',
		'label': 'Összes AP / Csatlakozott AP'
	},
	{
		'widget': 'ChartValue',
		'size': 'full',
		'type': 'bar',
		'value': [0, 0, 0, 0, 0, 0, 0],
		'label': 'Csatlakozott',
		'labels' : ['','','','','','','','','','','','','','','','','','','','','','','','']
	},
	{
		'widget': 'ChartValue',
		'size': 'full',
		'type': 'line',
		'value': [0, 0, 0, 0, 0, 0, 0],
		'label': 'Adatforgalom',
		'labels': ['','','','','','','','','','','','','','','','','','','','','','','','']
	}
];