import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { getActiveTileLayers, setLoadedTileOrder } from '../../../state/reducers/ui';
import TileLayer from './TileLayer';
import TitleLayer from './TitleLayer';

function TileSidebar(props) {

    function loadedTileChange(tile) {
        props.loadTile(tile.ref);
    }

    return(
        <div className="tiles-container">
            <div className="layer-tiles">
                <p>Felirat rétegek</p>
                {props.loading ?
                        <div className="loader-container" style={{marginLeft: "25%"}}>
                            <div className="loader" />
                        </div>
                    :
                    <div>
                    {props.layerTiles.map(layer => {
                        return(
                            <TitleLayer loadedLayers={props.loadedLayers} layer={layer} handleTitleCadastreLoad={props.handleTitleCadastreLoad} />
                        );
                    })}
                    </div>
                    }
            </div>
            <div className="avalib" />
            <p>Elérhető hátterek</p>
            {props.tiles.map(tile => {
                return(
                    <TileLayer key={tile.name} tile={tile} loadedTileChange={loadedTileChange} loadedTiles={props.loadedTiles}/>
                )
            })}
        </div>
    )
}

const mapStateToProps = state => ({
    loadedTiles: getActiveTileLayers(state)
});

const mapDispatchToProps = (dispatch) => ({
    setLoadedTileOrder: (dragIndex, dragCard, hoverIndex) => {
        dispatch(setLoadedTileOrder(dragIndex, dragCard, hoverIndex));
    },
});

export default connect(
   mapStateToProps,
   mapDispatchToProps,
)(TileSidebar);
