import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const AcceptStatuses = (props) => {

    const Icon = (props) => {
			if(props.checked == 1) { // eslint-disable-line
				return <FontAwesomeIcon className="checker" size="1x" color="#2CD02A" icon={['far', 'check-square'] }/>
			}
			else if(props.checked == 0) { // eslint-disable-line
				return <FontAwesomeIcon className="checker"  size="1x" color="#d5d5d5" icon={['far', 'square'] }/>
			}
			else if(props.checked == 2 || props.checked == -1) { // eslint-disable-line
				return <FontAwesomeIcon className="checker" style={{fontSize:"18px", lineHeight:"27"}} size="1x" color="#ff3d3d" icon={['far', 'minus-square'] }/>

			}
			else {
				return <FontAwesomeIcon className="checker" sty  size="1x" color="#d5d5d5" icon={['far', 'square'] }/>
			}

		
	};

    return (
    	<Icon checked={props.checked} />
    );
};

AcceptStatuses.propTypes = {};

export default AcceptStatuses;