import React, {Component} from 'react';
import { connect } from 'react-redux';
import RenderEnvBox from "./RenderEnvBox";

let environment = [
    {
        'widget': 'IconValue',
        'size': 'third',
        'value': "N/A",
        'icon': 'sensors',
        'unit': '',
        'label': 'Aktív szenzorok'
    },
    {
        'widget': 'IconValue',
        'size': 'third last',
        'value': "N/A",
        'icon': 'thermometer-three-quarters',
        'unit': '°C',
        'label': 'Hőmérséklet'
    },
    {
        'widget': 'IconValue',
        'size': 'third',
        'value': "N/A",
        'icon': 'humidity',
        'unit': '%',
        'label': 'Pára'
    },
    {
        'widget': 'IconValue',
        'size': 'third last',
        'value': "N/A",
        'icon': 'smog',
        'unit': 'PPM',
        'label': 'CO'
    },
    {
        'widget': 'IconValue',
        'size': 'third',
        'value': "N/A",
        'icon': 'particles',
        'unit': '',
        'label': 'PM10'
    },
    {
        'widget': 'IconValue',
        'size': 'third last',
        'value': "N/A",
        'icon': 'waveform-path',
        'unit': 'Db',
        'label': 'Zaj'
    }

];

class EnvironmentBox extends Component {
    render() {
        const { onDeviceInfo, ...props } = this.props;
        return (
            <React.Fragment>
                <RenderEnvBox environment={environment} details="EnvironmentDetails" {...props} />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EnvironmentBox);
