import React, { useState, useEffect } from 'react';
import { Col, Row, FormCheck } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import connect from 'react-redux/es/connect/connect';
import { 
    getMapLayerOptions,
    setMapLayerOptionsFilter,
    setAllLoadedLayers,
    clearMapLayerOptionsFilter,
    setMapLayerOptionsFilterSearchword,
    updateLoadedDynamicCadastreLayers,
    setActiveCadastre
} from '../../../state/reducers/ui';
import Config from '../../../config/config';
import { history } from '../../../state/store';


function Layer(props) {

    const [selected, setSelected] = useState(false);

    useEffect(() => {
        let activeStatic = props.loadedLayers.includes(props.cadastre.cadastre_ref);
        let activeDynamic = props.layerOptions.loadedDynamicCadastreLayers.map(x => x.cadastre_ref).includes(props.cadastre.cadastre_ref);

        if((activeStatic || activeDynamic) && !selected) {
            setSelected(true);
        }else if(!(activeStatic || activeDynamic) && selected) {
            setSelected(false);
        }
    }, [props.loadedLayers, props.layerOptions.loadedDynamicCadastreLayers])  // eslint-disable-line


    function handleSwitch(cadastre) {
        if(cadastre && cadastre.data_source === "static") {
            props.loadLayer(cadastre.cadastre_ref, false, false, props.cadastre.cadastre_id);
            if(selected) {
                delete props.layerOptions.searchFilters[cadastre.cadastre_id];
            }
        }else {
            if(selected) {
                delete props.layerOptions.searchFilters[cadastre.cadastre_id];
            }
            props.updateLoadedDynamicCadastreLayers(cadastre);
        }
        props.addLayer(cadastre.cadastre_id);
    }

    return(
        <div>
            <Row style={{padding:"5px 0"}}>
                <Col lg={1} xs={1}>
                    <FontAwesomeIcon icon={['fas', props.cadastre.icon !== null && props.cadastre.icon !== "" ? props.cadastre.icon : "genderless"]} color="black" ref={props.dragref}/>
                </Col>
                <Col lg={selected && props.active && props.cadastre.cadastre_ref !== undefined ? 6 : 8} xs={selected && props.active && props.cadastre.cadastre_ref !== undefined ? 6 : 8} className="d-flex align-items-center">
                    <p style={{color:Config.layers.hasOwnProperty(props.cadastre.cadastre_ref) || props.cadastre.data_source === "dynamic" ? "black" : "#cfcfcf", margin: "0px"}}>{props.cadastre.cadastre_label}</p>
                </Col>
                {(Config.layers.hasOwnProperty(props.cadastre.cadastre_ref) || props.cadastre.data_source === "dynamic") &&
                <Col lg={1} xs={1} style={{paddingLeft:"2px"}}>
                    <FormCheck
                        disabled={!!props.layerOptions.loadingLayer}
                        id={"switch" + props.cadastre.cadastre_ref}
                        type="switch"
                        label=""
                        checked={selected}
                        onChange={() => handleSwitch(props.cadastre)}
                    />
                </Col>}
                {selected && props.active && props.cadastre.cadastre_ref !== undefined &&  <Col lg={1} xs={1}>
                    <FontAwesomeIcon className="layer-filter-enable-button" role="button" icon={['fas', "sliders-h"]} onClick={() => props.enableCadastreFilter(props.cadastre)}/>
                </Col>}
                {selected && props.active && props.cadastre.cadastre_ref !== undefined &&  <Col lg={1} xs={1}>
                    <FontAwesomeIcon className="layer-filter-enable-button" role="button" icon={['fas', "list"]} onClick={() => {
                        props.setActiveCadastre(props.cadastre)
                        history.push("/kataszter")
                    }}/>
                </Col>}
            </Row>
            {selected && props.active && props.cadastre.cadastre_ref !== undefined && 
                <div>
                    {Config.layers.hasOwnProperty(props.cadastre.cadastre_ref) ? Config.layers[props.cadastre.cadastre_ref].zoom ? <p style={{paddingLeft:"10px"}}>Látható zoom: {Config.layers[props.cadastre.cadastre_ref].zoom}</p> : null : null}
                </div>
            }
        </div>
    );
}

const mapStateToProps = state => ({
    layerOptions: getMapLayerOptions(state)
});

const mapDispatchToProps = (dispatch) => ({
    addLayerFilter: (filterObject) => {
        dispatch(setMapLayerOptionsFilter(filterObject));
    },
    addLayer: (selected) => {
        dispatch(setAllLoadedLayers(selected));
    },
    clearLayerFilters: () => {
        dispatch(clearMapLayerOptionsFilter());
    },
    addSearchWord: (query) => {
        dispatch(setMapLayerOptionsFilterSearchword(query))
    },
    updateLoadedDynamicCadastreLayers: (cadastre) => {
        dispatch(updateLoadedDynamicCadastreLayers(cadastre))
    },
    setActiveCadastre: (cadastre) => {
        dispatch(setActiveCadastre(cadastre))
    }
});

export default connect(
   mapStateToProps,
   mapDispatchToProps,
)(Layer);