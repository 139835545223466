import React, {useEffect, useState} from "react";
import Box from "../../components/dashboard/Box";
import { connect } from "react-redux";
import { DashboardController } from '../../controllers/DashboardController'
import { MeasurementController } from "../../controllers/MeasurementController";

function RenderEnvBox(props) {
    const { devices, environment, ...rest } = props;

    const [summary, setSummary] = useState({});

    const [dataLoaded, setDataLoaded] = useState(false);
    const [environmentState, setEnvironmentState] = useState(environment);

    const [selectedDevice, setSelectedDevice] = useState(false);
    const [avalibleDevices, setAvalibleDevices] = useState([]);

    const DashboardControllerInst = DashboardController.getInstance();
    const MeasurementControllerInst = MeasurementController.getInstance();


    useEffect(() => {
        DashboardControllerInst.getSummaryData(selectedDevice).then(response => {
            if(response.statusCode === 200) {
                setSummary(response.data)
            }
        });


        if(!avalibleDevices.length) {
            MeasurementControllerInst.getDevices().then(response =>{
                setAvalibleDevices(response.data)
            })
        }

    }, [selectedDevice])  // eslint-disable-line


    useEffect(() => {
        if(Object.keys(summary).length) {
            let temp = environmentState;
            temp[0].value = summary.active_sensors;
            temp[1].value = summary.temperature;
            temp[2].value = summary.humidity;
            temp[3].value = summary.co;
            temp[4].value = summary.dust;
            temp[5].value = summary.noise;
            setEnvironmentState(temp);
            setDataLoaded(true);
        }
    }, [summary])  // eslint-disable-line

    return (
        <div>
            {dataLoaded ?
                <Box key={0} widgets={environmentState} {...rest} setSelectedDevice={setSelectedDevice} selectedDevice={selectedDevice} avalibleDevices={avalibleDevices}/>
            :
            <div className="loader-container">
                <div className="loader"></div>
            </div>
            }
        </div>
    );
}

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RenderEnvBox);