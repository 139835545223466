import { compose, createStore, applyMiddleware } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';

import Config from "../config/config";
import createRootReducer from './reducers'
import rootEpic from './epics';

export const history = createBrowserHistory();

// inject dependencies into epics
const epicMiddleware = createEpicMiddleware({
    dependencies: {
        endpoints: Config.endpoints,
        defaults: Config.defaults
    }
});

const actionSanitizer = (action) => (
    action.type.indexOf('JSON_INFO') > -1 ?
        { ...action, payload: '<<LONG_ARRAY>>' } : action
);
const stateSanitizer = (state) => (
    { ...state }
);
// eslint-disable-next-line no-underscore-dangle
const reduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__({
    actionSanitizer,
    stateSanitizer
});

const configureStore = (initialState) => {
    const coreEnhancer = applyMiddleware(epicMiddleware, routerMiddleware(history));
    const enhancer = (process.env.NODE_ENV === 'production')
        ? coreEnhancer
        : compose(coreEnhancer, reduxDevTools || (f => f));
    const storeByEnvironment = createStore(createRootReducer(history), initialState, enhancer);

    if (process.env.NODE_ENV !== 'production') {
        if (module.hot) {
            module.hot.accept('./epics/index', () => {
                // eslint-disable-next-line global-require
                const nextEpic = require('./epics/index').default;
                epicMiddleware.replaceEpic(nextEpic);
            });
            module.hot.accept('./reducers/index', () => {
                // eslint-disable-next-line global-require
                const nextReducer = require('./reducers/index').default;
                storeByEnvironment.replaceReducer(nextReducer(history));
            });
        }
    }
    epicMiddleware.run(rootEpic);

    return storeByEnvironment;
};

const store = configureStore();

export default store;
