import React, { useState } from "react";
import {Row, Col, Modal, Form} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function PointOfInterestModal(props) {

    let dummyPOIType = {
        bicycle: {
            name:"Kerékpártároló",
            icon:"bicycle",
            data_fields: [
                {type:"text",name:"Szin"},
                {type:"select",name:"Tipus", values:["Fedett", "Fedetlen"]}
            ]
        },
        bin: {
            name:"Kuka",
            icon:"bin",
            data_fields: [
                {type:"text",name:"Szin"},
                {type:"select",name:"Tipus", values:["normál", "szelektiv", "kutyakaki"]}
            ]
        }
    }


    const [POITypes, setPOITypes] = useState(dummyPOIType);
    const [selectedPOI, setSelectedPOI] = useState("bicycle");
    const [selectedFormValues, setSelectedFormValues] = useState({});
    const [expandedPOI, setExpandedPOI] = useState(false);
    const [warning, setWarning] = useState("");

    const [selectedDataType, setSelectedDataType] = useState("text");

    //option
    const [datafieldName, setDatafieldName] = useState("");

    //select option
    const [selectField, setSelectField] = useState([])
    const [selectFiledValue, setSelectFieldValue] = useState("")

    //New Poi Type
    const [newPoiTypeName, setNewPoiTypeName] = useState("");
    const [newPoiDataFields, setNewPoiDataFileds] = useState([])

    function handleSelectedFormValues(key, value) {
        let temp = {};
        temp[key] = value
        setSelectedFormValues({...selectedFormValues, temp})
    }

    function handleNewPoiType() {
        let temp = {};
        temp[newPoiTypeName] = {
            name:newPoiTypeName,
            icon:"teszt",
            data_fields:newPoiDataFields
        };
        setPOITypes({...POITypes, ...temp})

        setExpandedPOI(false);
        setSelectedPOI(newPoiTypeName);
    }

    function handleAddnewOperation() {
        setWarning("");
        if(selectedDataType !== "select") {
            setNewPoiDataFileds([...newPoiDataFields, {name:datafieldName, type:selectedDataType}]);
        }else {
            setNewPoiDataFileds([...newPoiDataFields, {name:datafieldName, type:selectedDataType, values:selectField}]);

        }
    }

    function handleSelectChoice() {
        return(
            <Row style={{width:"100%"}}>
                <Col lg="11">
                    <input style={{border:'3px solid #cbcdcd',borderRadius:'10px',padding: '3px', width:"100%"}} type="text" className="" placeholder="" onChange={(evt) => {setSelectFieldValue(evt.target.value)}}></input>                        
                </Col>
                <Col lg="1">
                    <FontAwesomeIcon size="lg" icon="check-square" onClick={() => {setSelectField([...selectField, selectFiledValue])}}/>                      
                </Col>                
            </Row>
        );
    }
    function setupDataFields(field, index) {
            if(field.type === "text") {
                return(
                    <Row key={index}>
						<Col lg="4">   
                        	<Form.Text>{field.name}</Form.Text>
						</Col>
						<Col lg="7">
                            <input style={{border:'3px solid #cbcdcd',borderRadius:'10px',padding: '3px', width:"100%"}} type="text" className="" placeholder=""></input>                        
                        </Col>
						<Col lg="1">
							<FontAwesomeIcon size="lg" icon="window-close"/>                      
                        </Col>
                    </Row>
                );
            }

            if(field.type === "select") {
                return(
                    <Row key={index}>
                        <Col lg="4">   
                        	<Form.Text>{field.name}</Form.Text>
						</Col>
						<Col lg="7">
                            <select style={{borderRadius:'10px', backgroundColor:'#cbcdcd',padding: '3px'}} name="type" id="type" className="dashboard-input-modal" onChange={(evt) => {handleSelectedFormValues(field, evt.target.value)}}>
                                {field.values.map(value => {
                                    return <option key={value}>{value}</option>
                                })}
                            </select>             
                        </Col>
						<Col lg="1">
							<FontAwesomeIcon size="lg" icon="window-close"/>                      
                        </Col>
                    </Row>
                )
            }
    }

    return(
            <Modal show={props.show} onHide={ () => props.handleClose()} size="lg" dialogClassName={expandedPOI ? "new-poi-modal expanded" : "new-poi-modal"} >
                <Modal.Header closeButton>
                    <h6 style={{fontWeight:'bold'}}>ÚJ POI</h6>
                </Modal.Header>                 
            <Modal.Body className="new-poi-modal-body">
				<Row>
                    <Col lg={expandedPOI ? "7" : "12"}>
                        <Row>
                            <Col lg="4">
                                <Form.Text>Típus</Form.Text>
                            </Col>
                            <Col lg="7">
                                <select style={{borderRadius:'10px', backgroundColor:'#cbcdcd',padding: '3px'}} name="type" id="type" className="dashboard-input-modal" onChange={(evt) => {setSelectedPOI(evt.target.value)}} value={selectedPOI}>
                                    {Object.keys(POITypes).map(key => {
                                        return <option key={key} value={key}>{POITypes[key].name}</option>
                                    })}
                                </select>
                            </Col>
                            <Col lg="1">
                                <FontAwesomeIcon size="lg" icon={expandedPOI ? "chevron-left" : "plus"} role="button" onClick={() => setExpandedPOI(!expandedPOI)}/>                      
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="4">    
                            <Form.Text>Elhelyezkedés</Form.Text>
                            </Col>
                            <Col lg="8">
                                <p>Lat: {props.coordinate[1]}</p>
                                <p>Lon: {props.coordinate[0]}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="4">                       
                                <Form.Text>Név</Form.Text>
                            </Col>
                            <Col lg="8">
                                <input style={{borderRadius:'10px', backgroundColor:'#cbcdcd',padding: '3px'}} type="text" className="dashboard-input-modal" placeholder="POI neve"></input>
                            </Col>
                        </Row>
                        <h6 style={{fontWeight:'bold'}}>POI ADATOK</h6>
                            {POITypes[selectedPOI].data_fields.map((field, index) => {
                                return setupDataFields(field, index)
                            })}

                        <h6 style={{fontWeight:'bold'}}>JOGOSULTSÁGOK</h6>
                        <Row>
                            <Col lg="4">    
                            <Form.Text>Létrehozta</Form.Text>
                            </Col>
                            <Col lg="8">
                                <p>{sessionStorage.username}</p>                
                            </Col>
                        </Row>
                        <Row>
                        <Col lg="4">
                                <Form.Text>Hozzáférés</Form.Text>
                                <Form.Text>(csoport)</Form.Text>
                            </Col>
                            <Col lg="7">
                                <select style={{borderRadius:'10px', backgroundColor:'#cbcdcd', color:'white',padding: '3px'}} name="type" id="type" className="dashboard-input-modal">
                                    <option value="null">Kérem válassza ki a listából</option>
                                    <option value="type1"></option>
                                </select>
                            </Col>
                            <Col lg="1">
                                <FontAwesomeIcon size="lg" icon="plus-square"/>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="4">
                                <Form.Text>Hozzáférés</Form.Text>
                                <Form.Text>(egyén)</Form.Text>
                            </Col>
                            <Col lg="7">
                                <select style={{borderRadius:'10px', backgroundColor:'#cbcdcd' ,color:'white' ,padding: '3px'}} name="type" id="type" className="dashboard-input-modal">
                                    <option value="null">Kérem válassza ki a listából</option>
                                    <option value="type1"></option>
                                </select>
                            </Col>
                            <Col lg="1">
                                <FontAwesomeIcon size="lg" icon="plus-square"/>
                            </Col>
                        </Row>
                        <h6 style={{fontWeight:'bold'}}>CSATOLMÁNYOK</h6>
                        <Row>
                            <Col lg="4">
                                <Form.Text className="text-muted">Megjegyzés</Form.Text>
                            </Col>
                            <Col lg="8">
                                <Form.Control style={{borderRadius:'10px', backgroundColor:'#cbcdcd',padding: '3px'}} as="textarea" rows="5" className="request-text-area"/>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form>                   
                                    <Form.Group>                    
                                        <Form.Text className="text-muted">Kapcsolódó dokumentum</Form.Text>
                                            <Row> 
                                                <Col lg="8">
                                                    <Form.File                                            
                                                        id="custom-file"
                                                        label="..."                                            
                                                        custom
                                                    />
                                                </Col>
                                                <Col lg="4">
                                                        <FontAwesomeIcon size="lg" icon="file-upload" />
                                                </Col>                                
                                            </Row>                        
                                    </Form.Group>
                                </Form>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="8">
                                <button className="request-send-button" onClick={props.createPoi}>POI létrehozása</button>
                            </Col>
                            <Col lg="4">
                                <button className="request-send-button" style={{backgroundColor:'red'}} onClick={() => props.handleClose()}>Bezár</button>
                            </Col>
                        </Row>
                    </Col>
                    {expandedPOI &&
                    <Col lg="5" className="new-poi-type-container">
                    <Modal.Header >
                        <Col>
                            <h6 style={{fontWeight:'bold'}}>ÚJ POI TÍPUS</h6>
                                <Row>
                                    <Col lg="3">
                                        <Form.Text>Típus</Form.Text>
                                    </Col>
                                    <Col lg="9">
                                        <input style={{border:'3px solid #cbcdcd',borderRadius:'10px',padding: '3px'}} type="text" className="dashboard-input-modal" placeholder=" Valami 1 " onChange={(evt) => {setNewPoiTypeName(evt.target.value)}}></input>                        
                                    </Col>
                                </Row>
                                <h6 style={{fontWeight:'bold'}}>POI ADATMEZŐK</h6>
                                    {!newPoiDataFields.length && <p>Kérem vegyen fel új adatmezőt.</p>}
                                    {newPoiDataFields.map(field => {
                                        return setupDataFields(field);
                                    })}
                            </Col>
                        </Modal.Header>                 
                        <Modal.Body>
                            <Col>
                                <Row>
                                    <h6 style={{fontWeight:'bold'}}>Új adatmező</h6>

                                </Row>
                                <Row>
                                    <Col>
                                        <input style={{border:'3px solid #cbcdcd',borderRadius:'10px',padding: '3px'}} type="text" className="dashboard-input-modal" placeholder=" új adatmező neve" onChange={(evt) => setDatafieldName(evt.target.value)}></input>
                                    </Col>
                                </Row>
                                <Row>
                                    <Form.Text>Új adatmező típusa</Form.Text>
                                </Row>
                                <Row>
                                    <Col lg="9">
                                    <select style={{border:'3px solid #cbcdcd',borderRadius:'10px',padding: '3px'}} name="type" id="type" className="dashboard-input-modal" onChange={(evt) => {setSelectedDataType(evt.target.value)}} value={selectedDataType}>
                                        <option value="select">választható</option>
                                        {/* <option value="checkbox">checkbox</option>
                                        <option value="date">dátum</option> */}
                                        <option value="text">szöveg</option>
                                        {/* <option value="textarea">szövegbox</option>
                                        <option value="num">szám</option> */}
                                    </select>
                                    </Col>
                                    <Col lg="1">
                                        <FontAwesomeIcon size="lg" icon ="check-square" role="button" onClick={() => {datafieldName.length ? handleAddnewOperation() : setWarning("Hiányzó adatok")}}/>
                                    </Col>
                                    <Col lg="1">
                                        <FontAwesomeIcon size="lg" icon ="window-close"/>
                                    </Col>
                                    <Col lg="1">
                                        <FontAwesomeIcon size="lg" icon ="plus-square"/>
                                    </Col>
                                </Row>
                                {selectedDataType === "select" && 
                                <Row>
                                    <Form.Text>Opciók hozzáadása</Form.Text>
                                </Row>}
                                {selectedDataType === "select" && handleSelectChoice()}

                                <Row>
                                {selectedDataType === "select" && <Form.Text>Előnézet</Form.Text>}
                                </Row>
                                <Row>
                                    {selectedDataType === "select" && 
                                    <div className="option-preview">
                                        <Col>
                                            <select style={{border:'3px solid #cbcdcd',borderRadius:'10px',padding: '3px'}} name="type" id="type" className="dashboard-input-modal">
                                                {selectField.map(field => {
                                                    return(<option>{field}</option>)
                                                })}
                                            </select>
                                        </Col>
                                    </div>}
                                </Row>
                                <Row>
                                <Col lg="9">
                                    <button className="request-send-button" disabled={!newPoiDataFields.length || newPoiTypeName === ""} onClick={() => handleNewPoiType()}>POI típus létrehozása</button>
                                </Col>
                                <Col lg="3">
                                    <button className="request-send-button" style={{backgroundColor:'red'}} onClick={() => props.handleClose()}>mégsem</button>
                                </Col>
                                <Col>
                                    {warning !== "" && <p className="warning">{warning}</p>}
                                </Col>
                            </Row>
                            </Col>
                        </Modal.Body>
                    </Col>
                    }
                </Row>
                </Modal.Body>
            </Modal>
    )
}