//Import interfaces
import {
    createMessageInterface,
    baseUserInterface,
    defaultRequestInterface,
    deleteMessageInterface,
    defaultResponseInterface
} from "../ts/interfaces/messagehandler_interfaces"
import Config from "../config/config";

/**
 * Singleton modal for business logic to handle messages from the server
 */
export class MessageHandler {

    private static instance: MessageHandler;

    //Reusable userdata
    private userData:baseUserInterface = {
        identity: sessionStorage.username,
        token: sessionStorage.token,
        email: sessionStorage.username,
    }

    //Reusable requestdata
    private requestData:defaultRequestInterface = {
        user_email: sessionStorage.username,
        identity: sessionStorage.username
    }

    /**
     * Can't create the object without the instance method to prevent direct init
     */
    private constructor() {}
    /**
     * Controlling the singleton
     */
    public static getInstance(): MessageHandler {
        if (!MessageHandler.instance) {
            MessageHandler.instance = new MessageHandler();
        }
        return MessageHandler.instance;
    }


    /**
     * Send message to the backend with parameters: 
     * @param message string - message body
     * @param project_task Integer - project task id
     * @param parent_message string - parent message id if we send a comment for a comment
     */
    public createMessage(message:string, project_task:Array<any>, parent_message:string) {
        let request_data:createMessageInterface = {
            project_task: project_task,
            message: message,
            parent_message: parent_message,
        }

        let messageObj = {
            identity: sessionStorage.username,
            email: sessionStorage.username,
            request_data: {
                ...request_data,
                user_email: sessionStorage.username,
                identity: sessionStorage.username
                }
        }

        return new Promise((resolve, reject) => {
            const url = Config.endpoints.base + Config.endpoints.list.message.create;
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(messageObj)
                }).then(res => {
                    if(res.status !== 200) {
                        reject({statusCode:500, data:res.statusText})
                    }else {
                        return res.json();
                    }
                }).then( data => {
                    resolve({statusCode:200, data:data});
                }).catch(error => {
                    console.log("failed to fetch", error)
                });
        })
    }

    /**
     * Update message on the backend with parameters: 
     * @param message string - message body
     * @param project_task Integer - project task id
     * @param parent_message string - parent message id if we send a comment for a comment
     * @param id Number - The message id we want to modify
     */
    public modifyMessage(message:string, project_task:Number, parent_message:string, id:Number) {
        const url = Config.endpoints.base + Config.endpoints.list.message.update;

        let obj = {
            identity: sessionStorage.username,
            request_data: {
                id: id,
                user_email: sessionStorage.username,
                project_task: project_task,
                message: message,
                parent_message: "",
                identity: sessionStorage.username
                }
            }

        return new Promise((resolve, reject) => {
            fetch(url, {
                method:'POST', 
                headers: {
                    'Content-Type': 'application/json'
                }, 
                body: JSON.stringify(obj)}).then(res => {
                    if(res.status !== 200) {
                        reject({statusCode:500, data:"Error"})
                    }else {
                        return res.json()
                    }
                }).then(response => {
                    resolve({statusCode:200, data:response.data})
                })
        })
    }

    /**
     * Delete message: 
     * @param id Number - The message id we want to delete
     */
    public deleteMessage(id:Number) {

        let messageObj:deleteMessageInterface = {
            id:id.toString(),
            identity:sessionStorage.username
        }

        return new Promise((resolve, reject) => {
            const url = Config.endpoints.base + Config.endpoints.list.message.delete;
            fetch(url, {
                headers: {
                    'Content-Type': 'application/json'
                },
                method:'POST',
                body: JSON.stringify(messageObj)
                }).then(res => {
                    if(res.status === 404) {

                        let resp:defaultResponseInterface = {
                            statusCode:404,
                            data:"Message to delete not found"
                        }

                        resolve(resp)
                    }else {
                        return res.json()
                    }
                }).then( data => {

                    let resp:defaultResponseInterface = {
                        statusCode:200,
                        data:data
                    }

                    resolve(resp);
                }).catch(error => {
                    console.log("failed to fetch", error)
                });
        })
    }

    /**
     *
     */
    public getMessagesByUser() {
        const url = Config.endpoints.base + Config.endpoints.list.message.user.replace("{email}", sessionStorage.username) + sessionStorage.username;

        return new Promise((resolve, reject) => {
            fetch(url, {
                headers: {
                    'Content-Type': 'application/json'
                },
                }).then(res => {
                    if(res.status === 404) {

                        let resp:defaultResponseInterface = {
                            statusCode:404,
                            data:"No comments found."
                        }
                        
                        resolve(resp)
                    }else {
                        return res.json();
                    }
                }).then( data => {
                    
                    let resp:defaultResponseInterface = {
                        statusCode:200,
                        data:data
                    }

                    resolve(resp);
                }).catch(error => {
                    console.log("failed to fetch", error)
                });
        })
    }

    /**
     * @param taskid Number - id of the task we need to update
     * @param params Array - Array of string of parameters
     */
    public async getMessagesByTask(params:Array<string>, taskid:Number) {

        let urlParams:string = "/" + taskid + "?identity=" + sessionStorage.username

        return new Promise((resolve, reject) => {
            const url = Config.endpoints.base + Config.endpoints.list.message.task;
            fetch(url + urlParams, {
                headers: {
                    'Content-Type': 'application/json'
                },
                }).then(res => {
                    if(res.status === 404) {

                        let resp:defaultResponseInterface = {
                            statusCode:404,
                            data:"No comments found."
                        }
                        
                        resolve(resp)
                    }else {
                        return res.json();
                    }
                }).then( data => {
                    
                    let resp:defaultResponseInterface = {
                        statusCode:200,
                        data:data
                    }

                    resolve(resp);
                }).catch(error => {
                    console.log("failed to fetch", error)
                });
        })
    }
};
