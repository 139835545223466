import React, { useState, useEffect } from 'react';
import Box from "../../components/dashboard/Box";
import { DashboardController } from '../../controllers/DashboardController';
export default function WifiBox(props) {
    
	const [widgets, setWidgets] = useState(false);

    const DashboardControllerInst = DashboardController.getInstance();


    useEffect(() => {
        DashboardControllerInst.getWidgetData('traffic').then(response => {
            let temp = [...props.widgets];
            temp[0].value = response.data.incoming.total
            temp[1].value = response.data.outgoing.total
            temp[2].value = response.data.incoming.motorcycle.percentage.toFixed(2)
            temp[3].value = response.data.outgoing.motorcycle.percentage.toFixed(2)
            temp[4].value = response.data.incoming.car.percentage.toFixed(2)
            temp[5].value = response.data.outgoing.car.percentage.toFixed(2)
            temp[6].value = response.data.incoming.van.percentage.toFixed(2)
            temp[7].value = response.data.outgoing.van.percentage.toFixed(2)
            temp[8].value = response.data.incoming.truck.percentage.toFixed(2)
            temp[9].value = response.data.outgoing.truck.percentage.toFixed(2)
            temp[10].value = response.data.incoming.bus.percentage.toFixed(2)
            temp[11].value = response.data.outgoing.bus.percentage.toFixed(2)
            setWidgets(temp);
        });
    }, []);  // eslint-disable-line

    return (
        <Box key={0} {...props}  widgets={widgets ? widgets : props.widgets}/>
    );
};
