import React, {useState, useEffect} from 'react';
import { Row, Col } from "react-bootstrap";
import { ParkController } from "../../../controllers/ParkController";
import Config from "../../../config/config";

function ParkDetails(props) {

    //Park
    const ParkControllerInst = ParkController.getInstance();
    const [parkingList, setParkingList] = useState([]);
    const [activeParkingPlaceID, setActiveParkingPlaceID] = useState('1');
    const [activeParkingPlaceLocation, setActiveParkingPlaceLocation] = useState('');
    const [activeParkingPlaceDevices, setActiveParkingPlaceDevices] = useState([]);
    const [activeParkingPlaceMap, setActiveParkingPlaceMap] = useState('');
    const [cameraPic1, setCameraPic1] = useState('');
    const [cameraPic2, setCameraPic2] = useState('');
    const [snapShotDate1, setSnapShotDate1] = useState('');
    const [snapShotDate2, setSnapShotDate2] = useState('');
    const [parkingPoints, setParkingPoints] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        let list = [];

        ParkControllerInst.getParkingList().then(response => {
            if (response.statusCode === 200) {
                Object.values(response.data).forEach(item => {
                    list.push(item);
                });
                setParkingList(list);
                setActiveParkingPlaceID(response.data['1'].id);
                setActiveParkingPlaceLocation(response.data['1'].location);
                setActiveParkingPlaceDevices(response.data['1'].devices);
                setActiveParkingPlaceMap(Config.endpoints.base+response.data['1'].map);
                const pictures = ParkControllerInst.getParkPictures(response.data['1'].devices);
                setCameraPic1(pictures[0]);
                setCameraPic2(pictures[1]);
            }
        });

        ParkControllerInst.getParkData(activeParkingPlaceID).then(response => {
            if (response.statusCode === 200) {
                setParkingPoints([...response.data[list[0].devices[0]].places, ...response.data[list[0].devices[1]].places]);
                setSnapShotDate1(response.data[list[0].devices[0]].snapshot_last_update);
                setSnapShotDate2(response.data[list[0].devices[1]].snapshot_last_update);
                setLoading(false);
            }
        });
    },[]) // eslint-disable-line

    function setSelectedParkingPlace(selectedParkingPlaceID) {
        setLoading(true);
        const id = selectedParkingPlaceID.toString();
        setActiveParkingPlaceID(parkingList[id].id);
        setActiveParkingPlaceLocation(parkingList[id].location);
        setActiveParkingPlaceDevices(parkingList[id].devices);
        setActiveParkingPlaceMap(Config.endpoints.base+parkingList[id].map);
        const pictures = ParkControllerInst.getParkPictures(parkingList[id].devices);
        setCameraPic1(pictures[0]);
        setCameraPic2(pictures[1]);
        ParkControllerInst.getParkData(parkingList[id].id).then(response => {
            if (response.statusCode === 200) {
                setParkingPoints([]);
                setParkingPoints([...response.data[parkingList[id].devices[0]].places, ...response.data[parkingList[id].devices[1]].places]);
                setSnapShotDate1(response.data[parkingList[id].devices[0]].snapshot_last_update);
                setSnapShotDate2(response.data[parkingList[id].devices[1]].snapshot_last_update);
                setLoading(false);
            }
        });
    }

    let pointBackgroundColor = 'green';

    return(
        <div className="dashboard-container park-container">
            <Row>
                <Col lg={3} md={3} sm={6} xs={6}>
                    <h4>Parkolásmenedzsment</h4>
                </Col>
                <Col lg={3} md={3} sm={6} xs={6}>
                    <select style={{marginTop: '5px'}} defaultValue={activeParkingPlaceID} onChange={e => setSelectedParkingPlace(e.target.value)}>
                        {parkingList.map((elem,index) => {
                            return (<option key={index} value={index}>{elem.location}</option>)
                        })}
                    </select>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="dashboard-card-body container park-container">
                        <div className="dashboard-card-inside">
                            <div className="dashboard-card-header">
                                <div className="map-container">
                                    <img src={activeParkingPlaceMap} alt="Parkolási térkép" className={activeParkingPlaceMap === 'https://erp.staging.bekescsaba.manageyourcity.com/custom/utudevice/img/HunyadiBCS_parking.png' ? 'fixed-size-map-hunyadi' : 'fixed-size-map-dedinszky'}></img>
                                    {parkingPoints.map((point) => {
                                        point.empty === '0' ? pointBackgroundColor = 'red' : point.empty === '1' ? pointBackgroundColor = 'green' : pointBackgroundColor = 'gray';
                                        return (<figure className="park-point" style={{top: point.coords.y+"px", left: point.coords.x+"px", background: pointBackgroundColor}}></figure>)
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className="dasboard-container-row">
                <Col lg={6} md={6} sm={12} xs={12}>
                    <div className="dashboard-card-body container">
                        <div className="dashboard-card-inside">
                            <img className="camera-picture" src={cameraPic2}></img>
                            <code>{activeParkingPlaceDevices[1]} (Utolsó frissítés: {snapShotDate2})</code>
                        </div>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12}>
                    <div className="dashboard-card-body container">
                        <div className="dashboard-card-inside">
                            <img className="camera-picture" src={cameraPic1}></img>
                            <code>{activeParkingPlaceDevices[0]} (Utolsó frissítés: {snapShotDate1})</code>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default (ParkDetails);
