import React, {useState, useEffect} from 'react';
import { Row, Col } from "react-bootstrap";
import {Tabs, Tab} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DoughnutChart from '../charts/DoughnutChart';
import LineChart from '../charts/LineChart';
import {getLightTimes} from "../../../helpers/lighttimes";
import OwletDetails from '../owlet/owlet_details';
import connect from 'react-redux/es/connect/connect';
import { DashboardController } from '../../../controllers/DashboardController';
import { getOwletData } from '../../Map/_helpers/owletData';
import { OwletController } from '../../../controllers/OwletController';
import moment from 'moment';

function LightsDetails(props) {

    const [lights, setLights] = useState({smart: "0", smart_ready: "0", non_smart: "0"});
    const [key, setKey] = useState('közvilágítás');
	const [lightProblem, setLightProblem] = useState(0);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const DashboardControllerInst = DashboardController.getInstance();
    const OwletControllerInst = OwletController.getInstance();
    let from = moment().subtract(2,'days').format("YYYY-MM-DD");
    let to = moment().format("YYYY-MM-DD");

    useEffect(() => {  
        setLoading(true);
        DashboardControllerInst.getLampData().then(response => {
            if(response.statusCode === 200) {
                setLights(response.data)
            }
        }).catch(err => {
            console.log("err", err);
        });
        
        async function getOwlet() {
            let lampsWithProblem = await getOwletData();
            setLightProblem(lampsWithProblem.length);
            setLoading(false);
        }

        getOwlet();

        OwletControllerInst.getOwletStatistics(from, to).then(response => {
            if(response.statusCode === 200) {
                setTotal(response.data[0].energyConsumption)
            }
        });
  
    }, []) // eslint-disable-line

    const wifiColor = ["#ffd573", "#e9e9e9"]
    const lampDetailsColor = ["#ffd573", "#e9e9e9", "#5c9aff"]

    return(
        <Tabs defaultActiveKey="közvilágítás"       
        activeKey={key}
        onSelect={(k) => setKey(k)}
        >
            <Tab eventKey="közvilágítás" title="Közvilágítás">
                <Tab.Content className="detailed-lights-body">
                    {loading &&
                        <div className="loader-container" style={{padding:'20px'}}>
                            <div className="loader"></div>
                        </div>
                    }
                    <Row>
                        <Col lg={3} className="detailed-lights-data">
                            <Row>
                                <Col lg={12} className="detailed-lights-body-lightswitch">
                                    <Row>
                                        <Col lg={6}>
                                            <i className="icon"><FontAwesomeIcon size="3x" color="#FFE6A9" icon={['far', 'lightbulb']} /></i>
                                            <p>{getLightTimes().down}</p>
                                        </Col>
                                        <Col lg ={6}>
                                            <i className="icon"><FontAwesomeIcon size="3x" color="#ffd573" icon={['far', 'lightbulb-on']} /></i>
                                            <p>{getLightTimes().up}</p>
                                        </Col>
                                    </Row>
                                    <h4>Kapcsolási idők</h4>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12} className="detailed-lights-data-cols">
                                    <Row >
                                        <Col lg={12}>
                                            <i className="icon"><FontAwesomeIcon size="3x" color="#FFE6A9" icon={['far', 'plug']} /></i>
                                            <p>{total.toFixed(2)} kWh</p>
                                        </Col>
                                    </Row>
                                    <h4>SMART fogyasztás (3 nap)</h4>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12} className="detailed-lights-data-cols">
                                    <Row>
                                        <Col lg={6}>
                                            <i className="icon"><FontAwesomeIcon size="3x" color="#FFE6A9" icon={['far', 'plug']} /></i>
                                            <p>N/A</p>
                                            <h4>Fogyasztás<br />tegnap</h4>
                                        </Col>
                                        <Col lg ={6}>
                                            <i className="icon"><FontAwesomeIcon size="3x" color="#ffd573" icon={['far', 'bolt']} /></i>
                                            <p>N/A</p>
                                            <h4>Fogyasztás<br />összesen</h4>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row>
                                <Col lg={12} className="detailed-lights-data-cols">
                                    <Row>
                                        <Col lg={6}>
                                            <i className="icon"><FontAwesomeIcon size="3x" color="#FFE6A9" icon={['far', 'piggy-bank']} /></i>
                                            <p>N/A</p>
                                            <h4>Tervezett<br />megtakaritás</h4>
                                        </Col>
                                        <Col lg ={6}>
                                            <i className="icon"><FontAwesomeIcon size="3x" color="#ffd573" icon={['far', 'coins']} /></i>
                                            <p>N/A</p>
                                            <h4>Megtakaritás<br />összesen</h4>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>

                        <Col lg={9} className="detailed-lights-chart">
                            <Row>
                                <Col lg={6}>
                                    <DoughnutChart data={[lights.smart, lights.non_smart, lights.smart_ready]} color={lampDetailsColor} title={"Világitási ezközök"} value={lights.non_smart} types={['Smart', 'Hagyományos', 'Smart Ready']}/>
                                </Col>
                                <Col lg={6}>
                                    <DoughnutChart data={[lights.smart-lightProblem, lightProblem]} color={wifiColor} title={"Smart"} value={lights.smart} types={['Aktiv', 'Inaktiv']}/>
                                </Col>
                            </Row>

                            <Row>
                                <Col lg={12} >
                                    <LineChart step={10} labels={[]} data={[]} types={[{title:"Napi"}, {title:"Heti"}, {title:"Havi"}]} title={'Átlagos fogyasztás'}/>
                                </Col>
                            </Row>
                        </Col>
                    </Row>                    
                </Tab.Content>
            </Tab>
            <Tab eventKey="owlet" title="Owlet">
                <Tab.Content className="detailed-owlet-body">
                    <OwletDetails active={key === "owlet" ? true : false} />
                </Tab.Content>
            </Tab>
        </Tabs>
      );
}

const mapStateToProps = state => ({

});

export default connect(
	mapStateToProps,
	null,
)(LightsDetails);
