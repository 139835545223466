import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import Layer from './Layer';
import TileLayer from './TileLayer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Row, Col} from "react-bootstrap";

const style = {
    border: '1px solid #bfbfbf',
    cursor: 'move',
    backgroundColor: 'rgba(209, 209, 209, 0.1)',
    padding:'5px',
    marginBottom: '.5rem',
};

const ItemTypes = {
    CARD: 'card',
  }

  
export const Card = ({index, moveCard, loadedLayers, loadLayer,  selectedLayer, cadastre, modifyStyle, enableCadastreFilter, type, tile, loadedTileChange, loadedTiles}) => {
    const ref = useRef(null);
    const [, drop] = useDrop({
        accept: ItemTypes.CARD,
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            // Time to actually perform the action
            moveCard(dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag(() => ({
        type: ItemTypes.CARD, 
        item: { cadastre, index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    }), [cadastre, index]);

    const opacity = isDragging ? 0 : 1;

    drag(drop(ref));

    return (<div style={{ ...style, opacity }}>
                {type === 'layer' ?
                <Row className="draggable-active-layer">
                    <Col className="drag-cont" ref={ref} lg={1} md={1}>
                        <FontAwesomeIcon style={{marginTop: '7px', marginLeft: '2px'}} color={"#bdbdbd"} className="" icon={['fas', "arrows-alt-v"]} />
                    </Col>
                    <Col lg={10} md={10} style={{cursor:'default'}}>
                        <Layer 
                            loadedLayers={loadedLayers} 
                            selectedLayer={selectedLayer} 
                            loadLayer={loadLayer} 
                            cadastre={cadastre} 
                            modifyStyle={modifyStyle} 
                            active={true}
                            enableCadastreFilter={enableCadastreFilter}
                            dragref={ref}
                        />
                    </Col>
                </Row>
                :
                <Row>
                    <Col className="drag-cont" ref={ref} lg={1} md={1}>
                        <FontAwesomeIcon style={{marginTop: '7px', marginLeft: '2px'}} color={"#bdbdbd"} className="" icon={['fas', "arrows-alt-v"]} />
                    </Col>
                    <Col lg={10} md={10} style={{cursor:'default'}}>
                        <TileLayer tile={tile} loadedTileChange={loadedTileChange} loadedTiles={loadedTiles}/>
                    </Col>
                </Row>
                
                }
		    </div>);
};