import React from 'react';
import {Row, Col, Modal } from "react-bootstrap";

function SupportPopup(props) {
    return (
        <React.Fragment>
            <Modal dialogClassName="contact-modal" show={props.shown} onHide={props.closeSupport}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h4>Technikai kapcsolat</h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className="infos">
                    <Row className="contact-information">
                        <Col lg={12} className="key"><h6>Rendszergazda</h6></Col>
                        <Col lg={12}><p>Telefon: +36 00 00 0000</p></Col>
                        <Col lg={12}><p>Email: hello@rendszergazda.hu</p></Col>
                    </Row>
                </div>
                </Modal.Body>                
            </Modal>
        </React.Fragment>
    );
 
}

SupportPopup.propTypes = {
};

export default SupportPopup;
