import React, {Component} from 'react';
import { connect } from 'react-redux';
import RenderLightBox from "./RenderLightBox";
import {getLightTimes} from "../../helpers/lighttimes";

let lights = [
    {
        'widget': 'Divider',
        'size': 'full',
        'label': 'Világítási eszközök'
    },
    {
        'widget': 'IconValue',
        'size': 'half',
        'value': "N/A",
        'icon': 'lightbulb-on',
        'unit': '',
        'label': 'Hagyományos'
    },
    {
        'widget': 'IconValue',
        'size': 'half last',
        'value': "N/A",
        'icon': 'smart-ready',
        'unit': '',
        'label': 'Smart ready'
    },
    {
        'widget': 'IconValue',
        'size': 'half',
        'value': "N/A",
        'icon': 'smart',
        'unit': '',
        'label': 'Smart'
    },
    {
        'widget': 'Divider',
        'size': 'full',
        'label': 'Kapcsolási idők'
    },
    {
        'widget': 'IconMultipleValue',
        'size': 'half last',
        'values': [
            {
                'icon': 'lightbulb',
                'value': getLightTimes().up,
                'unit': '',
                'color': '#999999'
            },
            {
                'icon': 'lightbulb-on',
                'value': getLightTimes().down,
                'unit': '',
                'color': '#FFD673'
            }
        ],
    },
    {
        'widget': 'Divider',
        'size': 'full',
        'label': 'SMART fogyasztási adatok'
    },
    {
        'widget': 'SimpleValueUnit',
        'size': 'third',
        'value': '324',
        'unit': 'kWh',
        'label': 'Tegnap'
    },
    {
        'widget': 'SimpleValueUnit',
        'size': 'third',
        'value': '4579',
        'unit': 'kWh',
        'label': 'Összes fogyasztás'
    },
    {
        'widget': 'SimpleValueUnit',
        'size': 'third',
        'value': '189',
        'unit': 'kWh',
        'label': 'Összes megtakarítás'
    }
];

let details = [
    {
        'widget': 'DoughnutChart',
        'size': 'fourth',
        'value': "N/A",
        'icon': 'lightbulb-on',
        'unit': '',
        'label': 'Világítási eszközök',
        'iconType': 'fal',
        'iconColor': '#FFC303'
    },
    {
        'widget': 'DoughnutChart',
        'size': 'fourth',
        'value': "N/A",
        'icon': 'smart-ready',
        'unit': '',
        'label': 'Smart Ready',
        'iconType': 'fal',
         'iconColor': '#FFC303'
    },
    {
        'widget': 'DoughnutChart',
        'size': 'fourth',
        'value': "N/A",
        'icon': 'smart',
        'unit': '',
        'label': 'Smart',
        'iconType': 'fal',
         'iconColor': '#FFC303'

    },
    {
        'widget': 'IconMultipleValue',
        'size': 'fourth last',
        'values': [
            {

                'icon': 'lightbulb',
                'value': getLightTimes().up,
                'unit': '',
                'color': '#999999'
            },
            {
                'icon': 'lightbulb-on',
                'value': getLightTimes().down,
                'unit': '',
                'color': '#FFD673'
            }
        ],
        'label': 'Kapcsolási idő'
    }
];

class LightBox extends Component {
    render() {
        const { onDeviceInfo, ...props } = this.props;
        return (
            <RenderLightBox lights={lights} details={details} {...props} />
        );
    }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LightBox);

