import React from 'react';
import FilterComponent from "../../Project/Filter";
import connect from 'react-redux/es/connect/connect';
import { getMapLayerOptions } from '../../../state/reducers/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from "react-tooltip";
import SavedFilterComponent from '../../Project/_helper/SavedFilterComponent';

function MapConfigSidebar(props) {


    const { loadedProjectLayers, allLoadedLayers} = props.layerOptions;
    return(
        <div className="map-config-sidebar">
            <div className="map-config-sidebar-header-info">
                <div>
                    <p className="active-project">
                        <span><FontAwesomeIcon color={loadedProjectLayers.length ? "#7dd173" : "#d17373"} size="1x" icon={['fas', "circle"]} />  Projekt</span> 
                        - {loadedProjectLayers.length? loadedProjectLayers.length + " darab" : "Nincs aktív"}
                        <FontAwesomeIcon data-tip data-for="map-project-tooltip" data-iscapture="true" className="info" size="1x" icon={['fas', "info"]} />
                        <ReactTooltip
                            id="map-project-tooltip"
                            type="light"
                            place="bottom"
                            multiline={true}>
                            <span>Az aktív projekteket ezen az oldalsávon fogja tudni szűrni.</span> 
                        </ReactTooltip>
                    </p>
                    {loadedProjectLayers.length ? <p>Feladatok száma: {props.loadedElements.project}</p>: null}
                </div>
                <div>
                    <p className="active-cadastre">
                        <span><FontAwesomeIcon color={allLoadedLayers.length ? "#7dd173" : "#d17373"} size="1x" icon={['fas', "circle"]} />  Kataszter</span> 
                        - {allLoadedLayers.length ? allLoadedLayers.length + " darab" : "Nincs aktív"}
                        <FontAwesomeIcon data-tip data-for="map-cadastre-tooltip" data-iscapture="true" className="info" size="1x" icon={['fas', "info"]} />
                        <ReactTooltip
                            id="map-cadastre-tooltip"
                            type="light"
                            place="bottom"
                            multiline={true}>
                            <span>A katasztereket külön a kataszter oldalsávon tudja egyenként felkapcsolni.<br />Az aktiv katasztereket szintén ezen az oldalsávon tudja egyenként szűrni.</span> 
                        </ReactTooltip>
                    </p>
                    {allLoadedLayers.length ? <p>Kataszterelemek száma: {props.loadedElements.cadastre}</p>: null}
                </div>
                <div className="map-saved-filters-container">
                    <SavedFilterComponent active={loadedProjectLayers.length}/>
                </div>
                <label className="map-config-filter-label">Projekt szűrési feltételek</label>
            </div>
            {loadedProjectLayers.length ?
                <FilterComponent page={props.type} type={""} setReset={props.setReset} reset={props.reset} loadSavedFilterMissingLayers={props.loadSavedFilterMissingLayers} projectCadastres={props.projectCadastres}/>
            :
                <p className="filter-information"> A szűréshez kapcsoljon fel egy projektet.</p>
            }
        </div>
    )
}

const mapStateToProps = state => ({
    layerOptions: getMapLayerOptions(state)
});

const mapDispatchToProps = (dispatch) => ({
});
export default connect(
   mapStateToProps,
   mapDispatchToProps,
)(MapConfigSidebar);