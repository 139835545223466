import React, { useCallback } from 'react';
import { Card } from './ActiveLayerCard';
import { getMapLayerOptions, setLoadedDynamicCadastreLayers } from '../../../state/reducers/ui';
import { getUserCadastreData } from '../../../state/reducers/user';
import connect from 'react-redux/es/connect/connect';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend';

function ActiveCadastreLayerContainer(props) {

    const moveCard = useCallback((dragIndex, hoverIndex) => {
        const dragCard = props.layerOptions.allLoadedLayers[dragIndex];
        props.setLoadedCadastreLayerOrder(dragIndex, dragCard, hoverIndex);

    }, [props.layerOptions.allLoadedLayers]); // eslint-disable-line

    const renderCard = (cadastre_id, index) => {
        return (
        <Card
            enableCadastreFilter={props.enableCadastreFilter} 
            key={parseInt(cadastre_id)} 
            index={index} 
            moveCard={moveCard} 
            loadLayer={props.loadLayer} 
            loadedLayers={props.loadedLayers} 
            selectedLayer={props.selectedLayer} 
            cadastre={props.cadastreData[cadastre_id]} 
            modifyStyle={props.modifyStyle}
            type="layer"
            />);
    };

    function setupActiveCadastres() {
        return props.layerOptions.allLoadedLayers.map((card, i) => renderCard(card, i))
    }
    
    return(
        <DndProvider backend={HTML5Backend}>
            <div>
                {setupActiveCadastres()}
            </div>
        </DndProvider>
    );
}

const mapStateToProps = state => ({
    layerOptions: getMapLayerOptions(state),
    cadastreData : getUserCadastreData(state),
});

const mapDispatchToProps = (dispatch) => ({
    setLoadedCadastreLayerOrder: (dragIndex, dragCard, hoverIndex) => {
        dispatch(setLoadedDynamicCadastreLayers(dragIndex, dragCard, hoverIndex))
    }
});
 
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ActiveCadastreLayerContainer);
 