import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const IconMultipleValue = ({label,size, values, unit, icon,color}) => {

	const ListValues = (props) => {
		const ValueList = props.values.map((val) => {
			const data = {...val};
			return	(
				<div key={uuidv4()} className="value-item">
					<i className="icon" style={{color:data.color}}>
						<FontAwesomeIcon icon={['fal', data.icon]} />
					</i>
					<div className="value">{data.value} {data.unit}</div>
				</div>
			)

		});

		return ( ValueList );
	};

	return (
	   <div className={'icon-value icon-value-multiple widget justify-content-center align-items-center '+ size }>
		<div className="display justify-content-center align-items-center">
			<ListValues values={values}/>
		</div>
		<span>{label}</span>
	   </div>
	);
};

IconMultipleValue.propTypes = {};

export default IconMultipleValue;