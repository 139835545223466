export const traffic = [
	{
		'widget': 'IconValue',
		'size': 'half',
		'value': "0",
		'icon': 'sign-in',
		'unit': '',
		'label': 'Városba be'
	},
	{
		'widget': 'IconValue',
		'size': 'half last',
		'value': "0",
		'icon': 'sign-out',
		'unit': '',
		'label': 'Városból ki'
	},
	{
		'widget': 'HorizontalProgress',
		'size': 'half border',
		'icon': 'motorcycle',
		'label': 'Motorkerékpár',
		'value': '0',
		'unit': '%',
	},
	{
		'widget': 'HorizontalProgress',
		'size': 'half last border',
		'icon': 'motorcycle',
		'label': 'Motorkerékpár',
		'value': '0',
		'unit': '%',
	},
	{
		'widget': 'HorizontalProgress',
		'size': 'half',
		'icon': 'car',
		'label': 'Személyautó',
		'value': '0',
		'unit': '%',
	},
	{
		'widget': 'HorizontalProgress',
		'size': 'half last',
		'icon': 'car',
		'label': 'Személyautó',
		'value': '0',
		'unit': '%',
	},
	{
		'widget': 'HorizontalProgress',
		'size': 'half',
		'icon': 'truck',
		'label': 'Kistehergépkocsi',
		'value': '0',
		'unit': '%',
	},
	{
		'widget': 'HorizontalProgress',
		'size': 'half last',
		'icon': 'truck',
		'label': 'Kistehergépkocsi',
		'value': '0',
		'unit': '%',
	},
	{
		'widget': 'HorizontalProgress',
		'size': 'half',
		'icon': 'truck-container',
		'label': 'Tehergépkocsi',
		'value': '0',
		'unit': '%',
	},
	{
		'widget': 'HorizontalProgress',
		'size': 'half last',
		'icon': 'truck-container',
		'label': 'Tehergépkocsi',
		'value': '0',
		'unit': '%',
	},
	{
		'widget': 'HorizontalProgress',
		'size': 'half',
		'icon': 'bus',
		'label': 'Busz',
		'value': '0',
		'unit': '%',
	},
	{
		'widget': 'HorizontalProgress',
		'size': 'half last',
		'icon': 'bus',
		'label': 'Busz',
		'value': '0',
		'unit': '%',
	}
]