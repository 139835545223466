import React, { useState, useEffect } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Table, Popover, OverlayTrigger } from 'react-bootstrap';
import Information from "../Information";
import Progress from "./Progress";

//React Table imports
import {    useTable,
            usePagination,
            useSortBy,
            useFilters,
            useGlobalFilter,
            useGroupBy,
            useExpanded,
            useRowSelect,
        } from 'react-table'


const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef
    
        React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])
    
        return (
            <>
                <input type="checkbox" ref={resolvedRef} {...rest} />
            </>
        )
    }
)

/**
 *
 *
 */
export default function DataTable({data,
    userRole,
    columns,
    selectedRows,
    updateMultipleData,
    fetchingData,
    totalTasks
}) {

    const [tableExpanded, setTableExpanded] = useState({})

    //Const with built in table functions
    const {
        //base table properties
        getTableProps,
        //body properties
        getTableBodyProps,
        //Contains all the headers in a flattened structure
        headerGroups,
        //Refactored list with the values to put in table, also contains the full object passed into
        //Rows is only needed if there arend pagination active
        //rows,
        prepareRow,
        //Page is like a row but for pagination, only contains the rows for one page
        page,

        visibleColumns,
        state,
        allColumns,
        selectedFlatRows,
      }
      /**
       * Root hook
       * The base needs are columns, and the data to render
       * Data should be a list of object, columns get the object key to render it.
       */
      = useTable({
            columns,
            data,
            // defaultColumn,
            state: {
                selectedRowPaths: selectedRows,
                expanded: tableExpanded
            },
            //Obj for setting up table init state, here i can probably toggle on off pages, sizes, and groups ?
            initialState: { pageIndex: 0,
                            pageSize: 100,
                            groupBy: [
                                "device.location"
                            ],
                            hiddenColumns: [
                                "driver",
                                "planned_part",
                                "information.beav_tip",
                                "note_public",
                                "note_private"
                            ],
                            selectedRowPaths: selectedRows,
                            expanded: tableExpanded


                        }
        },
        useFilters,
        useGlobalFilter,
        useGroupBy,
        //Add sort by option, this is also implemented
        useSortBy,
        //Grouping function
        //Expanded rows
        useExpanded,
        //Bool for use ppagination
        usePagination,
        useRowSelect,
        // useBlockLayout,
        // useResizeColumns,
        hooks => {
          hooks.visibleColumns.push(columns => [
            // Let's make a column for selection
            {
              id: 'selection',
              width:30,
              // The header can use the table's getToggleAllRowsSelectedProps method
              // to render a checkbox
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <div>
                   <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                </div>
              ),
              // The cell can use the individual row's getToggleRowSelectedProps method
              // to the render a checkbox
              Cell: ({ row }) => (
                <div>
                  <IndeterminateCheckbox type="checkbox" {...row.getToggleRowSelectedProps()} />
                </div>
              ),
            },
            ...columns,
          ])
        }
      )

    useEffect(() => {
        setTableExpanded(state.expanded);
    }, [state]);

    const canUpdate = userRole === 'PROJECTADMIN' || userRole === 'SUPERADMIN' || userRole === 'PROJECTAPPROVAL';
    
    return(
    <>
        <div style={{position:'sticky', top:75, zIndex:9}}>
            <Row className="progression-row">
                <Col lg="11" className={'col col-progress'}>
                    <div className="search-container">
                    <span><label>Feladatok:</label> 
                    <p>{totalTasks}</p>
                </span>
                    </div>
                    <span className="table-project-progression "><Progress info={<span>Előrehaladás: </span>} value={data.length ? ((data.filter(x => x.approved === 1).length/data.length)*100).toFixed(1) : 0} mainProgress={true} /></span>
                    <span className="table-project-progression "><Progress info={<span>Felszerelt: </span>} value={data.length ? ((data.filter(x => x.app === 1).length/data.length)*100).toFixed(1) : 0} mainProgress={true}  /></span>
                    <span className="table-project-progression "><Progress info={<span>Elfogadott: </span>} value={data.length ? ((data.filter(x => x.done === 1).length/data.length)*100).toFixed(1) : 0}  mainProgress={true} /></span>
                </Col>

                <Col lg="1" className={'col'}>
                    <div className="col-settings" title="Beállítások">
                        {['bottom'].map((placement) => (
                            <OverlayTrigger
                            trigger="click"
                            key={placement}
                            rootClose
                            placement={placement}
                            overlay={
                                <Popover id={`popover-positioned-${placement}`}>
                                <Popover.Title as="h3">{`Oszlopok`}</Popover.Title>
                                <Popover.Content>
                                {allColumns.map(column => {
                                    return (typeof column.Header === 'string' || column.Header instanceof String) ?
                                    <div key={column.id}>
                                        <label>
                                        <input type="checkbox" {...column.getToggleHiddenProps()} />{' '}
                                        {column.Header}
                                        </label>
                                    </div>
                                    :
                                    null
                                })}
                                </Popover.Content>
                                </Popover>
                            }
                            >
                            <i role="button"><FontAwesomeIcon icon={['fas', 'cog']}/> </i>
                            </OverlayTrigger>
                        ))}
                    </div>
                </Col>
            </Row>

            <Row className="table-pagination-row">
                <Col lg="10" className={'col'}>
                    {canUpdate && <button className="btn btn-secondary btn-acceptance" onClick={() => updateMultipleData(selectedFlatRows, "accept")} disabled={!selectedFlatRows.length}><span><FontAwesomeIcon icon={['fas', 'check']} /> </span>Elfogadás</button>}
                    {canUpdate && <button className="btn btn-secondary btn-undo" onClick={() => updateMultipleData(selectedFlatRows, "revert")} disabled={!selectedFlatRows.length}><span><FontAwesomeIcon icon={['fas', 'times']} /> </span>Visszavonás</button>}
                    <button className="btn btn-secondary btn-sync" onClick={() => updateMultipleData(selectedFlatRows, "update")} disabled={!selectedFlatRows.length}><span><FontAwesomeIcon icon={['fas', 'sync']} /> </span>Frissítés</button>
                </Col>
            </Row>
        </div>
        <Row className="project-table-wrapper">
            { fetchingData ?
            <div className="table-empty-data">
                <div className="loader-container">
                    <div className="loader" style={{margin:"auto"}}>
                    </div>
                </div>
            </div> :
            data.length ?
            <Col>
                {/* Pass table properties, initial is an object with a role attribute that its a "table" */}
                <Table {...getTableProps()} striped bordered hover>
                    <thead>
                    {/* Map trough header groups : Contains an array with the headers and their props*/}
                        {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                            <>
                                {/* column here is a header with more props, and values*/}
                                {/* Add an arg to getporps to sort the data in columns*/}
                                <th {...column.getHeaderProps(column.getSortByToggleProps({ title: "Sorrend" }))}>
                                {column.canGroupBy ? (
                                    // If the column can be grouped, let's add a toggle
                                    <span {...column.getGroupByToggleProps({title:'Csoportba rendezés'})}>
                                    {column.isGrouped ?
                                        <span><FontAwesomeIcon icon={['fas', 'layer-minus']} /> </span>
                                    :
                                        <span><FontAwesomeIcon icon={['fas', 'layer-plus']} /> </span>
                                        }
                                    </span>
                                ) : null}
                                    {column.render('Header')}
                                    <span>
                                        {column.isSorted
                                        ? column.isSortedDesc
                                            ? <span>  <FontAwesomeIcon icon={['fas', 'caret-down']} /></span>
                                            : <span>  <FontAwesomeIcon icon={['fas', 'caret-up']} /></span>

                                        : ""}
                                    </span>

                                </th>
                            </>
                            ))}
                        </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                    {page.map((row) => {  // eslint-disable-line
                        prepareRow(row)
                        return (
                        <React.Fragment key={row.id}>
                        <tr {...row.getRowProps()}
                        style={{
                            background: row.isExpanded
                            ? '#f6fbff'
                            : 'white',
                        }}
                        >
                            {row.cells.map(cell => {
                            return (
                                <td {...cell.getCellProps()}
                                style={{
                                    background: cell.isGrouped
                                    ? ''
                                    : cell.isAggregated
                                    ? ''
                                    : cell.isPlaceholder
                                    ? '#ededed'
                                    : '#ededed',
                                }}
                                >
                                {cell.isGrouped ? (
                                    // If it's a grouped cell, add an expander and row count
                                    <>
                                    <span {...row.getToggleRowExpandedProps()}>
                                        {row.isExpanded
                                            ? <span>  <FontAwesomeIcon icon={['fas', 'chevron-square-down']} /></span>
                                            : <span>  <FontAwesomeIcon icon={['fas', 'chevron-square-right']} /></span>
                                        }
                                    </span>{' '}
                                    {cell.render('Cell')}
                                    </>
                                ) : cell.isAggregated ? (
                                    // If the cell is aggregated, use the Aggregated
                                    // renderer for cell
                                    cell.render('Aggregated')
                                ) : cell.isPlaceholder ? null : ( // For cells with repeated values, render null
                                    // Otherwise, just render the regular cell
                                    cell.render('Cell')

                                )}
                                </td>
                            )
                            })}
                        </tr>
                        {row.isExpanded && !row.isGrouped ?  (
                        <tr>
                            <td className="info-cell" colSpan={visibleColumns.length}>
                            <Information data={row.original}/>
                            </td>
                        </tr>
                        ) : null}
                        </React.Fragment>
                        )
                    })}
                    </tbody>
                </Table>
            </Col>
            : <div className="table-empty-data"><p>Nem található feladat.</p></div>}
        </Row>
    </>
    );

}
