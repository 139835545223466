import React from "react";
import Box from "../../components/dashboard/Box";
import {connect} from "react-redux";
function RenderLightBox(props) {

    props.lights[1].value = props.lampData.non_smart;
    props.lights[2].value = props.lampData.smart_ready;
    props.lights[3].value = props.lampData.smart;
    props.lights[7].value = props.consum.toFixed(2);

    return (
        <Box key={0} widgets={props.lights} details={props.details} {...props} />
    );
}

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RenderLightBox);