import React, {useState} from 'react';
import { Button, Modal } from 'react-bootstrap';

const IconValue = ({ url, size }) => {

    const [show, setShow] = useState();

    return (
        <React.Fragment>
        <div className={'image-value '+ size } style={{backgroundImage: `url(${url})`}} onClick={() => setShow(true)}/>
        <Modal className="confirm" show={show} aria-labelledby="contained-modal-title-vcenter" centered >
          <Modal.Body>
            <img
                className="img dashboard-project-image"
                src={url}
                alt="img"
              />
          </Modal.Body>
          <Modal.Footer>
              <Button variant="danger" onClick={() => {setShow(false)}}>Bezár</Button>
          </Modal.Footer>
        </Modal>
        </React.Fragment>
    );
};

IconValue.propTypes = {};

export default IconValue;