import React, {useEffect, useState} from 'react';
import { Form, Tabs, Tab } from 'react-bootstrap';
import "../../styles/ui-elements/_input.scss"
import { addMapFilter, 
    removeMapFilter, 
    getFilterOptions, 
    startMapSearch, 
    getIsFiltering, 
    getNavigationState,
    resetMapFilter, 
    setMapDetailHide, 
    getMapState, 
    setMapType, 
    setLoadedProjectLayers } from '../../state/reducers/ui';
import connect from 'react-redux/es/connect/connect';
import { FormGroupWrapperDefault } from "./ui/FormGroupWrapper";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SavedFilterComponent from './_helper/SavedFilterComponent';
import CadastreFilterComponent from './sidebar/CadastreFilterComponent';

/**
 * Documentation
 * @param { filterOptions } props All the options with elements to render filtering FormGroups. Comes from REDUX.
 */
function FilterComponent(props) {

    const [searchWord, setSearchWord] = useState(false);
    const [showTaskDetails, setShowTaskDetails] = useState(true);
    const [showTaskDate, setShowTaskDate] = useState(true);
    const [cadastreFiltering ,setCadastreFiltering] = useState(false);

    useEffect(() => {
        //force update
    }, [props.isFiltering])

    const filters = props.navigationState.query.searchFilters;
    const filterWord = props.navigationState.query.term;

    const addFilter = (filterObj) => {
        props.addMapFilter(filterObj);
    }


    function handleSubmit(e){
        e.preventDefault();
		const data = new FormData(e.target);
		props.onSearch(data.get('search'));
    }

    function handleSearch(){
		props.onSearch(searchWord);
    }

    function clearFilterOptions() {
        props.resetMapFilter();
    }
    
    useEffect(() => {
        if(!props.isFiltering) {
            setSearchWord("");
        }
    }, [props.isFiltering])


    function checkActiveFilters(key) {
        if(filters.hasOwnProperty(key)) {
            return filters[key]
        } else {
            return "null"
        }
    }

    function clearStatusFilter() {
        if(filters.hasOwnProperty("app")) {
            delete filters["app"]
            addFilter({"app":"null"})
        }
        if(filters.hasOwnProperty("approved")) {
            delete filters["approved"]
            addFilter({"approved":"null"})

        }
        if(filters.hasOwnProperty("done")) {
            delete filters["done"]
            addFilter({"done":"null"})

        }
    }

    function addStatusFilter(value) {
        clearStatusFilter();
        if(value === "0") {
            addFilter({"app":1})
        }
        else if(value === "1") {
            addFilter({"done":1})
        }
        else if(value === "2") {
            addFilter({"approved":1})
        }
        else if(value === "3") {
            addFilter({"approved":2})
        }
    }

    function getcurrentStatusFilterValue() {
        if(filters.hasOwnProperty("approved")) {
            if(filters.approved === 1) {
                return "2"
            }else {
                return "3"
            }
        }
        if(filters.hasOwnProperty("done")) {
            return "1";
        }
        if(filters.hasOwnProperty("app")) {
            return "0"
        }
    }

    return(
        <div className="">	
            {!cadastreFiltering || props.projectCadastres === undefined ?
                <div>
                    <div className="col-md-12">
                        <div className="view-filters">
                            <div className="align-self-start">
                                <button className="filterdeletebutton" variant="primary" onClick={() => {clearFilterOptions()}}>Szűrési feltételek törlése</button>
                            </div>
                        </div>
                        <form style={{marginBottom:'15px', display:'flex', border:'1px solid #e9e9e9'}}  onSubmit={(e) => {handleSubmit(e)}}>
                            <input type="text" name="search" className="small form-control filter-search" placeholder="Keresés" onChange={(e) => {setSearchWord(e.target.value)}} value={searchWord !== false ? searchWord : filterWord}/>
                            <i role="button" onClick={(e) => {handleSearch()}}><FontAwesomeIcon icon={['far', 'search']} /></i>
                        </form>
                        {props.navigationState.view !== 'map' && <SavedFilterComponent active={true}/>}
                    </div>
                    <p className="filter-separator">
                        <i role="button"  className="filter-separator-button" onClick={() => setShowTaskDetails(!showTaskDetails)}><FontAwesomeIcon size="xs" icon={['fas', showTaskDetails ? 'minus' : 'plus']} /></i>
                        <span className="filter-separator-text">Feladatok</span>
                    </p>
                    {showTaskDetails ? 
                        <div className="device-details">
                            <Form.Group controlId="status">
                                <Form.Label>Feladat típusa</Form.Label>
                                <Form.Control as="select" className="form-control" placeholder="Osztály"
                                onChange = {(event) => {addFilter({"act": event.target.value !== "null" ? parseInt(event.target.value) : "null"})}}
                                value={props.isFiltering ? checkActiveFilters("act") : "null"}>
                                    <option value="null">----</option>
                                    <option value="1">Felszerelés</option>
                                    <option value="2">Leszerelés</option>
                                    <option value="3">Fejlesztés</option>

                                </Form.Control>
                            </Form.Group>
                            <Form.Group controlId="status">
                                <Form.Label>Feladat státusza</Form.Label>
                                <Form.Control as="select" className="form-control" placeholder="Osztály"
                                onChange = {(event) => {addStatusFilter(event.target.value)}}
                                value={props.isFiltering ? getcurrentStatusFilterValue() : "null"}>
                                    <option value="null">----</option>
                                    <option value="0">Kivitelezve</option>
                                    <option value="1">Jóváhagyva</option>
                                    <option value="2">Elfogadva</option>
                                    <option value="3">Elutasítva</option>
                                </Form.Control>
                            </Form.Group>

                            <FormGroupWrapperDefault name="Tartalmaz üzenetet" type="has_message" filterOption={ [{label:"Igen", value:"1"}, {label:"Nem", value:"0"}] } addFilter={addFilter} isFiltering={props.isFiltering} checkActiveFilters={checkActiveFilters}/>
                            <p className="filter-separator-date">
                                <i role="button"  className="filter-separator-button" onClick={() => setShowTaskDate(!showTaskDate)}><FontAwesomeIcon size="xs" icon={['fas', showTaskDate ? 'minus' : 'plus']} /></i>
                                <span className="filter-separator-text">Feladat határideje</span>
                            </p>
                                {showTaskDate ? 
                                    <div className="img-container">
                                        <Form.Group controlId="dateo">
                                            <Form.Label>Kezdete</Form.Label>
                                            <input  type="date" 
                                                    id="dateo" 
                                                    name="start_date"
                                                    className="filter-date-pick"
                                                    onChange={(event) => addFilter({"dateo": event.target.value})}
                                                    value={props.isFiltering ? checkActiveFilters("dateo") : "null"}
                                                    />
                                        </Form.Group>
                                        <Form.Group controlId="datee">
                                            <Form.Label>Vége</Form.Label>
                                            <input  type="date" 
                                                    id="datee" 
                                                    name="end_date"
                                                    className="filter-date-pick"
                                                    onChange={(event) => addFilter({"datee": event.target.value})}
                                                    value={props.isFiltering ? checkActiveFilters("datee") : "null"}
                                                    />
                                        </Form.Group>
                                    </div>
                                    :
                                    null 
                                }
                        </div>
                    :
                    null}
                    <div className="project-cadastre-filter-button-container">
                        <button className="project-cadastre-filter-button" onClick={() => setCadastreFiltering(true)} disabled={!props.projectCadastres.length}>Kataszter alapú keresés</button>
                    </div>
                </div>
                :
                <div className="project-cadastre-filter-container">
                    <span role="button" onClick={() => {setCadastreFiltering(false)}} className="back-span"><FontAwesomeIcon className="" role="button" icon={['fas', "chevron-left"]}/><span className="back">Vissza</span></span>
                    <Tabs defaultActiveKey={props.projectCadastres[0].cadastre_label} cassName="cadastre-filter-tabs">
                    {props.projectCadastres.map(cadastre => {
                        return(
                            <Tab eventKey={cadastre.cadastre_label} title={cadastre.cadastre_label}>
                                <CadastreFilterComponent setEnableFilter={setCadastreFiltering} cadastre={cadastre} modifyStyle={false} header={false}/>
                            </Tab>
                        );
                    })}
                    </Tabs>
                </div>}
        </div>);

}

const mapStateToProps = state => ({
    filterOptions: getFilterOptions(state),
    isFiltering: getIsFiltering(state),
    navigationState:getNavigationState(state),
    mapState: getMapState(state),
});

const mapDispatchToProps = (dispatch) => ({
    addMapFilter: (filterObj) => {
      dispatch(addMapFilter(filterObj));
    },
    removeMapFilter: () => {
        dispatch(removeMapFilter());
    },
    onSearch: (query) => {
		dispatch(startMapSearch(query));
    },
    resetMapFilter: () => {
        dispatch(resetMapFilter());
    },
    hideDetail: () => {
		dispatch(setMapDetailHide());
    },
    removeKeepFilter: () => {
		dispatch(removeMapFilter('keep'));
    },
    setMapType: (maptype) => {
		dispatch(setMapType(maptype));
    },
	setLoadedProjectLayers: (projectid) => {
		dispatch(setLoadedProjectLayers(projectid))
	}
  });

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(FilterComponent);
