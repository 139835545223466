import { OwletController } from '../../../controllers/OwletController';
import moment from 'moment';

export function getOwletData() {
    const OwletControllerInst = OwletController.getInstance();
    let from = moment().subtract(2,'days').format("YYYY-MM-DD");
    let to = moment().format("YYYY-MM-DD");
    let owletData = [];

    return Promise.all([
        OwletControllerInst.getBrokenLamps(from, to),
        OwletControllerInst.getLostNodes(from, to),
        OwletControllerInst.getHighPower(from, to)
    ]).then(function (data) {

        data.forEach(response => {
            if(response.data[0] !== undefined) {
                owletData = [...owletData, ...response.data[0].map(x => x.deviceId)]
            }
        })
        return owletData;
    }).catch(function (error) {
        console.log(error);
    });

};