import React, {useEffect, useState} from 'react';
import { Row, Col, Tab, Nav, Button } from "react-bootstrap";
import LineChart from '../charts/LineChart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import { OwletController } from '../../../controllers/OwletController';
import OwletMap from "./owlet_map";

import moment from 'moment';

function OwletDetails(props) {


    const OwletControllerInst = OwletController.getInstance();

    const [data, setData] = useState([]);
    const [labels, setLabels] = useState([]);
    const [type, setType] = useState(30);
    const [loading, setLoading] = useState(false);
    const [lamps, setLamps] = useState([]);
    const [total, setTotal] = useState(0);
    const [mapType, setMapType] = useState('broken');
    const [loadingData, setLoadingData] = useState(false);

    let to = moment().format("YYYY-MM-DD");
    let from = moment().subtract(type, 'days').format("YYYY-MM-DD");   

    //Get lamps on load
    useEffect(() => {
        setLoadingData(true);

        if(mapType === "broken") {
            //getBrokenLamps(from, to)
        }else if(mapType === "lostnode") {
            //getLostNodes(from, to)
        }else {
            //getHighPower(from, to)
        }

    }, [mapType, type]) // eslint-disable-line


    //Get statistics on load, and every type change
    useEffect(() => {
        setLoading(true);
        
        OwletControllerInst.getOwletStatistics(from, to).then(response => {
            if(response.statusCode === 200) {
                setLoading(false);
                setData(Object.values(response.data[0].chartData));
                setLabels(Object.keys(response.data[0].chartData))
                setTotal(response.data[0].energyConsumption)
            }
        });
    }, [type]); // eslint-disable-line

    function setupLamps(lamps) {
        let result = [];
        lamps.forEach(lamp => {
            let temp = {
                device: {}
            };
            temp.device["id"] = lamp.deviceId
            temp.device["geometry"] = {type:'Point', coordinates:[lamp.metaData.lng, lamp.metaData.lat]}
            temp.device["street"] = lamp.metaData.street
            temp.device["type"] = lamp.metaData.type

            result.push(temp)
        });
        setLamps(result);

    }

    function getBrokenLamps(from, to) {
        OwletControllerInst.getBrokenLamps(from, to).then(response => {
            if(response.data.length) {
                setupLamps(response.data[0])
            }
            else {
                setupLamps([])
            }
            setLoadingData(false);

        });
    }

    function getLostNodes(from, to) {
        OwletControllerInst.getLostNodes(from, to).then(response => {
            if(response.data.length) {
                setupLamps(response.data[0])
            }
            else {
                setupLamps([])
            }           
            setLoadingData(false);

        })
    }

    function getHighPower(from, to) {
        OwletControllerInst.getHighPower(from, to).then(response => {
            if(response.data.length) {
                setupLamps(response.data[0])
            }
            else {
                setupLamps([])
            }
            setLoadingData(false);

        })
    }

    return(
        <Row>
            <Col lg={8} className="owlet-details-left">
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Row className="owlet-details-chart-container">
                        <p style={{paddingLeft:'20px'}}>{'Teljes fogyasztás: 12343' + total.toFixed(1) + " kWh"}</p>
                        <Col sm={10} className="tab-content-wrapper">
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <LineChart step={3000} labels={labels.map(x => moment.unix(x/1000).format("YYYY-MM-DD"))} data={data} hide={3} type="owlet" title='Fogyasztás' sum={'Teljes fogyasztás: 12343' + total.toFixed(1) + " kWh"}/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    <LineChart step={1000} labels={labels.map(x => moment.unix(x/1000).format("YYYY-MM-DD"))} data={data} hide={0} type="owlet" title='Fogyasztás' sum={'Teljes fogyasztás: ' + total.toFixed(1) + " kWh"}/>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                        <Col sm={2} className="tab-buttons-wrapper">
                            <Nav variant="pills" className="flex-column">
                                <Nav.Item>
                                    <Nav.Link eventKey="first" onClick={() => setType(30)} disabled={loading}>30 nap</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="second" onClick={() => setType(7)} disabled={loading}>7 nap</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                    </Row>
                    <Row className="owlet-details-notifications-container">
                        <Col lg={12}>
                            <div className="owlet-details-notifications">
                                <p className="title">Riasztások</p>
                                <div>
                                    <p className="subtitle"><FontAwesomeIcon size="2x" color="#df3030" icon={['fas', 'bell']} />Nem igazolt</p>
                                    <div className="data-container">
                                        <p className="text">Jelenleg nincsenek nem igazolt riasztások</p>
                                    </div>
                                </div>
                                <div>
                                    <p className="subtitle"><FontAwesomeIcon size="2x" color="#df3030" icon={['fas', 'bell']} />Igazolt</p>
                                    <div className="data-container">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Dátum</th>
                                                    <th>ID</th>
                                                    <th>Probléma</th>
                                                    <th>Megtekintés</th>
                                                </tr>
                                            </thead>
                                        </table>
                                        <p className="text">Jelenleg nincsenek igazolt riasztások</p>
                                    </div>
                                </div>
                                <div>
                                    <p className="subtitle"><FontAwesomeIcon size="2x" color="#df3030" icon={['fas', 'bell']} />Riasztási előzmények</p>
                                    <div className="data-container">
                                        <p className="text">Jelenleg nincsenek riasztási előzmények</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Tab.Container>
            </Col>
            <Col lg={4} className="owlet-details-right">
                <Row>
                    <Col>
                        <div className="title"><FontAwesomeIcon size="2x" color="#212529" icon={['fas', 'wrench']} /> Anomáliák</div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} className="owlet-map-buttons">
                        <Button onClick={() => setMapType("broken")} className={mapType === "broken" ? 'active' : ''} disabled={loadingData}>Lámpahiba</Button>
                        <Button onClick={() => setMapType("lostnode")} className={mapType === "lostnode" ? 'active' : ''} disabled={loadingData}>Nincs kapcsolat</Button>
                        <Button onClick={() => setMapType("highpower")} className={mapType === "highpower" ? 'active' : ''} disabled={loadingData}>Magas fogyasztás</Button>
                        {loadingData && <div className="loader-container-owlet">
                            <div className="loader" style={{margin:"auto"}}></div>
                        </div>}
                    </Col>
                    <Col lg={12} className="owlet-map">
                        {props.active && <OwletMap lamps={lamps} />}
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <Button className="owlet-button" href="https://hu-bekescsaba.owlet-iot.com/" >Kezelés az <img alt="owlet" src="/logos/owlet_white.png" /> felületén</Button>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} className="owlet-logo">
                        <div className="logo-box">
                            <img alt="owlet" src="/logos/owlet_gray.png" />
                            <p>Powered by</p>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

const mapStateToProps = state => ({
    
});

const mapDispatchToProps = dispatch => ({
    
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(OwletDetails));
