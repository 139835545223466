import React, { useState } from 'react';
import { useDrag } from 'react-dnd';
import Details from '../../ui/Details';
const style = {

    position: 'absolute',
    cursor: 'move',
    zIndex: 100,
};
export function Box({id, right, top, navigationState, removeSelectedItem, zoomToFeature, findFeaturesById, clearDraw, highlighted, updateFeature}) {
    
    const [showdetails, setShowdetails] = useState(true);

    const [{ isDragging }, drag] = useDrag(() => ({
        type: "box", 
        item: { id, right, top },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    }), [id, right, top]);

    if (isDragging) {
        return <div ref={drag}/>;
    }

    return (<div ref={drag} style={{ ...style,  right, top}} role="Box" >
        {navigationState.detail && <Details removeSelectedItem={removeSelectedItem}
                    zoomToFeature={zoomToFeature}
                    findFeaturesById={findFeaturesById}
                    clearDraw={clearDraw}
                    highlighted={highlighted}
                    updateFeature={updateFeature}
                    refDrag={drag}
                    setShowdetails={setShowdetails}
                    showdetails={showdetails}
                    />}
    </div>);
}
