import React, { useEffect, useState } from "react";

import { Button, Row, Col, Modal } from 'react-bootstrap';
import ValidatioPopup from "./validationPopup"
//Redux imports
import connect from 'react-redux/es/connect/connect';
import {addUserAuthWarning} from '../../../state/reducers/user';
import moment from "moment";
import { TaskUpdateController } from '../../../controllers/TaskUpdateController';

/**
 * 
 * @param {*} props 
 */
function MqttDateUpdatePopup(props) {

    //State for new start and end date, and if the date is invalid
    const [startDate, setstartDate] = useState(null);
    const [endDate, setendDate] = useState(null);
    const [invalidDate, setInvalidDate] = useState(false);
    const [showFeedback, setShowFeedback] = useState(false);
    const [feedback, setFeedback] = useState(null);

    //state for validation popup
    const [validationPopupShow, setValidationPopupShow] = useState(false);

    const TaskUpdateControllerInst = TaskUpdateController.getInstance();

    //Func for resetting the date states and close to popup
    const handleClose = () => {
        props.setShowDatePopup(false);
        setValidationPopupShow(false);
        setstartDate(null);
        setendDate(null);
    };

    //Check the date inputs from user, if startdate is older than the end its invalid.
    useEffect(() => {
        let start = new Date(startDate)
        let end = new Date(endDate)

        if(end === null || start === null){
            setInvalidDate(true)
        }
        if(end < start) {
            setInvalidDate(true)
        }
        else {
            setInvalidDate(false)
        }
    }, [startDate, endDate])

    //Update state by user date input
    function updateStartEndDate() {
        if(endDate === null) {
            setendDate(moment(props.currentTask.datee).format("YYYY-MM-DD HH:mm:ss"))
        }
        if(startDate === null) {
            setstartDate(moment(props.currentTask.datee).format("YYYY-MM-DD HH:mm:ss"))
        }
        props.setShowDatePopup(false);
        setValidationPopupShow(true);
    }

    function handleDateUpdate() {

        let localUpdate = []
        let localUpdateObj = {}

        localUpdateObj["taskid"] = props.currentTask.id
  
        //Setup the publish object and format date as requested.
        const data = {
              dateo: moment(startDate).format("YYYY-MM-DD HH:mm:ss"),
              datee: moment(endDate).format("YYYY-MM-DD HH:mm:ss"),
        };


        localUpdateObj["data"] = {"dateo":data.dateo, "datee":data.datee}
        localUpdate.push(localUpdateObj);
  
        TaskUpdateControllerInst.updateTask(props.currentTask.project, props.currentTask.id, data).then(res => {
          if(res.statusCode === 200) {
            setstartDate(null);
            setendDate(null);
            setFeedback("Feladatok sikeresen frissitve")
            setShowFeedback(true)
            setValidationPopupShow(false);
            props.update(true);
          }else {
            console.log("error", res)
          }
        });
    }

    return(
        <React.Fragment>
            <Modal show={props.showDatePopup} className="confirm" onHide={() => {props.setShowDatePopup(false)}} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    Feladat módositása
                </Modal.Header>
                <Modal.Body>
                    <h6 style={{marginBottom:"10px"}}>Dátum: </h6>
                    <p> <span  className="task-update-validate">Sorszám:</span> <span  className="task-update-validate-value">{props.currentTask !== null ? props.currentTask.id : "Invalid"}</span></p>
                    <Row>
                        <Col >
                            <form>
                                <label>Kezdete:  </label>
                                <input  type="date"
                                        id="dateo"
                                        name="start_date"
                                        value={ props.currentTask !== null ? startDate !== null ? startDate : moment(props.currentTask.dateo).format("YYYY-MM-DD") : ""}
                                        onChange={(event) => setstartDate(event.target.value)}/>
                            </form>
                        </Col>
                        <Col>
                            <form>
                    
                                <label>Vége:  </label>
                                <input  type="date"
                                        id="datee"
                                        name="end_date"
                                        value={props.currentTask !== null ? endDate !== null ? endDate : moment(props.currentTask.datee).format("YYYY-MM-DD") : ""}
                                        onChange={(event) => setendDate(event.target.value)}/>
                            </form>
                        </Col>
                    </Row>
                    {invalidDate ?
                        <div style={{textAlign:"center", margintTop:20}}>
                            <p style={{marginTop:13, marginBottom:0, color:"red", fontSize:12}}>A dátum nem megfelelő !</p>
                        </div>
                        :
                        null

                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {handleClose()}}>Bezárás</Button>
                    <Button variant="primary" onClick={() => {updateStartEndDate()}} disabled={invalidDate}>Mentés</Button>
                </Modal.Footer>
            </Modal>
            <ValidatioPopup show={validationPopupShow} setShow={setValidationPopupShow} action={"Biztosan firssiti a dátumot"} makeAction={handleDateUpdate} type={"date"} currentTask={props.currentTask} startDate={startDate} endDate={endDate}/>
            <Modal className="confirm" show={showFeedback} onHide={() => setShowFeedback(false)} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                <p>{feedback}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="dark" onClick={() => {setShowFeedback(false)}}>Bezárás</Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    );
}

const mapDispatchToProps = (dispatch) => ({
    addUserAuthWarning: (warning) => {
        dispatch(addUserAuthWarning(warning))
    },
});
export default connect(
    null,
    mapDispatchToProps
)(MqttDateUpdatePopup);
