import React, {useEffect} from 'react';
//React Table imports
import {    
    useTable,
    useSortBy,
    useFilters,
    useGlobalFilter,
    useGroupBy,
    useExpanded,
    useRowSelect,
} from 'react-table'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Information from "../elements/kataszterInformation";

import { Table, Row, Col} from 'react-bootstrap';

const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef
    
        React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])
    
        return (
            <>
                <input type="checkbox" ref={resolvedRef} {...rest} />
            </>
        )
    }
)

export default function KataszterDataTable({ 
    columns, 
    data, 
    onChangeSort, 
    updateId, 
    updateSelected, 
    setObjectChanged, 
    tableExpanded, 
    setTableExpanded, 
    cadastre_details
    }) {

    //Const with built in table functions
    const {
        //base table properties
        getTableProps,
        //body properties
        getTableBodyProps,
        //Contains all the headers in a flattened structure
        headerGroups,
        //Refactored list with the values to put in table, also contains the full object passed into
        //Rows is only needed if there arend pagination active
        //rows,
        prepareRow,
        //Page is like a row but for pagination, only contains the rows for one page
        rows,
        visibleColumns,
        state,
        state: {sortBy}
      }
      /**
       * Root hook
       * The base needs are columns, and the data to render
       * Data should be a list of object, columns get the object key to render it.
       */
      = useTable({
            columns,
            data,
            // defaultColumn,
            manualSortBy: true,
            state: {
                expanded: tableExpanded
            },
            initialState: {
                expanded: tableExpanded
            }

        },
        useFilters,
        useGlobalFilter,
        useGroupBy,
        //Add sort by option, this is also implemented
        useSortBy,
        //Grouping function
        //Expanded rows
        useExpanded,
        useRowSelect,
        // useBlockLayout,
        // useResizeColumns,
        hooks => {
          hooks.visibleColumns.push(columns => [
            // Let's make a column for selection
            {
              id: 'selection',
              width:30,
              // The header can use the table's getToggleAllRowsSelectedProps method
              // to render a checkbox
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <div>
                   <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                </div>
              ),
              // The cell can use the individual row's getToggleRowSelectedProps method
              // to the render a checkbox
              Cell: ({ row }) => (
                <div>
                  <IndeterminateCheckbox type="checkbox" {...row.getToggleRowSelectedProps()} />
                </div>
              ),
            },
            ...columns,
          ])
        }
    )

    const prevCountRef = React.useRef();
    useEffect(() => {
      prevCountRef.current = state;
    });

    useEffect(() => {
        onChangeSort(sortBy[0]);
    }, [sortBy]); // eslint-disable-line

    useEffect(() => {
        setTableExpanded({...state.expanded, ...tableExpanded});
    }, [state]) // eslint-disable-line

    return (
        <div className='kataszter-table-view'>
            <Row className="kataszter-table-wrapper">
                <Col>
                <Table {...getTableProps()} striped bordered hover>
                    <thead>
                    {/* Map trough header groups : Contains an array with the headers and their props*/}
                        {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                            <>
                                {/* column here is a header with more props, and values*/}
                                {/* Add an arg to getporps to sort the data in columns*/}
                                <th {...column.getHeaderProps(column.getSortByToggleProps({ title: "Sorrend" }))}>
                                {column.canGroupBy ? (
                                    // If the column can be grouped, let's add a toggle
                                    <span {...column.getGroupByToggleProps()}>
                                    {column.isGrouped ?
                                        <span><FontAwesomeIcon icon={['fas', 'layer-minus']} /> </span>
                                    :
                                        <span><FontAwesomeIcon icon={['fas', 'layer-plus']} /> </span>
                                        }
                                    </span>
                                ) : null}
                                    {column.render('Header')}
                                    <span>
                                        {column.isSorted
                                        ? column.isSortedDesc
                                            ? <span>  <FontAwesomeIcon icon={['fas', 'caret-down']} /></span>
                                            : <span>  <FontAwesomeIcon icon={['fas', 'caret-up']} /></span>

                                        : ""}
                                    </span>

                                </th>
                            </>
                            ))}
                        </tr>
                        ))}
                    </thead>
                    {data.length ? 
                    <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row)
                        return (
                        <React.Fragment key={row.id}>
                        <tr {...row.getRowProps()}
                        style={{
                            background: row.isExpanded
                            ? '#f6fbff'
                            : 'white',
                        }}
                        >
                            {row.cells.map(cell => {
                            return (
                                <td {...cell.getCellProps()}
                                style={{
                                    background: cell.isGrouped
                                    ? ''
                                    : cell.isAggregated
                                    ? ''
                                    : cell.isPlaceholder
                                    ? '#ededed'
                                    : '#ededed',
                                }}
                                >
                                {cell.isGrouped ? (
                                    // If it's a grouped cell, add an expander and row count
                                    <>
                                    <span {...row.getToggleRowExpandedProps()}>
                                        {row.isExpanded
                                            ? <span>  <FontAwesomeIcon icon={['fas', 'chevron-square-down']} /></span>
                                            : <span>  <FontAwesomeIcon icon={['fas', 'chevron-square-right']} /></span>
                                        }
                                    </span>{' '}
                                    {cell.render('Cell')}
                                    </>
                                ) : cell.isAggregated ? (
                                    // If the cell is aggregated, use the Aggregated
                                    // renderer for cell
                                    cell.render('Aggregated')
                                ) : cell.isPlaceholder ? null : ( // For cells with repeated values, render null
                                    // Otherwise, just render the regular cell
                                    cell.render('Cell')

                                )}
                                </td>
                            )
                            })}
                        </tr>
                        {row.isExpanded && !row.isGrouped ?  (
                        <tr>
                            <td className="info-cell" colSpan={visibleColumns.length}>
                            <Information 
                                cadastre_details={cadastre_details} 
                                data={row.original} 
                                updateId={updateId} 
                                updateSelected={updateSelected} 
                                setObjectChanged={setObjectChanged}/>
                            </td>
                        </tr>
                        ) : null}
                        </React.Fragment>
                        )
                    })}
                    </tbody>
                    :
                    ""}
                </Table>
                </Col>
            </Row>
        </div>
    )
  }
