import React, {useCallback, useState, useEffect} from 'react'
import {useDropzone} from 'react-dropzone'
import { Row, Col } from 'react-bootstrap';
import { TaskUpdateController } from '../controllers/TaskUpdateController';
import { KataszterController } from '../controllers/KataszterController';

export default function DropZoneUpload(props) {

    const [files, setFiles] = useState([]);
    const [feedback, setFeedback] = useState({message:"", warning:false});
    const [loading, setLoading] = useState(false);
    const [fileUploadDesc, setFileUploadDesc] = useState(false);
    const [fileDesc, setFileDesc] = useState({});

    const TaskUpdateControllerInst = TaskUpdateController.getInstance();
    const KataszterControllerInst = KataszterController.getInstance();

    const onDrop = useCallback(acceptedFiles => {
        setFiles(acceptedFiles)
    }, []);
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

    useEffect(() =>  {
        if(feedback.message !== "") {
            setTimeout(() => {
                setFeedback({message:"", warning:false});
            }, [5000])
        }
    }, [feedback])

    function uploadFiles() {
        setLoading(true);
        if(props.type === 'task') {
            let id = props.data.task;
            TaskUpdateControllerInst.uploadFileToTask(id, files, fileDesc).then(res => {
                if(res.statusCode === 200) {
                    setFiles([]);
                    setFeedback({message:"Fájlok sikeresen feltöltve", warning:false})
                    setLoading(false);
                    setFileUploadDesc(false);
                    setFileDesc({});
                }
            }).catch(err => {
                setLoading(false);
                setFileUploadDesc(false);
                setFileDesc({});
                setFeedback({message:"Fájlok feltöltése sikertelen", warning:true})
            });
        }else {
            let id = props.data.id;
            KataszterControllerInst.uploadFile(id, files, fileDesc).then(res => {
                if(res.statusCode === 200) {
                    setFiles([]);
                    setFileUploadDesc(false);
                    setFileDesc({});
                    setFeedback({message:"Fájlok sikeresen feltöltve", warning:false});
                    setLoading(false);
                    props.addFile(res.data.files);
                }
            }).catch(err => {
                setLoading(false);
                setFileUploadDesc(false);
                setFileDesc({});
                setFeedback({message:"Fájlok feltöltése sikertelen", warning:true})
            });
        }
    }

    function displayFiles() {
        return files.map(file => {
            return(
                <snap>{file.name}</snap>
            )
        })
    }

    function descChange(name, desc) {
        let temp = {...fileDesc};
        temp[name] = desc;
        setFileDesc(temp);
    }

    return (
        <Row>
            <Col lg={files.length && !fileUploadDesc > 0 ? 12 : 12} md={12} sm={12} style={{outline:'red', width:files.length ? '70%' :'100%'}} {...getRootProps()}>        
            {
                <div className="drag-n-drop-container">
                    <input {...getInputProps()} />
                    <p>{!isDragActive && !files.length ? "A feltölteni kívánt fájlt kérem húzza ide, vagy kattintson rá a mezőre!" : files.length ?  
                    
                    displayFiles()

                    : "Engedje el a file-t itt!"}</p>
                </div>
            }
            </Col>
            {/* load button if files present */}
            {files.length && !fileUploadDesc ? <Col md={12} >
                {files.length > 0 &&
                    <div>
                        <button className="uploadbutton" onClick={() => setFileUploadDesc(true)}>{!loading ? 
                        "Feltöltés"
                        :
                        <div className="loader-container-upload">
                            <div className="loader"></div>
                        </div>
                        }</button>
                    </div>
                }
            </Col>:null}
            {fileUploadDesc ? 
                <div className="file-upload-description-input">
                    <p style={{marginBottom:'5px'}}>Fájlok elnevezése</p>
                    <p style={{fontSize:'11px', color:'grey'}}>Ez fog megjelenni mint feltöltött fájl neve.</p> 
                    {files.map(file => {
                        return(<div><span>{file.name}: </span> <span><input placeholder="leírás" onChange={(evt) => descChange(file.name, evt.target.value)}></input></span></div>);
                    })}
                    <button disabled={Object.keys(fileDesc).length !== files.length} onClick={uploadFiles}>Feltöltés</button>
                </div>:null}
            {/* load feedback if files uploaded */}
            {feedback.message !== "" ?
            <Col sm={12} style={{textAlign:'center'}}>
                <p style={{color:feedback.warning ? "#fc5203" : "#6be879"}}>{feedback.message}</p>
            </Col>:null}
        </Row>

    )
}