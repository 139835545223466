import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function ProjectSidebarIcon(props) {

    function displayTitle() {
        if(props.action === 'filter') {
            return "Szűrés";
        }else if(props.action === 'layer') {
            return "Rétegek";
        }else if(props.action === "project") {
            return "Projektek"
        }else {
            return "Háttér Rétegek"
        }
    }

    return(        
        <div className="card-header" key={props.index} title={displayTitle()}>
            <div onClick={() => {
                if(props.activeLayout === props.action) {
                    props.projectSidebarToggle();
                    props.setActiveLayout(null);
                }else if(props.activeLayout === null) {
                    props.setActiveLayout(props.action);
                    props.projectSidebarToggle();
                }else {
                    props.setActiveLayout(props.action);
                }
            }}>
                <i className="icon" >
                    {props.action === 'filter' && <FontAwesomeIcon color={props.activeLayout === props.action ? "#4daef2" : "#333333"} icon={['far', 'sliders-h']} />}
                    {props.action === 'layer' && <FontAwesomeIcon color={props.activeLayout === props.action ? "#4daef2" : "#333333"} icon={['far', 'layer-group']} />}
                    {props.action === 'project' && <FontAwesomeIcon color={props.activeLayout === props.action ? "#4daef2" : "#333333"} icon={['far', 'tasks']} />}
                    {props.action === 'tile' && <FontAwesomeIcon color={props.activeLayout === props.action ? "#4daef2" : "#333333"} icon={['far', 'map']} />}

                </i>
            </div>
        </div>
    );    
}