import React from 'react';
import MapComponent from '../../components/Map/Map';
import connect from 'react-redux/es/connect/connect';
import { getMapState,getNavigationState,setMapDetailDisplay,setMapDetail } from '../../state/reducers/ui';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const Map  = ({onDeviceInfo,displayDetail, ...props}) => {
    return (
        <div>
            <DndProvider backend={HTML5Backend}>
                <MapComponent {...props} displayDetail={displayDetail} dispatch={onDeviceInfo} />
            </DndProvider>
            
        </div>
    );
};

const mapStateToProps = state => ({
    mapState: getMapState(state),
    navigationState: getNavigationState(state)
});


const mapDispatchToProps = dispatch => ({
    displayDetail: (markers) => {
        dispatch(setMapDetail(markers));
        dispatch(setMapDetailDisplay())
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Map);


