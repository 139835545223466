import React from "react";

import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function CustomButton(props) {

    return(
        <Button onClick={() => props.setProjectType(props.type)}  variant="secondary" className={props.projectState.type === props.type ? 'active' : ''}>
            <i className="icon"><FontAwesomeIcon icon={props.icon} /></i>
            {props.name}
        </Button>
    );
}