import React, { useState } from 'react';
import {Container, Row, Col} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import { history } from '../../state/store';

export default function KataszterBox(props) {

    const [openedcadastre, setOpenedcadastre] = useState([]);

    function checkOpenCadastre(cadastre) {
        if (openedcadastre.includes(cadastre)) {
            setOpenedcadastre(openedcadastre.filter(x => x !== cadastre ))
        } else {
            setOpenedcadastre([...openedcadastre, cadastre])
        }
    }

    function addFavourite() {
        let ref = props.group ? props.cadastre.ref : props.cadastre.cadastre_ref;

        if(props.favourite.includes(ref)) {
            props.setFavourite(props.favourite.filter(x => x !== ref))
        }else{
            props.setFavourite([...props.favourite, ref])
        }

        props.setUserFavCadastre(ref);
    }

    function loadOnMap(cadastre) {

        if(!props.layerOptions.allLoadedLayers.includes(cadastre.cadastre_id)) {
            if(cadastre.data_source !== 'static') {
                props.updateLoadedDynamicCadastreLayers(cadastre)
                history.push('/map');
            }else {
                props.setLoadedCadastreLayers({name:cadastre.cadastre_ref, cadastre_id:cadastre.cadastre_id});
                history.push('/map');
            }
            props.addLayer(cadastre.cadastre_id);
        }else {
            history.push("/map")
        }
    }

    return(

        <Container className="dashboard-kataszter-container">
            <div className="dashboard-kataszter-container-inside">
                <Row className="dashboard-kataszter-container-header-row">
                    <Col lg="12" className="card-header align-items-center" >
                        <div className="color-circle" style={{backgroundColor: props.cadastre.icon_background, color: props.cadastre.icon_foreground}} onClick={() => props.cadastre.form_json !== null ? props.handleSelect(props.cadastre) : null}>
                            <FontAwesomeIcon size="1x" icon={['fas', props.cadastre.icon]} />
                        </div>
                        <span onClick={() => props.cadastre.form_json !== null ? props.handleSelect(props.cadastre) : null} role={props.cadastre.form_json !== null ? "button" : ""}>{props.group ? props.cadastre.label : props.cadastre.cadastre_label}</span>
                        {!props.group && <div style={{marginLeft:"10px"}}>
                            <FontAwesomeIcon size="1x" color="#ffe852" icon={[props.favourite.includes(props.cadastre.cadastre_ref) ? 'fas' : 'far', 'star']} onClick={() => addFavourite()}/>
                        </div>}
                        {(props.id !== null && props.cadastre.form_json !== null && props.group !== true) && <button className="kataszter-button" onClick={() =>  props.handleSelect(props.cadastre)}>tovább</button>}
                    </Col>
                </Row>

                <Row className="dashboard-kataszter-details">
                    <Col>
                        <p>2020-11-06</p>
                        <span>utolsó módosítás</span>
                     </Col>
                     <Col>
                        {props.cadastres.length ?
                            <>
                                <p>{props.cadastres.length} kataszter</p>
                                <span>kataszterek száma</span>
                            </> 
                            :
                            <>
                                <p>UTU Admin</p>
                                <span>utolsó modosító</span>
                            </>
                        }
                        
                     </Col>
                </Row>

                {props.cadastres.length ?

                    <Row className="dashboard-kataszter-group">
                    {openedcadastre.includes(props.refid) &&
                        <Col>
                            <p>Kataszterek</p>
                            <ul>
                                {
                                    props.cadastres.map((cadastre, index) => 
                                        <li>
                                            <p key={index}>{cadastre.cadastre_label}</p>
                                            <span className="button"><FontAwesomeIcon size="1x" icon={['far', 'list']} onClick={() => cadastre.form_json !== null ? props.handleSelect(cadastre) : null} /></span>
                                            <span className="button"><FontAwesomeIcon size="1x" icon={['far', 'map-marked-alt']} onClick={() => loadOnMap(cadastre)}/></span>
                                        </li>
                                    )
                                }
                            </ul>
                        </Col>
                    }
                    <Col className="dashboard-kataszter-toggle" onClick={() => checkOpenCadastre(props.refid)}>
                        <FontAwesomeIcon size="1x" icon={['fas', openedcadastre.includes(props.refid) ? 'chevron-up' : 'chevron-down']}/>
                    </Col>
                    </Row>
                :null}

            </div>
        </Container>
    );
}
