import React from 'react';
import { useTable } from 'react-table'

export default function WifiTable({loading, data, columns}) {

    const tableInstance = useTable({ columns, data })
 
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
    } = tableInstance



    return(
        // apply the table props
        <div>
            {loading ? 
                <div className="loader-container" style={{padding:'20px', position: "absolute"}}>
                    <div className="loader"></div>
                </div>
            : 
            <table {...getTableProps()} className="wifi-table">
                <thead>
                {// Loop over the header rows
                headerGroups.map(headerGroup => (
                    // Apply the header row props
                    <tr {...headerGroup.getHeaderGroupProps()}>
                    {// Loop over the headers in each row
                    headerGroup.headers.map(column => (
                        // Apply the header cell props
                        <th {...column.getHeaderProps()}>
                        {// Render the header
                        column.render('Header')}
                        </th>
                    ))}
                    </tr>
                ))}
                </thead>
                {/* Apply the table body props */}
                {data.length ? 
                <tbody {...getTableBodyProps()}>
                {// Loop over the table rows
                rows.map(row => {
                    // Prepare the row for display
                    prepareRow(row)
                    return (
                    // Apply the row props
                    <tr {...row.getRowProps()}>
                        {// Loop over the rows cells
                        row.cells.map(cell => {
                        // Apply the cell props
                        return (
                            <td {...cell.getCellProps()}>
                            {// Render the cell contents
                            cell.render('Cell')}
                            </td>
                        )
                        })}
                    </tr>
                    )
                })}
                </tbody> : <p style={{textAlign:'center', margin:'10px'}}>Jelenleg nincs megjelenítendő adat.</p>}
            </table> }
        </div>
    );
}