import React from 'react';
import {  Col, Row, FormCheck } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function TileLayer(props) {

    return(
        <Row>
            <Col lg={2} xs={2}>
                <FontAwesomeIcon icon={['fas', "map"]} />
            </Col>
            <Col lg={7} xs={7}>
                <p>{props.tile.title}</p>
            </Col>
            <Col lg={3} xs={3}>
                <FormCheck
                    id={props.tile.ref}
                    type="switch"
                    label=""
                    checked={props.loadedTiles.map(x => x.ref).includes(props.tile.ref)}
                    onChange={() => {
                        props.loadedTileChange(props.tile);
                    }}
                />
            </Col>
        </Row>
    );
}