import React , { useState, useEffect } from 'react';
import BrowseLayout from '../../screens/layouts/BrowseLayout';

import connect from 'react-redux/es/connect/connect';
import {getLoadingData, toggleView, getLoadingMessage} from '../../state/reducers/ui';
import DashboardBox from "../../components/dashboard/DashboardBox";
import { Row, Col, Container } from "react-bootstrap";
import NewContactModal from '../_helper/newContactModal';
import { ThirdPartyController } from "../../controllers/ThirdPartyController";

/**
 * All the style object are exported from helper files.
 * Currently these are only exporting an object, but for future if we need logic in these, they can be easliy converted to functions.
 * **/

/**
 * Dashboard Componenet
 * 
 * Basic Functionality :
 * - Load all the recent and future widgets on a screen
 * - Loading mechanism until MQTT provide the necessary data, and the JSON-s are fetched
 * - 2 different view
 * 		- SMART	: All smart view widgets.
 * 		- Basic : This will be updated with widgets, and 3rd party API calls or links when requested
 * **/
function DashboardThirdParty(props){
	props.toggleViewState("thirdparty");

	const [show, setShow] = useState(false);
	const [allData, setAllData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [pageLoaded, setPageLoaded] = useState(false);

    const ThirdPartyControllerInst = ThirdPartyController.getInstance();


	useEffect(() => {
		setPageLoaded(true);
	}, [])

	useEffect(() => {
		if(pageLoaded && !loading) {
			setLoading(true);
			ThirdPartyControllerInst.getAllThirdPartyResources().then(response => {
				if(response.statusCode === 200) {
					setAllData(response.data);
					setLoading(false);
				}
			}).catch(err => {
				console.log("error", err);
				setLoading(false);
			});
		}
	}, [pageLoaded]); // eslint-disable-line


	function createNewThirdParty(data) {
		ThirdPartyControllerInst.createThirdPartyResource(data).then(response => {
            if(response.statusCode === 200) {
				setAllData([...allData, {...response.data, logo:{logo:response.data.logo}}]);
				setShow(false);

            }
        }).catch(err => {
			setShow(false);
		});
	}


	return (
		<BrowseLayout>
			<div className="dashboard-container dashboard-connection-container">
				<Row className="dasboard-container-row">

					<Col className="col-kaptalan col-lg-3 col-md-4 col-sm-12">
						<a href={'https://bekescsaba.mindigis.hu/login'} target="_blank" rel="noopener noreferrer">
							<DashboardBox title="" type="" body="<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit fusce lobortis interdum libero</p>">
								<div className="img"><img alt="Káptalan" src="logos/kaptalan.png" /></div>
							</DashboardBox>
						</a>
					</Col>
					<Col className="col-owlet col-lg-3 col-md-4 col-sm-12">
						<a href={'https://hu-bekescsaba.schreder-exedra.com/login/'} target="_blank" rel="noopener noreferrer">
							<DashboardBox title="" type="" body="<p>Világítótestek távoli vezérlése és részletes műszaki adatok megtekintése.</p>">
								<div className="img"><img alt="Schreder-Exedra" className="schreder-logo" src="logos/schreder_logo.jpg" /></div>
							</DashboardBox>
						</a>
					</Col>
					<Col className="col-owlet col-lg-3 col-md-4 col-sm-12">
						<a href={'https://hibabejelento.bekescsaba.manageyourcity.com/#/'} target="_blank" rel="noopener noreferrer">
							<DashboardBox title="" type="" body="<p>Békéscsaba közterületi hibabejelntő webapplikációja</p>">
								<div className="img"><img alt="LOG" className="utu-logo" src="logos/LOG_logo1080.png" /></div>
							</DashboardBox>
						</a>
					</Col>
					{allData.map(data => {
						return(
							<Col className="col-owlet col-lg-3 col-md-4 col-sm-12">
								<a rel="noopener noreferrer" target="_blank" href={data.url}>
									<DashboardBox title={data.logo !== null ? "" : data.label} type="" body={"<p>"+ data.description +"</p>"}>
									{data.logo !== null && <div className="img"><img style={{width:"200px", height:"60px"}} alt={data.label} src={"data:image/png;base64, " + data.logo.logo} /></div>}
									</DashboardBox>
								</a>
							</Col>
						);
					})}
					<Col className="col-new-connection col-lg-4 col-md-6 col-sm-12">
						<Container className="dashboard-card-body" >
							<div className="dashboard-card-inside">
								<Row className="dashboard-card-header">
									<Col lg="12" className="dashboard-header-col">
										<p className="dashboard-card-title" role="button" onClick={() => setShow(true)} >Új kapcsolat</p>
										<p className="dashboard-card-desc">
											<p>Ide kattintva új külső kapcsolat betöltését hozhatja létre</p>
											<i role="button" onClick={() => setShow(true)}>
												<span className ="plus"></span>
											</i>
										</p>
									</Col>
								</Row>
							</div>
						</Container>
					</Col>
				</Row>
			</div>
		{show && <NewContactModal show={show} setShow={setShow} createNewThirdParty={createNewThirdParty}/>}
		</BrowseLayout>
	);
};

DashboardThirdParty.propTypes = {};

const mapStateToProps = state => ({
  	loading : getLoadingData(state),
	loadingMessage: getLoadingMessage(state)
});

const mapDispatchToProps = (dispatch) => ({
	toggleViewState: (id) => {
	  dispatch(toggleView(id));
	}
  });

export default connect(
    mapStateToProps,
	mapDispatchToProps
)(DashboardThirdParty);
