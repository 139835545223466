import React from 'react';

export default function DefaultPage(props) {


    return(
        <div>
            <p style={{padding:'50px', fontSize:'15px'}}>Jelenleg nincs megjelenítendő információ.  <a href='/smart'>Vissza</a></p>
        </div>
    );
}