import React, {useState, useEffect} from 'react';
import { Row, Col, Form, Tabs, Tab } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StackedbarChart from '../charts/StackedbarChart';
import MultiLineChart from '../charts/MultiLineChart';
import { MeasurementController } from "../../../controllers/MeasurementController";
import moment from 'moment';
import { history } from '../../../state/store';
import { getMapLayerOptions, setAllLoadedLayers, updateLoadedDynamicCadastreLayers } from '../../../state/reducers/ui';
import { getUserCadastreData } from '../../../state/reducers/user';

import connect from 'react-redux/es/connect/connect';

function EnvironmentDetails(props) {


    const [activeId, setActiveId] = useState('10'); // eslint-disable-line
    const [group, setGroup] = useState(false);

    //Humidity
    const [currentHumidity, setCurrentHumidity] = useState("N/A");
    const [, setPreviousHumData] = useState([]);
    const [humidityataLast5Days, setHumidityDataLast5Days] = useState([]);
    const [humidityParse, setHumidityParse] = useState([]);
    const [humidityLastYearData, setHumidityLastYearData] = useState([]);
    const [humidityLastYearParse, setHumidityLastYearParse] = useState([]);
    const [humidityWeeklyAvg, setHumidityWeeklyAvg] = useState({min: 0, max: 0, avg: 0});

    //Dust
    const [dustDataLast5Days, setDustDataLast5Days] = useState([]);
    const [dustParse, setDustParse] = useState([]);
    const [dustLastYearData, setDustLastYearData] = useState([]);
    const [dustLastYearParse, setDustLastYearParse] = useState([]);

    const [dustWeeklyAvg, setDustWeeklyAvg] = useState({min:0, max:0, avg:0});
    const [dustThreshold, setDustTreshold] = useState(0);
    const [dustLimitPrevMonth, ] = useState([]);

    //Temperature
    const [temperatureDataActual, setTemperatureDataActual] = useState(0);
    const [temperatureDataLastDay, setTemperatureDataLastDay] = useState({min:0, max:0, avg:0});
    const [temperatureDataLast5Days, setTemperatureDataLast5Days] = useState([]);
    const [temperatureParse, setTemperatureParse] = useState([]);
    const [temperatureYearParse, setTemperatureYearParse] = useState([]);
    const [temperatureYearData, setTemperatureYearData] = useState([]);

    const [dataDevice,setDataDevice] = useState([]);
    const [selectedDevice, setSelectedDevice] = useState(false);

    const [lastMeasurement, setLastMeasurement] = useState({humidity:"Nincs adat", dust:"Nincs adat", temperature:"Nincs adat"});

    const MeasurementControllerInst = MeasurementController.getInstance();

    const [dateType, setDateType] = useState(7);

    //CO
    const [coData, setCoData] = useState(false);
    const [coDataLast5Days, setCoDataLast5Days] = useState([]);
    const [coParse, setCoParse] = useState([]);
    const [coLastYearData, setCoLastYearData] = useState([]);
    const [coLastYearParse, setCoLastYearParse] = useState([]);

    //Noise
    const [noiseData, setNoiseData] = useState(false);
    const [noiseDataLast5Days, setNoiseDataLast5Days] = useState([]);
    const [noiseParse, setNoiseParse] = useState([]);
    const [noiseLastYearData, setNoiseLastYearData] = useState([]);
    const [noiseLastYearParse, setNoiseLastYearParse] = useState([]);

    function openCadastreOnMap() {

        let cadastre = props.cadastreData[70];

        if(!props.layerOptions.allLoadedLayers.includes(cadastre.cadastre_id)) {
            if(cadastre.data_source !== 'static') {
                props.updateLoadedDynamicCadastreLayers(cadastre)
                history.push('/map');
            }else {
                props.setLoadedCadastreLayers({name:cadastre.cadastre_ref, cadastre_id:cadastre.cadastre_id});
                history.push('/map');
            }
            props.addLayer(cadastre.cadastre_id);
        }else {
            history.push("/map")
        }
    }

    useEffect(() => {
        getStatistics()
        if(!dataDevice.length) {
            MeasurementControllerInst.getDevices().then(response =>{
                setDataDevice(response.data)
            })
        }
    }, [selectedDevice, dateType]) // eslint-disable-line react-hooks/exhaustive-deps

    function getStatistics() {
        let endDate = moment().format("YYYY-MM-DD");
        let startDate = moment().subtract(dateType, "days").format("YYYY-MM-DD")

        MeasurementControllerInst.getHumidityStatistics(startDate, endDate, selectedDevice).then(response => {
            setHumidityParse([]);

            if(response.statusCode === 200) {
                setCurrentHumidity(response.data.humidity.actual);
                setPreviousHumData(response.data.humidity.previous_day);
                setLastMeasurement(prevState => ({
                    ...prevState,
                    humidity: response.data.humidity.last_measurement
                }));

                let temp = [];
                let parse = [];

                Object.keys(response.data.humidity.days).forEach( date => {
                    Object.keys(response.data.humidity.days[date]).forEach( time  => {
                        temp.push(response.data.humidity.days[date][time]);

                        if(dateType === 1) {
                            parse.push(time);
                        }else {
                            parse.push(date);
                        }
                    })
                });

                if ('previous_day' in response.data) {
                    setHumidityWeeklyAvg(response.data.previous_day);
                }

                let year_temp = [];
                let year_parse = [];
                if ('last_year' in response.data.humidity) {
                    response.data.humidity.last_year.forEach( item => {
                        year_parse.push(item.date);
                        year_temp.push(item);
                    });
                }

                setHumidityDataLast5Days(temp);
                setHumidityParse(parse);
                setHumidityLastYearData(year_temp);
                setHumidityLastYearParse(year_parse);
            }
        });

        MeasurementControllerInst.getDustStatistics(startDate, endDate, selectedDevice).then(response => {
            setDustDataLast5Days([]);
            setDustParse([]);
            if(response.statusCode === 200) {
                setLastMeasurement(prevState => ({
                    ...prevState,
                    dust: response.data.last_measurement
                }));

                setDustTreshold(response.data.threshold);
                let temp = [];
                let parse = []

                //setDustWeeklyAvg({min: response.data.previous_day.min, max: response.data.previous_day.max, avg: response.data.previous_day.avg});
                if ('previous_day' in response.data) {
                    setDustWeeklyAvg(response.data.previous_day);
                }

                Object.keys(response.data.data).forEach( date => {
                    Object.keys(response.data.data[date]).forEach( time  => {
                        temp.push(response.data.data[date][time]);
                        if(dateType === 1) {
                            parse.push(time);
                        }else {
                            parse.push(date);
                        }
                    })
                });

                let year_temp = [];
                let year_parse = [];
                if ('last_year' in response.data) {
                    response.data.last_year.forEach( item => {
                        year_parse.push(item.date);
                        year_temp.push(item);
                    });
                }

                setDustDataLast5Days(temp);
                setDustParse(parse);
                setDustLastYearData(year_temp);
                setDustLastYearParse(year_parse);
            }
        });

        MeasurementControllerInst.getTemperature(selectedDevice, startDate, endDate).then(response => {
            setTemperatureDataLast5Days([]);
            setTemperatureParse([]);
            if(response.statusCode === 200) {
                setLastMeasurement(prevState => ({
                    ...prevState,
                    temperature: response.data.last_measurement
                }));

                setTemperatureDataActual(response.data.actual)
                setTemperatureDataLastDay(response.data.previous_day);

                let temp = [];
                let parse = [];

                Object.keys(response.data.data).forEach( date => {
                    Object.keys(response.data.data[date]).forEach( time  => {
                        temp.push(response.data.data[date][time]);

                        if(dateType === 1) {
                            parse.push(time);
                        }else {
                            parse.push(date);
                        }
                    })
                });

                let year_temp = [];
                let year_parse = [];
                if ('last_year' in response.data) {
                    response.data.last_year.forEach( item => {
                        year_parse.push(item.date);
                        year_temp.push(item);
                    });
                }

                setTemperatureDataLast5Days(temp);
                setTemperatureParse(parse);
                setTemperatureYearData(year_temp);
                setTemperatureYearParse(year_parse);
            }
        });

        MeasurementControllerInst.getCOSummary(startDate, endDate, selectedDevice).then(response => {
            setCoData(response.data);
            setCoParse([]);
            if(response.statusCode === 200) {
                let temp = [];
                let parse = []

                Object.keys(response.data.days).forEach( date => {
                    Object.keys(response.data.days[date]).forEach( time  => {
                        temp.push(response.data.days[date][time]);

                        if(dateType === 1) {
                            parse.push(time);
                        }else {
                            parse.push(date);
                        }
                    })
                });

                let year_temp = [];
                let year_parse = [];
                if ('last_year' in response.data) {
                    response.data.last_year.forEach( item => {
                        year_parse.push(item.date);
                        year_temp.push(item);
                    });
                }

                setCoDataLast5Days(temp);
                setCoParse(parse);
                setCoLastYearData(year_temp);
                setCoLastYearParse(year_parse);
            }
        });

        MeasurementControllerInst.getNoiseSummary(startDate, endDate, selectedDevice).then(response => {
            setNoiseData(response.data);
            setNoiseParse([]);
            if(response.statusCode === 200) {
                let temp = [];
                let parse = []

                Object.keys(response.data.days).forEach( date => {
                    Object.keys(response.data.days[date]).forEach( time  => {
                        temp.push(response.data.days[date][time]);

                        if(dateType === 1) {
                            parse.push(time);
                        }else {
                            parse.push(date);
                        }
                    })
                });

                let year_temp = [];
                let year_parse = [];
                if ('last_year' in response.data) {
                    response.data.last_year.forEach( item => {
                        year_parse.push(item.date);
                        year_temp.push(item);
                    });
                }

                setNoiseDataLast5Days(temp);
                setNoiseParse(parse);
                setNoiseLastYearData(year_temp);
                setNoiseLastYearParse(year_parse);
            }
        });
    };

    useEffect(() => {
        if(group === 'false') {
            setSelectedDevice(false);
        }else if(dataDevice && group) {
            setSelectedDevice(dataDevice[group][0])
        }
    }, [group]) // eslint-disable-line react-hooks/exhaustive-deps

    function rendeDevices() {
        if(group && group !== 'false') {
            return dataDevice[group].map(e => {
                return(
                    <option key={e} value={e}>{e}</option>
                )
            })    
        }else {
            return(
                <option key={'Átlag'} value="false">Átlag</option>
            )
        }
    }

    const weeklyParse = ["1.hét", "2.hét", "3.hét", "4.hét"];
    const yearlyParse = ["2018", "2019", "2020"];

    //TEMP
    const monthlyParse = ["Jan", "Feb", "Már", "Ápr", "Máj", "Jun", "Júl", "Aug", "Szep", "Okt", "Nov", "Dec"];

    return(
        <div>
            <Form.Group controlId="ControlSelect" className="widget-device-selecter">
                <Row style={{height:'auto'}}><Form.Label style={{fontWeight: 'bold',padding: '2px', margin: '2px'}}>Eszköz kiválasztása </Form.Label></Row>
                <Row style={{padding: '5px 0', margin: '0', border: '10px'}}>
                    <Form.Control size="sm" as="select" style={{marginRight:'2px'}} onChange={(event) => { setGroup(event.target.value) }}>
                            <option value="false">Átlag</option>
                            { Object.keys(dataDevice).map(e => {
                                return(
                                    <option key={e} value={e}>{e}</option>
                                    )})                            
                                } 
                    </Form.Control>
                    <Form.Control size="sm" as="select" onChange={(event) => { setSelectedDevice(event.target.value)} }>
                            {rendeDevices()}
                    </Form.Control>
                </Row>
            </Form.Group>

            <Tabs defaultActiveKey={props.value}>
                <Tab eventKey="10" title="Por">
                    <Tab.Content className="particle-details particle-details-dust">
                        <Row>
                            <Col lg={3} className="particle-details-data">
                                <Col lg={12} className="data-current">
                                    <i className="icon"><FontAwesomeIcon size="4x" icon={['fal', 'particles']} /></i>
                                    <p>{dustThreshold}</p>
                                    <h4>Határérték</h4>
                                </Col>
                                <Col lg={12} className="data-current" style ={{paddingBottom : '10px' , paddingTop: '10px'}}>
                                    <h4>Utolsó mérés ideje (GMT)</h4>
                                    <span>{lastMeasurement.dust}</span> 
                                </Col>
                                <Col lg={12} className="data-minmax">
                                    <Row>
                                        <Col lg={6} className="data-min">
                                            <i className="icon"><FontAwesomeIcon size="3x" icon={['fal', 'particles']} /></i>
                                            <p>{dustWeeklyAvg.min !== null ? parseInt(dustWeeklyAvg.min) : 0}</p>
                                        </Col>
                                        <Col lg ={6}>
                                            <i className="icon"><FontAwesomeIcon size="3x" icon={['fal', 'particles']} /></i>
                                            <p>{dustWeeklyAvg.max !== null ? parseInt(dustWeeklyAvg.max) : 0}</p>
                                        </Col>
                                    </Row>
                                    <h4>Min/Max határátlépés<br />(előző hét)</h4>
                                </Col>
                                <Col lg={12} className="data-map" role="button" onClick={()  => openCadastreOnMap()}>
                                    <i className="icon"><FontAwesomeIcon size="3x" icon={['fal', 'map-marked-alt']} /></i>
                                    <h4>Szenzorok megjelenítése a térképen</h4>
                                </Col>
                            </Col>
                            <Col lg={9} className="particle-details-chart">
                                <Row>
                                    <Col lg={12}>
                                        <MultiLineChart treshold={dustThreshold} dateType={dateType} setDateType={setDateType} data={dustDataLast5Days.length ? {min:dustDataLast5Days.map(x => x.min), max:dustDataLast5Days.map(x => x.max), avg:dustDataLast5Days.map(x => x.avg)} : []} labels={dustParse} labelName={["Max", "Min", "Átlag"]} title={"Mért adatok (Elmúlt "+ dateType + " nap)"} fill={"origin"} types={[{title:"Napi", value:1}, {title:"Heti", value:7}, {title:"Havi", value:30}]}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12}>
                                        <MultiLineChart dateType={dateType} setDateType={setDateType} data={dustLastYearData.length ? {min: dustLastYearData.map(x => x.min), max: dustLastYearData.map(x => x.max), avg: dustLastYearData.map(x => x.avg)} : []} labels={monthlyParse} labelName={["Havi max átlag", "Havi min átlag", "Havi átlag"]} title={"Előző évi adatok"} fill={false} types={[]}/>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Tab.Content>
                </Tab>
                <Tab eventKey="11" title="Hőmérséklet">
                    <Tab.Content className="particle-details particle-details-temp">
                        <Row>
                            <Col lg={3} className="particle-details-data">
                                <Col lg={12} className="data-current">
                                    <i className="icon"><FontAwesomeIcon size="4x" icon={['far', 'thermometer-three-quarters']} /></i>
                                    <p>{Math.floor(temperatureDataActual)} °C</p>
                                    <h4>Aktuális hőmérséklet</h4>
                                </Col>
                                <Col lg={12} className="data-current" style ={{paddingBottom : '10px' , paddingTop: '10px'}}>
                                    <h4>Utolsó mérés ideje (GMT)</h4>
                                    <span>{lastMeasurement.temperature}</span>
                                </Col>
                                <Col lg={12} className="data-minmax">
                                    <Row>
                                        <Col lg={6}>
                                            <i className="icon"><FontAwesomeIcon size="3x" icon={['far', 'temperature-low']} /></i>
                                            <p>{Math.floor(temperatureDataLastDay.min)} °C</p>
                                        </Col>
                                        <Col lg ={6}>
                                            <i className="icon"><FontAwesomeIcon size="3x" icon={['far', 'temperature-high']} /></i>
                                            <p>{Math.floor(temperatureDataLastDay.max)} °C</p>
                                        </Col>
                                    </Row>
                                    <h4>Min/Max hőmérséklet<br />(előző nap)</h4>
                                </Col>
                                <Col lg={12} className="data-map" role="button" onClick={()  => openCadastreOnMap()}>
                                    <i className="icon"><FontAwesomeIcon size="3x" icon={['fal', 'map-marked-alt']} /></i>
                                    <h4>Szenzorok megjelenítése a térképen</h4>
                                </Col>
                            </Col>
                            <Col lg={9} className="particle-details-chart">
                                <Row>
                                    <Col lg={12}>
                                        <MultiLineChart dateType={dateType} setDateType={setDateType} data={temperatureDataLast5Days.length ? {min:temperatureDataLast5Days.map(x => x.min), max:temperatureDataLast5Days.map(x => x.max), avg:temperatureDataLast5Days.map(x => x.avg)} : []} labels={temperatureParse} labelName={["Max hőmérséklet", "Min hőmérséklet", "Átlag hőmérséklet"]} title={"Hőmérséklet (Elmúlt "+ dateType + " nap)"} fill={"origin"} types={[{title:"Napi", value:1}, {title:"Heti", value:7}, {title:"Havi", value:30}]}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12}>
                                        <MultiLineChart dateType={dateType} setDateType={setDateType} data={temperatureYearData.length ? {min: temperatureYearData.map(x => x.min), max: temperatureYearData.map(x => x.max), avg: temperatureYearData.map(x => x.avg)} : []} labels={monthlyParse} labelName={["Havi max átlag", "Havi min átlag", "Havi átlag"]} title={"Előző évi adatok"} fill={false} types={[]}/>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Tab.Content>
                </Tab>
                <Tab eventKey="12" title="Pára">
                    <Tab.Content className="particle-details particle-details-humadity">
                        <Row>
                            <Col lg={3} className="particle-details-data">
                                <Col lg={12} className="data-current">
                                    <i className="icon"><FontAwesomeIcon size="4x" icon={['far', 'humidity']} /></i>
                                    <p>{currentHumidity} %</p>
                                    <h4>Aktuális páratartalom</h4>
                                </Col>
                                <Col lg={12} className="data-current" style ={{paddingBottom : '10px' , paddingTop: '10px'}}>
                                    <h4>Utolsó mérés ideje (GMT)</h4>
                                    <span>{lastMeasurement.humidity}</span>
                                </Col>
                                <Col lg={12} className="data-minmax">
                                    <Row>
                                        <Col lg={6}>
                                            <i className="icon"><FontAwesomeIcon size="3x" icon={['far', 'humidity']} /></i>
                                            <p>{humidityWeeklyAvg.min !== null ? parseInt(humidityWeeklyAvg.min) : 0} %</p>
                                        </Col>
                                        <Col lg ={6}>
                                            <i className="icon"><FontAwesomeIcon size="3x" icon={['far', 'humidity']} /></i>
                                            <p>{humidityWeeklyAvg.max !== null ? parseInt(humidityWeeklyAvg.max) : 0} %</p>
                                        </Col>
                                    </Row>
                                    <h4>Min/Max páratartalom<br />(előző hét)</h4>
                                </Col>
                                <Col lg={12} className="data-map" role="button" onClick={()  => openCadastreOnMap()}>
                                    <i className="icon"><FontAwesomeIcon size="3x" icon={['fal', 'map-marked-alt']} /></i>
                                    <h4>Szenzorok megjelenítése a térképen</h4>
                                </Col>
                            </Col>
                            <Col lg={9} className="particle-details-chart">
                                <Row>
                                    <Col lg={12}>
                                        <MultiLineChart dateType={dateType} setDateType={setDateType} data={humidityataLast5Days.length ? {min:humidityataLast5Days.map(x => x.min), max:humidityataLast5Days.map(x => x.max), avg:humidityataLast5Days.map(x => x.avg)} : []} labels={humidityParse} labelName={["Max Páratartalom", "Min Páratartalom", "Átlag Páratartalom"]} title={"Páratartalom (Elmúlt " + dateType + " nap)"} fill={"origin"} types={[{title:"Napi", value:1}, {title:"Heti", value:7}, {title:"Havi", value:30}]}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12}>
                                        <MultiLineChart dateType={dateType} setDateType={setDateType} data={humidityLastYearData.length ? {min: humidityLastYearData.map(x => x.min), max: humidityLastYearData.map(x => x.max), avg: humidityLastYearData.map(x => x.avg)} : []} labels={monthlyParse} labelName={["Havi max átlag", "Havi min átlag", "Havi átlag"]} title={"Előző évi adatok"} fill={false} types={[]}/>
                                    </Col>
                                </Row>                            
                            </Col>
                        </Row>
                    </Tab.Content>
                </Tab>
                <Tab eventKey="13" title="Zaj">
                    <Tab.Content className="particle-details particle-details-dust">
                       {noiseData && <Row>
                            <Col lg={3} className="particle-details-data">
                                <Col lg={12} className="data-current">
                                    <i className="icon"><FontAwesomeIcon size="4x" icon={['far', 'podcast']} /></i>
                                    <p>{parseInt(noiseData.actual.measured_value).toFixed(2)} {noiseData.unit}</p>
                                    <h4>Aktuális Zaj értéke</h4>
                                </Col>
                                <Col lg={12} className="data-current" style ={{paddingBottom : '10px' , paddingTop: '10px'}}>
                                    <h4>Utolsó mérés ideje (GMT)</h4>
                                    <span>{noiseData.last_measurement}</span>
                                </Col>
                                <Col lg={12} className="data-map" role="button" onClick={()  => openCadastreOnMap()}>
                                    <i className="icon"><FontAwesomeIcon size="3x" icon={['fal', 'map-marked-alt']} /></i>
                                    <h4>Szenzorok megjelenítése a térképen</h4>
                                </Col>
                            </Col>
                            <Col lg={9} className="particle-details-chart">
                                <Row>
                                    <Col lg={12}>
                                        <MultiLineChart dateType={dateType} setDateType={setDateType} data={noiseDataLast5Days.length ? {min:noiseDataLast5Days.map(x => x.min), max:noiseDataLast5Days.map(x => x.max), avg:noiseDataLast5Days.map(x => x.avg)} : []} labels={noiseParse} labelName={["Maximum érték", "Minimum érték", "Átlag"]} title={"Zaj (Elmúlt " + dateType + " nap)"} fill={"origin"} types={[{title:"Napi", value:1}, {title:"Heti", value:7}, {title:"Havi", value:30}]}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12}>
                                        <MultiLineChart dateType={dateType} setDateType={setDateType} data={noiseLastYearData.length ? {min: noiseLastYearData.map(x => x.min), max: noiseLastYearData.map(x => x.max), avg: noiseLastYearData.map(x => x.avg)} : []} labels={monthlyParse} labelName={["Havi max átlag", "Havi min átlag", "Havi átlag"]} title={"Előző évi adatok"} fill={false} types={[]}/>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>}
                    </Tab.Content>
                </Tab>
                <Tab eventKey="14" title="CO">
                    <Tab.Content className="particle-details particle-details-dust">
                        {coData && <Row>
                            <Col lg={3} className="particle-details-data">
                                <Col lg={12} className="data-current">
                                    <i className="icon"><FontAwesomeIcon size="4x" icon={['far', 'radiation']} /></i>
                                    <p>{coData.actual.measured_value} {coData.unit}</p>
                                    <h4>Aktuális CO értéke</h4>
                                </Col>
                                <Col lg={12} className="data-current" style ={{paddingBottom : '10px' , paddingTop: '10px'}}>
                                    <h4>Utolsó mérés ideje (GMT)</h4>
                                    <span>{coData.last_measurement}</span>
                                </Col>
                                <Col lg={12} className="data-map" role="button" onClick={()  => openCadastreOnMap()}>
                                    <i className="icon"><FontAwesomeIcon size="3x" icon={['fal', 'map-marked-alt']} /></i>
                                    <h4>Szenzorok megjelenítése a térképen</h4>
                                </Col>
                            </Col>
                            <Col lg={9} className="particle-details-chart">
                                <Row>
                                    <Col lg={12}>
                                        <MultiLineChart dateType={dateType} setDateType={setDateType} data={coDataLast5Days.length ? {min:coDataLast5Days.map(x => x.min), max:coDataLast5Days.map(x => x.max), avg:coDataLast5Days.map(x => x.avg)} : []} labels={coParse} labelName={["Maximum érték", "Minimum érték", "Átlag"]} title={"CO (Elmúlt " + dateType + " nap)"} fill={"origin"} types={[{title:"Napi", value:1}, {title:"Heti", value:7}, {title:"Havi", value:30}]}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12}>
                                        <MultiLineChart dateType={dateType} setDateType={setDateType} data={coLastYearData.length ? {min: coLastYearData.map(x => x.min), max: coLastYearData.map(x => x.max), avg: coLastYearData.map(x => x.avg)} : []} labels={monthlyParse} labelName={["Havi max átlag", "Havi min átlag", "Havi átlag"]} title={"Előző évi adatok"} fill={false} types={[]}/>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>}
                    </Tab.Content>
                </Tab>
            </Tabs>
        </div>
    );
}

const mapStateToProps = state => ({
    layerOptions: getMapLayerOptions(state),
    cadastreData : getUserCadastreData(state),

});

const mapDispatchToProps = (dispatch) => ({
    addLayer: (selected) => {
        dispatch(setAllLoadedLayers(selected));
    },
    updateLoadedDynamicCadastreLayers: (cadastre) => {
		dispatch(updateLoadedDynamicCadastreLayers(cadastre))
	},
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(EnvironmentDetails);
