import Config from "../config/config";

export class OwletController {

    private static instance: OwletController;

    /**
     * Can't create the object without the instance method to prevent direct init
     */
    private constructor() {}

    /**
     * Controlling the singleton
     */
    public static getInstance(): OwletController {
        if (!OwletController.instance) {
            OwletController.instance = new OwletController();
        }
        return OwletController.instance;
    }

    public authentication() {
        const url = Config.endpoints.owlet.base + Config.endpoints.owlet.endpoints.auth;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept-Language': 'de_DE',
                    'Authorization': Config.endpoints.owlet.auth
                },
            }).then(res => {
                if(res.status === 200) {
                    return res.json()
                }else {
                    reject({statusCode:404, error:"Could not load measurement data"})
                }
            }).then(res => {
                resolve({statusCode:200, data:res})
            })
        })

    }

    public getOwletStatistics(from:any, to:any) {

        const url = Config.endpoints.base + Config.endpoints.owlet.statistics.get.replace('{fromDate}', from).replace('{toDate}', to);

        return new Promise((resolve, reject) => {
            fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then(res => {
                if(res.status === 200) {
                    return res.json()
                }else {
                    reject({statusCode:404, error:"Could not load measurement data"})
                }
            }).then(res => {
                resolve({statusCode:200, data:res})
            })
        })
    }

    public getBrokenLamps(from:any, to:any) {
        const url = Config.endpoints.base + Config.endpoints.owlet.lamps.brokenlamp.replace("{fromDate}", from).replace("{toDate}", to);

        return new Promise((resolve, reject) => {
            fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then(res => {
                if(res.status === 200) {
                    return res.json()
                }else {
                    reject({statusCode:404, error:"Could not load measurement data"})
                }
            }).then(res => {
                resolve({statusCode:200, data:res})
            })
        })
    }

    public getLostNodes(from:any, to:any) {
        const url = Config.endpoints.base + Config.endpoints.owlet.lamps.lostnode.replace("{fromDate}", from).replace("{toDate}", to);

        return new Promise((resolve, reject) => {
            fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then(res => {
                if(res.status === 200) {
                    return res.json()
                }else {
                    reject({statusCode:404, error:"Could not load measurement data"})
                }
            }).then(res => {
                resolve({statusCode:200, data:res})
            })
        })
    }

    public getHighPower(from:any, to:any) {
        const url = Config.endpoints.base + Config.endpoints.owlet.lamps.highpower.replace("{fromDate}", from).replace("{toDate}", to);

        return new Promise((resolve, reject) => {
            fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then(res => {
                if(res.status === 200) {
                    return res.json()
                }else {
                    reject({statusCode:404, error:"Could not load measurement data"})
                }
            }).then(res => {
                resolve({statusCode:200, data:res})
            })
        })
    }


    public getLamp(deviceId:any) {

        //hardcoded lamp id for now
        const url = Config.endpoints.base + Config.endpoints.owlet.lamps.get + "?deviceId=0013a20041b3a538";
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then(res => {
                if(res.status === 200) {
                    return res.json()
                }else {
                    reject({statusCode:404, error:"Could not load measurement data"})
                }
            }).then(res => {
                resolve({statusCode:200, data:res})
            })
        })
    }
    
};
