import React, { useState, useEffect } from 'react';
import TimeLine from "react-gantt-timeline";
import connect from 'react-redux/es/connect/connect';
import { Button, Row } from 'react-bootstrap';

import {addUserAuthWarning, getUserRole, getUserProjects} from '../../state/reducers/user';
import ReactTooltip from "react-tooltip";
import renderHTML from 'react-render-html';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import { getActiveProject, getNavigationState } from '../../state/reducers/ui';

/**
 * Documentation
 * @param {tasks} props All the tasks which are in the JSON, check public/json/tasks.json for more information.
 */
function Gantt(props){

    //data storage, for full list, sliced list to separate pages, and the page number what to load.
    const [data, setData] = useState([]);
    const [, setSortBy] = useState("id")

    const [mode, setMode] = useState("year");

    //The base Effect hook, load the component every time when the props or the reload changes
    useEffect(() => {
        setData([]);
        let today = new Date();

        props.tasks.forEach((task, index) => {
            //Check if date is due change color
            let color = today > new Date(task.datee) ? "red" : "orange";

            setData((prevData => [...prevData, {
                id:index,
                start:task.dateo,
                end:task.datee,
                color:color,
                name:<div style={{marginTop:"6px"}}>
                        <input type="checkbox" id={task.task} name={task.task} style={{float:'left', marginTop:"3px", marginLeft:"3px"}} />
                            <Information data={task} startDate={task.dateo} endDate={task.datee}/>
                    </div>,
            }]));
        });
    }, [props.limit, props.page, props.tasks]);

    function updatelimit(evt) {
        let newlimit = parseInt(evt.target.value)
        props.setLimit(newlimit)
    }

    // function handleSelectProject(element) {
    //     let tasks = (data.filter((item) => {
    //         if(item.street === element) {
    //             return true
    //         }
    //     }).map(task => values(task.subtasks)))[0].map(x => x)

    //     var finalObj = {};
    //     for (var i = 0; i < tasks.length; ++i){
    //         finalObj[tasks[i].id] = tasks[i];
    //     }

    //     if(!selectedProjects.includes(element)) {
    //         setSelectedProjects([...selectedProjects, element])
    //         setSelectedTasks({...selectedTasks, ...finalObj})
    //     }else {
    //         setSelectedProjects(selectedProjects.filter(item => item !== element))


    //         setSelectedTasks(prevState => {
    //             let state = {...prevState};
    //             tasks.forEach(element => {
    //                 if(state.hasOwnProperty(element.id)) {
    //                 delete state[element.id]
    //                 }
    //             })
    //             return state;
    //         })
    //     }
    // }

    // function handleSelectTask(element) {
    //     if(!selectedTasks.hasOwnProperty(element.id)) {
    //         setSelectedTasks(prevState => ({
    //         ...prevState,
    //         [element.id]: element
    //         }))
    //     }
    //     else {
    //         deleteSelectedTask(element.id)
    //     }
    // }

    // function deleteSelectedTask(task) {
    //     setSelectedTasks(prevState => {
    //         let state = {...prevState};
    //         if(state.hasOwnProperty(task)) {
    //             delete state[task]
    //         }
    //             return state;
    //     })
    // };

    //Separate the string with line brakes near ,
    function modifyInformation(information) {
        return information.split(',').join(', <br />')
    }

    //Componenet for tooltip information
    const Information = (props) => {
        let information = props.data.action_type ? props.data.action_type : 'Nincs adat';
        let spot_ref = props.data.device.lpt_ref

        return <>
            {props.startDate !== null && props.endDate !== null ?
                <div style={{float:"left", padding:"4px 3px 3px 7px"}}>
                    <a data-tip="" data-for={props.data.id} data-iscapture="true" href={() => false} >
                        <span style={{color:"black"}}><span style={{fontWeight:500}}>{props.data.device.location}</span>{" - " + spot_ref}</span>
                    </a>
                    <ReactTooltip
                        id={props.data.id}
                        type="info"
                        place="right"
                        multiline={true}>
                        {renderHTML("<span>" + modifyInformation(information) +  "</span>")}
                        <br /><br />
                        <span>Feladat kezdete: {moment(props.startDate).format("YYYY-MM-DD")}</span> <br />
                        <span>Feladat vége: {moment(props.endDate).format("YYYY-MM-DD")}</span> <br /><br />
                        <span>Helyszín: {props.data.device.location}</span> <br />
                    </ReactTooltip>
                </div>
            :
            <></>
        }
        </>
    };

    //todo error if i put this outside of function, die after second load
    const styleConfig = {
        header:{
            top:{
                style:{backgroundColor: "#515151"}
            },
            middle:{
                style:{backgroundColor: "#99D7D8"},
            },
            bottom:{
                style:{background:"grey"},
                selectedStyle:{backgroundColor:"orange"}
            },
        },
        taskList:{
            title:{
                label:props.projects[props.activeProject].title,
                style:{backgroundColor:'#515151'}
            }
        }
    }

    //next page
    function switchpageForward() {
        if(props.page < props.maxPage) {
            props.setPage(props.page + 1)
        }
    }
    //prev page
    function switchPageBackward() {
        if(props.maxPage > 0 ) {
            props.setPage(props.page - 1)
        }
    }

    return(
        <div>
            <Row id="grantRow">
                <div>
                    <Button variant="light" id="grantButton" onClick={() => setMode("year")}>Év</Button>
                    <Button variant="light" id="grantButton" onClick={() => setMode("month")}>Hónap</Button>
                    <Button variant="light" id="grantButton" onClick={() => setMode("week")}>Hét</Button>
                    <span style={{marginRight:"50px"}}>
                        <span style={{paddingLeft:"20px", paddingRight:"10px"}}>Sorrend</span>
                        <select id="sort" name="sort" onChange={(evt) => {setSortBy(evt.target.value)}} form="">
                        <option value="id" selected="selected">Név</option>
                        <option value="start">Kezdés dátuma</option>
                        <option value="end">Befejezés dátuma</option>
                        </select>
                    </span>

                    <span style={{marginRight:"20px"}}>
                        <span style={{paddingLeft:"20px", paddingRight:"10px"}}>Találat oldalanként</span>
                        <select id="pagenumber" defaultValue={10} name="pagenumber" onChange={(evt) => {updatelimit(evt)}} form="">
                            <option value="10">10</option>
                            <option value="25" >25</option>
                            <option value="50">50</option>
                            <option value="75">75</option>
                            <option value="100">100</option>
                        </select>
                    </span>

                    <Button variant="light" id="grantButton" onClick={() => {props.setPage(0)}}><FontAwesomeIcon icon={['fas', 'step-backward']} /></Button>
                    <Button variant="light" id="grantButton" onClick={switchPageBackward}><FontAwesomeIcon icon={['fas', 'chevron-left']} /></Button>
                    <span style={{paddingLeft:"20px", paddingRight:"10px"}}>{props.page+1} of {props.maxPage+1}</span>
                    <Button variant="light" id="grantButton" onClick={switchpageForward}><FontAwesomeIcon icon={['fas', 'chevron-right']} /></Button>
                    <Button variant="light" id="grantButton" onClick={() => {props.setPage(props.maxPage)}}><FontAwesomeIcon icon={['fas', 'step-forward']} /></Button>

                </div>
            </Row>
            <div className="app-container">
            <TimeLine data={data} mode={mode} config={styleConfig} nonEditableName={true}/>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    userRole: getUserRole(state),
    activeProject: getActiveProject(state),
	projects : getUserProjects(state),
	navigationState:getNavigationState(state),

});

const mapDispatchToProps = (dispatch) => ({
    addUserAuthWarning: (warning) => {
        dispatch(addUserAuthWarning(warning))
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Gantt);
