import Style from "ol/style/Style";
import Stroke from "ol/style/Stroke";
import {Text} from "ol/style";
import Fill from "ol/style/Fill";
import moment from "moment";

const s4 = () => Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);

export const guid = () => (s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4());

export const fetchJson = async (url) => {
    const response = await fetch(url, {});
    return await response.json();
};

export function uniq(cadastres) {
    let seen = {};
    return cadastres.filter(function(cadastre) {
        return seen.hasOwnProperty(cadastre.cadastre_id) ? false : (seen[cadastre.cadastre_id] = true);
    });
}

export const getNumberStyler = (layerData, savedStyle) =>(feature) => {

    let value = feature.values_[layerData.style.accessor];

   return new Style({
        stroke: layerData.stroke ? new Stroke({
            color: savedStyle ? savedStyle.strokeColor : layerData.stroke.color,
            width: savedStyle? savedStyle.stroke : layerData.stroke.width
        }) : null,
        fill: layerData.fill ? new Fill({
            color: savedStyle ? "rgba(" + savedStyle.fillColor.r  +","+ savedStyle.fillColor.g + "," + savedStyle.fillColor.b +  "," + savedStyle.fillColor.a + ")" : layerData.fill
        }) : null,
        text: new Text({
            text: value,
            placement: 'point',
            textAlign: 'center',
            font: '12px sans-serif',
            fill: new Fill({
                color: '#444444'
            })
        })
    });
}


export const getDateRangeFromWeekNumber = (weeknum) => {
    let monday = moment().day("Monday").add(weeknum, "w");
    let sunday = moment().day("Friday").add(weeknum, "w");

    if(weeknum.includes("-")) {

        let weeks = weeknum.split("-");
        sunday = moment().day("Friday").subtract(weeks[1], "w");

    }
    return {start:monday,end:sunday};
};

export const getFirstN = (obj, n) => {
    return Object.keys(obj) //get the keys out
        .sort() //this will ensure consistent ordering of what you will get back. If you want something in non-aphabetical order, you will need to supply a custom sorting function
        .slice(0, n) //get the first N
        .reduce(function(memo, current) { //generate a new object out of them
            memo[current] = obj[current];
            return memo;
        }, {})
};

export function shallowEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
  
    if (keys1.length !== keys2.length) {
      return false;
    }
  
    for (let key of keys1) {
      if (object1[key] !== object2[key]) {
        return false;
      }
    }
  
    return true;
}
