import React from 'react';
import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';
import Header from '../../components/Header';
import Footer from '../../components/Footer';


const MainLayout = ({ children }) => (
    <div id='mainLayout'>
        <ToastContainer
            position="top-right"
            type="default"
            autoClose={8000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            pauseOnHover
            progressClassName="colour-progress"
        />
        <Header />
        {children}
        <Footer />
    </div>
);

MainLayout.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.any,
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element)
    ])
};

MainLayout.defaultProps = {
    children: []
};

export default MainLayout;
