
import React from "react";
import {getFilters} from '../../../state/reducers/ui';
import connect from 'react-redux/es/connect/connect';
import { resetMapFilter, getIsFiltering } from '../../../state/reducers/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getUserCadastreData } from '../../../state/reducers/user';
import Config from '../../../config/config';

function ActiveFilterContainer(props) {

    const filters = props.filters.searchFilters
    const searchWords = props.filters.term

    function clearFilterOptions() {
        props.resetMapFilter();
    }
    return(
        <React.Fragment>
        {props.isFiltering ?
            <div className="filter-container">
                <p><i role="button" onClick={clearFilterOptions}><FontAwesomeIcon size="xs" icon={['fal', 'times']} /></i>
                <span>Projekt szűrési feltételek:</span>
                {Object.entries(filters).map(([key,value]) => {
                    let mapKey = Config.mapping.lamp.attribute.hasOwnProperty(key) ? Config.mapping.lamp.attribute[key] : key;
                    let mapValue = Config.mapping.lamp.value.hasOwnProperty(key) ? Config.mapping.lamp.value[key][value] : value
                    return <span key={key} className="filter-container-option"> {mapKey} : {mapValue}, </span>
                })};

                {searchWords !== "" ?
                    <span key={searchWords}>Kereső: "{searchWords}" </span>
                    :
                    null
                }
                </p>
            </div>
        :
            null
        }
        </React.Fragment>
    );
}

const mapStateToProps = state => ({
    filters:getFilters(state),
    isFiltering: getIsFiltering(state),
    cadastreData: getUserCadastreData(state),

});

const mapDispatchToProps = (dispatch) => ({
    resetMapFilter: () => {
        dispatch(resetMapFilter());
    }
});

export default connect(
    mapStateToProps,
	mapDispatchToProps
)(ActiveFilterContainer);
