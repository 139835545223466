import React from "react";
import connect from 'react-redux/es/connect/connect';
import { getMapLayerOptions, clearMapLayerOptionsFilter } from '../../../state/reducers/ui';
import { getUserCadastreData } from '../../../state/reducers/user';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ActiveCadastreFilterContainer(props) {

    function clearFilterOptions() {
        props.clearLayerFilters();
    }

    function setupFilters() {
        let filters = [];
        Object.keys(props.layerOptions.searchFilters).map(key => { // eslint-disable-line
            let cadastre_json = props.cadastreData[key] ? props.cadastreData[key].form_json : [];
            let cadastre_name = props.cadastreData[key] ? props.cadastreData[key].cadastre_label : '';

            filters.push(<span style={{fontWeight:600}}>"{cadastre_name}": </span>);

            Object.entries(props.layerOptions.searchFilters[key]).map(([name, value]) => { // eslint-disable-line
                let valueMask = cadastre_json.find(x => x.name === name);
                if(valueMask.values) {
                    filters.push( <span key={key} className="filter-container-option">{valueMask.label}: {valueMask.values[value]}, </span>)
                }
                else {
                    filters.push( <span key={key} className="filter-container-option">{valueMask.label}: {value}, </span>)

                }
            })
        })

        return filters;
    }

    return(
        <React.Fragment>
            {Object.keys(props.layerOptions.searchFilters).length ?
                <div className="filter-container" style={{paddingTop:"2px"}}>
                    <p><i role="button" onClick={clearFilterOptions}><FontAwesomeIcon size="xs" icon={['fal', 'times']} /></i>
                    <span>Kataszter szűrési feltételek:</span>
                    { setupFilters() }
                    </p>
                </div>
            :
                null
            }
        </React.Fragment>
    );
}

const mapStateToProps = state => ({
    layerOptions: getMapLayerOptions(state),
    cadastreData: getUserCadastreData(state),
});

const mapDispatchToProps = (dispatch) => ({
    clearLayerFilters: () => {
        dispatch(clearMapLayerOptionsFilter());
    },
});

export default connect(
    mapStateToProps,
	mapDispatchToProps
)(ActiveCadastreFilterContainer);
