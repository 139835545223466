import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const HorizontalProgress = ({label,size, value, unit, icon,color}) => {

    return (
        <div className={'horizontal-progress widget justify-content-center align-items-center '+ size }>
        	<div>
        
       			<div className="display justify-content-center align-items-center">
	       			<div className="label-container">
		       			<i className="icon" style={{color:color}}>
							<FontAwesomeIcon icon={['fal', icon]} />
		       			</i>
		       			<span>{label}</span>
	       			</div>
	       			<div className="value-container">
		       			<div>{value}{unit}</div>
		    		</div>
       			</div>
       			<div className="progress" style={{backgroundColor:color,width:value+'%'}}></div>
       		</div>
       	</div>
    );
};

HorizontalProgress.propTypes = {};

export default HorizontalProgress;