import React, { useState } from 'react';
import { Modal, Row ,Col} from 'react-bootstrap';
import ExportCSV from '../../../helpers/exportXLSX';
import connect from 'react-redux/es/connect/connect';
import { getProjectState, getActiveProject } from '../../../state/reducers/ui';
import '../../../styles/ui-elements/_exportelement.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getUserCadastreData } from '../../../state/reducers/user';
import Config from '../../.././config/config';

function ExportDataModal(props){

    const [checked, setChecked] = useState([]);
    const [searchWord, setSearchWord] = useState("");
    const [taskFileds, ] = useState(["id", "task", "action_type", "location", "dateo", "app", "done", "approved"]);

    function handleToggle (value){
        if(checked.filter(x => x.id === value.id).length) {
            setChecked(checked.filter(x => x.id !== value.id))
        }else {
            setChecked(prev => [...prev, value]);
        }
    }

    function handleClose () {
        props.setShow(false)
        setChecked([])
    }

    function removeSelect(id) {
        setChecked(checked.filter(x => x.id !== id));
    }

    function getFormJson() {
        let temp = [];
        
        if(props.type === 'project') {
            Object.values(props.cadastreData).forEach(cadastre => {
                if(cadastre.projects.filter(x => x.id === props.activeProject).length) {
                    temp.push({form_json:cadastre.form_json, cadastre_label:cadastre.cadastre_label, icon:cadastre.icon, cadastre_id:cadastre.cadastre_id});
                }
            });
        }else {
            props.selectedFeatureCadastreRefs.forEach(id => {
                if(id !== undefined) {
                    temp.push({form_json:props.cadastreData[id].form_json, cadastre_label:props.cadastreData[id].cadastre_label, icon:props.cadastreData[id].icon, cadastre_id:props.cadastreData[id].cadastre_id});
                }
            });
        }
        return temp;
    }

    function selectAll() {
        let cadastreData = getFormJson();
        let names = [];

        cadastreData.forEach(cadastre_data => {
            cadastre_data.form_json.forEach((detail, index) => {
                if(!names.includes(detail.name) && detail.exportable) {
                    names.push(detail.name);
                    setChecked(prev => [...prev, { id:index, label:detail.label, name:detail.name }]);
                }
            });
        });

        if(props.type === 'project') {
            taskFileds.forEach((field, index) => {
                setChecked(prev => [...prev, {id:index, label:Config.mapping.lamp.attribute.hasOwnProperty(field) ? Config.mapping.lamp.attribute[field] : field, name:field }]);
            });
        }
    }

    function deselectAll() {
        setChecked([]);
    }
 
    function setupTable() {
        let cadastreData = getFormJson();
        let names = [];
        let duplicateRefs = [];

        return(
            <div>
                {props.type === 'project' ?
                <div>
                    <h5 className="export-header"> <FontAwesomeIcon size="sm" icon={['far', 'list']} /> Projekt adatok</h5>
                    <div>
                        {taskFileds.map((field, index) => {
                            if(field.includes(searchWord)) {
                                return(
                                    <Row key={index}>
                                        <Col xs={8} lg={10}>                            
                                            <label className="export-modal-text" >{Config.mapping.lamp.attribute.hasOwnProperty(field) ? Config.mapping.lamp.attribute[field] : field}</label>
                                        </Col>
                                        <Col xs={4} lg={2} style={{textAlign:"right"}}>
                                            <input className="export-modal-checkbox" type="checkbox" checked={checked.filter(x => x.id === index).length} value={field} onChange={() => handleToggle({id:index, label:Config.mapping.lamp.attribute.hasOwnProperty(field) ? Config.mapping.lamp.attribute[field] : field, name:field })} />
                                        </Col>
                                    </Row>
                                );
                            }else return null;
                        })}
                        {!taskFileds.filter(x => x.includes(searchWord)).length ? <p className="result-not-found-text">Nem található a keresett mező</p> : null}
                    </div>
                </div>:null}
                <h5 className="export-header"> <FontAwesomeIcon size="sm" icon={['far', 'clipboard-list']} /> Kataszter adatok</h5>
                {cadastreData.map((cadastre_data, index) => {
                    return(
                        <div key={index}>
                            <h6 style={{paddingLeft:'5px'}}><FontAwesomeIcon size="sm" icon={['far', cadastre_data.icon]} /> {cadastre_data.cadastre_label}</h6>
                            {cadastre_data.form_json.map((detail, index) => { // eslint-disable-line
                                if(detail.label.includes(searchWord) && !names.includes(detail.name) && detail.exportable) {
                                    names.push(detail.name);
                                    return(
                                        <Row style={{paddingLeft:'15px'}} key={index}>
                                            <Col xs={8} lg={10}>                            
                                                <label className="export-modal-text" >{detail.label}</label>
                                            </Col>
                                            <Col xs={4} lg={2} style={{textAlign:"right"}}>
                                                <input className="export-modal-checkbox" type="checkbox" checked={checked.filter(x => x.id === index).length} value={detail.name} onChange={() => handleToggle({id:index, label:detail.label, name:detail.name })} />
                                            </Col>
                                    </Row>
                                    );
                                }else if(names.includes(detail.name) && !duplicateRefs.includes(cadastre_data.cadastre_label)) {
                                    duplicateRefs.push(cadastre_data.cadastre_label);

                                }
                            })}
                            {!cadastre_data.form_json.filter(x => x.label.includes(searchWord)).length ? <p className="result-not-found-text">Nem található a keresett mező</p> : null}
                            {duplicateRefs.includes(cadastre_data.cadastre_label) ? <p className="result-not-found-text">Néhány mező nem jelent meg, mert máshol is szerepelt. A mező kiválasztva ebből a kataszterből is exportálva lesz.</p> : null}
                        </div>
                    )
                })}
            </div>
        )
    }

   return(
        <React.Fragment>
            <Modal show={props.show} onHide={() => handleClose() } size="lg" dialogClassName="export-modal">
                <Modal.Header closeButton>
                    <Modal.Title><h4>Adatok kiválasztása</h4></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {checked.length ? <Col className="export-active-container" xs={12}>
                        {checked.map(value => {
                            return(
                                <div className="export-active-select"><FontAwesomeIcon alt="Kijelölés megszüntetése" role='button' className="remove-select" size="1x" icon={['fas', 'times']} onClick={() => removeSelect(value.id)} /> {value.label}</div>
                            );
                        })}
                    </Col>: null}
                    <Col xs={12} className="export-selects-filter">
                        <input type="text" placeholder="Keresés az opciók között" onChange={(event) => setSearchWord(event.target.value)}/>
                        <div className="interaction-buttons">
                            <button className="select-button" onClick={() => selectAll()}>Mind</button>
                            <button className="select-button" onClick={() => deselectAll()}>Egyik se</button>
                        </div>
                    </Col>
                    <Col xs={12} className="export-selects">
                        {setupTable()}
                    </Col>
                </Modal.Body>
                <Modal.Footer>
                    <ExportCSV selectedvalues={checked} disabled={!checked.length} type={props.type} selectedFeatures={props.selectedFeatures} cadastreData={props.cadastreData}/>
                </Modal.Footer>                      
            </Modal>
        </React.Fragment>
        
    );
}

const mapStateToProps = state => ({
    projectState: getProjectState(state),
    activeProject: getActiveProject(state),
    cadastreData : getUserCadastreData(state),

});

export default connect(
    mapStateToProps,
)(ExportDataModal);
