import React from "react";
import { Form } from 'react-bootstrap';
import Slider from '../../../components/ui/Slider';
import Config from '../../../config/config';

/**
 * Documentation
 * @param {name, type, filterOption, addFilter()} props Parameters in an Obj, the form name, filter type, the filteroption object, and a filtering func from parent
 * 
 */
export function FormGroupWrapperDefault(props) {

    let name = props.name
    let type = props.type
    let filterOption = props.filterOption

    function setupFilter(event) {
        let filter = {}
        filter[type] = event.target.value;

        props.addFilter(filter);
    }

    function getReadableValue(item) {
        return Config.mapping.lamp.value[type] ? Config.mapping.lamp.value[type][item.value] : item.value;

    }

    function sortFilter(a, b) {
        if(a.label !== null && b.label !== null) {
            return  a.label.localeCompare(b.label)
        }
        else {
            return false;
        }
    }

    return(
         <Form.Group controlId="beosztas">
            <Form.Label>{name}</Form.Label>
            <Form.Control as="select" className="form-control" placeholder="Osztály"
            onChange = { (event) => {setupFilter(event) } }
            value={props.isFiltering ? props.checkActiveFilters(type) : "null"}
            >
                <option value="null">----</option>
                {filterOption ? filterOption.sort((a,b) => {
                    return sortFilter(a, b);
                }).map((item) => {
                    return item.label !== null ? 
                    <option key={item.value+item.label}  value={item.value}>{getReadableValue(item)}</option> 
                    :
                    null
                }): 'loading'}
            </Form.Control>
        </Form.Group>
    );
}

/**
 * Documentation
 * @param {label, min, max, step, addFilter(), filterValue} props Props neccessary for Slider component to render, and filtervalue obj with the function. 
 */
export function FormGroupWrapperSlider(props) {

    return(
        <Form.Group controlId="beosztas">
            <Form.Label>{props.label}</Form.Label>
            <Slider min={props.min} max={props.max} step={props.step} addFilter={props.addFilter} filterValue={props.filterValue} isFiltering={props.isFiltering} checkActiveFilters={props.checkActiveFilters}></Slider>
        </Form.Group>
    );
}