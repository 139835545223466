export function downloadMap(olmap) {
    olmap.once('rendercomplete', function () {
        var mapCanvas = document.createElement('canvas');
        var size = olmap.getSize();
        mapCanvas.width = size[0];
        mapCanvas.height = size[1];
        var mapContext = mapCanvas.getContext('2d');
        
        Array.prototype.forEach.call(
            document.querySelectorAll('.ol-layer canvas'),
            function (canvas) {
                if (canvas.width > 0) {
                let opacity = canvas.parentNode.style.opacity;
                mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);
                let transform = canvas.style.transform;
                // Get the transform parameters from the style's transform matrix
                let matrix = transform
                    .match(/^matrix\(([^(]*)\)$/)[1]
                    .split(',')
                    .map(Number);
                // Apply the transform to the export map context
                CanvasRenderingContext2D.prototype.setTransform.apply(
                    mapContext,
                    matrix
                );
                mapContext.drawImage(canvas, 0, 0);
                }
            });
        if (navigator.msSaveBlob) {
            // link download attribuute does not work on MS browsers
            navigator.msSaveBlob(mapCanvas.msToBlob(), 'map.png');
        } else {
            let link = document.getElementById('image-download');
            link.href = mapCanvas.toDataURL();
            link.click();
        }
    });
    olmap.renderSync();
}