import React, { useState } from 'react'
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import connect from 'react-redux/es/connect/connect';
import { getProjectState, getActiveProject, getNavigationState, getMapLayerOptions } from '../state/reducers/ui';
import { Button } from 'react-bootstrap';
import { TaskUpdateController } from '../controllers/TaskUpdateController';

import moment from 'moment';

function ExportCSV(props) {

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
	const TaskUpdateControllerInst = TaskUpdateController.getInstance();

    const [exportLoading, setExportLoading] = useState(false);
    const [failedToExport, setFailedToExport] = useState([]);

    let projectName = ""

    function exportToCSV() {

        setExportLoading(true);

        if(props.type === 'project') {
            let all = {}

            TaskUpdateControllerInst.getTasksByProjectId(props.activeProject, null, null, props.navigationState.query.searchFilters, props.navigationState.query.term, props.layerOptions.searchFilters).then(response => {
                if(response.statusCode === 200){
                    response.data.data.forEach(task => {
                        let columnValue = {}
                        props.selectedvalues.map(column => {  // eslint-disable-line

                            if(task.hasOwnProperty(column.name)) {
                                    columnValue[column.label] = task[column.name]
                            }
                            else if(task.device.hasOwnProperty(column.name)) {
                                    columnValue[column.name] = task.device[column.name]
                            }else {
                                setFailedToExport(prev => {
                                    if(prev.includes(column.label)) {
                                        return prev
                                    }
                                    else {
                                        return [...prev, column.label]
                                    }
                                });
                            }
                            
                        });
                        projectName = task.title_p;

                        if(task.device.hasOwnProperty('cadastre_id')) {

                            if(all.hasOwnProperty(task.device.cadastre_id)) {
                                all[task.device.cadastre_id].push(columnValue);
                            }else {
                                all[task.device.cadastre_id] = [columnValue];
                            }
                        }else {
                            if(all.hasOwnProperty('project')) {
                                all.project.push(columnValue)
                            }else {
                                all['project'] = [columnValue]
                            }
                        }
                    });

                    createXLS(all, props.type);
                }
            });
        }else if(props.type === 'map') {
            
            let all = {}

            props.selectedFeatures.forEach(feature => {
                let columnValue = {}

                props.selectedvalues.map(column => {  // eslint-disable-line

                    if(feature.values_.hasOwnProperty(column.name)) {
                        columnValue[column.label] = feature.values_[column.name]
                    }
                    else if(feature.values_.hasOwnProperty('json')) {
                        if(feature.values_.json.hasOwnProperty(column.name)) {
                            columnValue[column.label] = feature.values_.json[column.name]
                        }
                    }
                    else {
                        setFailedToExport(prev => {
                            if(prev.includes(column.label)) {
                                return prev
                            }
                            else {
                                return [...prev, column.label]
                            }
                        });
                    }
                });

                if(all.hasOwnProperty(feature.values_.cadastre_id)) {
                    all[feature.values_.cadastre_id].push(columnValue);
                }else {
                    all[feature.values_.cadastre_id] = [columnValue];
                }
            });

            createXLS(all, props.type);

        }else {
            console.log("Invalid export type.")
        }
    };

    function createXLS(all, type) {

        let wbs = []
        Object.entries(all).forEach(([key, value]) => {
            let ws = XLSX.utils.json_to_sheet(value);
            wbs.push({id:key, ws:ws});

        });

        let wb = { Sheets: { }, SheetNames: [] };

        wbs.forEach(page => {
            if(page.id !== 'project') {
                wb.Sheets[props.cadastreData[page.id].cadastre_label.slice(0, 30)] = page.ws
                wb.SheetNames.push(props.cadastreData[page.id].cadastre_label.slice(0, 30))
            }else {
                wb.Sheets[page.id] = page.ws
                wb.SheetNames.push(page.id)
            }
        });

        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});

        FileSaver.saveAs(data, (type === 'project' ? projectName : "kataszter") + "_export_" + moment().format('YYYY-MM-DD-hh-mm-ss') + fileExtension);

        setExportLoading(false);
    }

    return (
        <div className="export-group">
            <Button disabled={props.disabled} variant="secondary" size="lg" block style={{borderRadius:'5px'}} onClick={() =>{exportToCSV()}} >{exportLoading ? <div className="loader-container-export"><div className="loader"></div></div> : 'Exportálás'}</Button>
            <div className="failed-to-export">
                {!exportLoading && failedToExport.length ? <p>A következőket nem sikerült exportálni : {failedToExport.map(x => {return <span>{x}, </span>})}</p> : null}
            </div>
        </div>

    )
}

const mapStateToProps = state => ({
    projectState: getProjectState(state),
    activeProject: getActiveProject(state),
    navigationState: getNavigationState(state),
    layerOptions: getMapLayerOptions(state),
});

export default connect(
    mapStateToProps,
)(ExportCSV);