import React, {useState, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Config from '../../config/config';
import { Button, Modal } from 'react-bootstrap';
import connect from 'react-redux/es/connect/connect';
import {getUserCadastreData} from '../../state/reducers/user';
import DropZoneUpload from '../../helpers/DropZoneUpload';

const Information = (props) => {


    const [popup, setPopup] = useState({visible: false, imageUrl: '', note:false, rotation:0});
    const [data, setData] = useState(false);

    useEffect(() => {
        if(props.data.device.cadastre_id !== null) {
            setData(true);
        }
    }, []) // eslint-disable-line

    //Setup base URL for the pictures folder.

    const imgURL = Config.static.img;
    const thumbnailURL = Config.static.imgThumbnail;

    let datasheet = false;
    let catalogue = false;

    function getTaskData() {
        if(props.cadastreData[props.data.device.cadastre_id]) {
            let form_json = props.cadastreData[props.data.device.cadastre_id].form_json.filter(x => x.input !== 'image' && x.input !== 'file');
            let task_device = props.data.device;
            return(
                form_json.map(field_data => { // eslint-disable-line
                    if(field_data.visible.visible_details) {
                        return(
                            <tr key={task_device[field_data.name]}><td className="key">{field_data.label}:</td><td>{checkValueMask(field_data, task_device[field_data.name])}</td></tr>
                        );
                    }
                })
            )
        }
        else {
            return(<p>Nincs megjelenitendő adat.</p>)
        }
    };

    //for future
    function checkValueMask(field_data, value) {
        if(value === null) {
            return "N/A";
        }else {
            if(field_data.hasOwnProperty("values")) {
                return value;
            }else {
                return value;
            }
        }
    };

    return (
        <div>
            {(props.data.lpt_id !== "" || Object.keys(props.data.device).length) ? <div className="infotable-wrapper">
            <div className="infotable row">
            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-9">
                        <h5>Készülék</h5>
                        <div className="table-wrapper">
                            <table>
                                <tbody>
                                    {data ? getTaskData() : <p>Nincs megjelitendő adat.</p>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-12 col-xs-12">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="documents">
                                    <h5>Csatolt dokumentumok</h5>
                                    {catalogue && <div><a rel="noopener noreferrer" target="_blank" href={catalogue}><i><FontAwesomeIcon icon={['fas', 'file'] }/></i>Katalógus</a></div>}
                                    {datasheet && <div><a rel="noopener noreferrer" target="_blank" href={datasheet}><i><FontAwesomeIcon icon={['fas', 'file'] }/></i>Adatlap</a></div>}
                                    {parseInt(props.data.act) === 1 ? <div><a href="#garancia"><i><FontAwesomeIcon icon={['fas', 'file'] }/></i>Garancia papír</a></div> : null}
                                    {(!parseInt(props.data.act) === 1  && !catalogue && !datasheet) ? <p>Nincsenek feltöltött dokumentumok.</p> : null}
                                </div>
                                <div className="images">

                                {(props.data.device.images !== null && props.data.device.images !== undefined) &&
                                    <div className="image">
                                        <h5>Képek</h5>
                                        {props.data.device.images.length ?<div className="img-grid">
                                            {props.data.device.images.map((image) => (
                                                <div stlye={{rotation:image.th_rotation}} key={image.file} onClick={() => setPopup({
                                                            visible: true,
                                                            // imageUrl: !props.data.status.action ? imgURL + image.file : oldImgURL + props.data.device.ref_spot + '.png',
                                                            imageUrl: imgURL + image.file,
                                                            rotation: image.rotation,
                                                            note:false
                                                        })} className="img" style={{backgroundImage: `url(${thumbnailURL + image.file})`, transform:'rotate(' + image.th_rotation + 'deg)'}} />
                                            ))}
                                            </div>
                                        :<p>Nincsenek megjelenitendő képek</p>}
                                    </div>
                                }
                                </div>
                            </div>
                            <div className="col-md-12">
                                <DropZoneUpload data={props.data} type="task" />
                            </div>  
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <Modal className="confirm" show={popup.visible} onHide={() => setPopup({visible: false, imageUrl: popup.imageUrl})} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                <img
                    className="img"
                    src={popup.imageUrl}
                    alt={props.data.task}
                    style={{"height" : "auto", "width" : "100%", transform:'rotate(' + popup.rotation + 'deg)' }}
                    />
                    {popup.note ? <p style={{padding:"10px"}}>Megjegyzés: {popup.note}</p> : null}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => {setPopup({visible: false, imageUrl: popup.imageUrl})}}>Bezár</Button>
                </Modal.Footer>
            </Modal>
            </div>:
            <p>Ehez a feladathoz nem tartozik készülék</p>}
        </div>
    );
};

const mapStateToProps = state => ({
    cadastreData : getUserCadastreData(state),
});


export default connect(
    mapStateToProps,
    null,
)(Information);
