import React from 'react';
import { Line } from 'react-chartjs-2'

export default function LineChart(props) {

    const options = {
        maintainAspectRatio: false,
        scales: {
            xAxes: [{
                display: true,
                ticks: {
                    callback: function(dataLabel, index) {
                        // Hide the label of every 2nd dataset. return null to hide the grid line too
                        return dataLabel;
                    }
                }
            }],
            yAxes: [{
                display: true,
                ticks: {
                    callback: function(dataLabel, index) {
                        return dataLabel
                    }
                }
            }]
    },
  }

    const data = {
        labels: props.labels,
            datasets: [
                {
                label: props.title,
                data: props.data,
                backgroundColor: 'rgb(255, 99, 132)',
                borderColor: 'rgba(255, 99, 132)',
            }
        ],
    }

    return(
        <div>
            <div className="chartinfo-doughnut">
                <p>{props.title}</p>
                {props.type !== 'owlet' && 
                <div className="button-group">
                    {props.types.map(type => {
                        return(
                            <button onClick={() => props.setDateType(type.value)} style={{backgroundColor: props.dateType === type.value ? '#a1a1a1' : '#c9c9c9'}}>{type.title}</button>
                        );
                    })}
                </div>}
            </div>
            <div style={{height:"300px"}}>
                <Line data={data} options={options} height={300}/>
            </div>

        </div>
    );
}
