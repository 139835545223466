import moment from "moment";

export const lightTimes = {
    "1":{
        "1":{
            "up":"16:25",
            "down":"7:00"
        },
        "2":{
            "up":"16:40",
            "down":"7:00"
        },
        "3":{
            "up":"16:50",
            "down":"6:55"
        }
    },
    "2":{
        "1":{
            "up":"17:05",
            "down":"6:40"
        },
        "2":{
            "up":"17:20",
            "down":"6:25"
        },
        "3":{
            "up":"17:35",
            "down":"6:00"
        }
    },
    "3":{
        "1":{
            "up":"17:50",
            "down":"5:45"
        },
        "2":{
            "up":"18:05",
            "down":"5:30"
        },
        "3":{
            "up":"18:20",
            "down":"5:10"
        }
    },
    "4":{
        "1":{
            "up":"18:40",
            "down":"4:45"
        },
        "2":{
            "up":"19:05",
            "down":"4:30"
        },
        "3":{
            "up":"19:10",
            "down":"4:15"
        }
    },
    "5":{
        "1":{
            "up":"19:25",
            "down":"3:50"
        },
        "2":{
            "up":"19:45",
            "down":"3:40"
        },
        "3":{
            "up":"19:50",
            "down":"3:25"
        }
    },
    "6":{
        "1":{
            "up":"20:05",
            "down":"3:10"
        },
        "2":{
            "up":"20:05",
            "down":"3:10"
        },
        "3":{
            "up":"20:05",
            "down":"3:10"
        }
    },
    "7":{
        "1":{
            "up":"20:05",
            "down":"3:20"
        },
        "2":{
            "up":"20:00",
            "down":"3:30"
        },
        "3":{
            "up":"19:50",
            "down":"3:40"
        }
    },
    "8":{
        "1":{
            "up":"19:30",
            "down":"3:55"
        },
        "2":{
            "up":"19:15",
            "down":"4:05"
        },
        "3":{
            "up":"19:00",
            "down":"4:20"
        }
    },
    "9":{
        "1":{
            "up":"18:35",
            "down":"4:35"
        },
        "2":{
            "up":"18:15",
            "down":"4:50"
        },
        "3":{
            "up":"17:55",
            "down":"5:05"
        }
    },
    "10":{
        "1":{
            "up":"17:30",
            "down":"5:20"
        },
        "2":{
            "up":"17:20",
            "down":"5:35"
        },
        "3":{
            "up":"16:55",
            "down":"5:45"
        }
    },
    "11":{
        "1":{
            "up":"16:35",
            "down":"6:05"
        },
        "2":{
            "up":"16:25",
            "down":"6:20"
        },
        "3":{
            "up":"16:20",
            "down":"6:30"
        }
    },
    "12":{
        "1":{
            "up":"16:10",
            "down":"6:45"
        },
        "2":{
            "up":"16:10",
            "down":"6:55"
        },
        "3":{
            "up":"16:15",
            "down":"7:00"
        }
    }
}

export function getLightTimes() {
    let monthNum = moment().format("M");
    let dayNum = moment().format("D");

    if(dayNum < 10) {
        return lightTimes[monthNum]["1"]
    }
    else if(dayNum > 10 && dayNum < 20) {
        return lightTimes[monthNum]["2"]
    }
    else {
        return lightTimes[monthNum]["3"]
    }
}