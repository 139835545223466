import React from 'react';

const Progress = (props) => {
    return (
		<React.Fragment>
			<div className="info-wrapper">
				<div className="percentage">
					<div className="percent-number" style={{marginRight: "10px"}}>{props.info}</div>
					<div className="percent-holder">
						{
							props.mainProgress === true ? <div className="percent" style={{width:props.value+'%', backgroundColor:  props.value <= 25 ? '#d17373' : props.value <= 75 ? '#d2cd72' : ' #7ED173' }} />
							: <div className="percent" style={{width:props.value+'%'}} />
						}
					</div>
					{
						props.mainProgress === true ? <div className="percent-value" style={{color:  props.value <= 25 ? '#d17373' : props.value <= 75 ? '#d2cd72' : ' #7ED173' }}><em>{props.value}%</em></div>
						: <div className="percent-value"><em>{props.value}%</em></div>
					}
					
				</div>
			</div>
		</React.Fragment>
    );
};

Progress.propTypes = {};

export default Progress;
