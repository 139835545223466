import React from "react";
import { first } from 'lodash/fp';
import Config from '../../../config/config';

export function getTaskPercentage(leafValues) {
    let approvedValues = 0;
    leafValues.forEach((value) => {
        if(value){
            // eslint-disable-next-line
            if(value != 2 && value != -1) {
                approvedValues++;
            }
        }
    })
    return approvedValues ? Math.round((approvedValues/leafValues.length)*100) : 0
}

export function getTaskFirst(leafValues) {
    return first(leafValues)
}

export function getStatus(leafValues) {
    let planned = 0;
    let assessed = 0;
    let upgraded = 0;

    leafValues.forEach(element => {
        if(element === "Felszerelés") {
            planned += 1
        }else if(element === 'Leszerelés'){
            assessed += 1
        }else{
            upgraded += 1
        }
    });

    return (<span><span className="planned"> {planned} </span> / <span className="assessed"> {assessed} </span> / <span className="upgraded"> {upgraded} </span></span>)
}

export function modifySelectedData(data) {
    let taskIds = []
    let updateableTasks = [];
    data.forEach(element => {
        if(element.isGrouped) {
            element.leafRows.forEach(row => {
                updateableTasks.push(row.original)
                taskIds.push(row.original.id)
            })
        }else {
            if(!taskIds.includes(element.original.id))
            {
                updateableTasks.push(element.original)
                taskIds.push(element.original.id)
            }
        }
    })
    return updateableTasks;
}

export function setUpDriverParam(leafValues) {
    let value = ""
    leafValues.forEach((elem) => {
        if(!value.includes(Config.mapping.lamp.value["driver"][elem])) {
            const label = Config.mapping.lamp.value["driver"][elem]
            if(label) {
                value = value + "/" + label

            }
        }
    })
    return value.substring(1, value.length);
}

export function setUpPlannedPart(leafValues) {
    let value = ""
    leafValues.forEach((elem) => {
        if(!value.includes(elem)) {
            value = value + "/" + elem

        }
    })
    return value.substring(1, value.length);
}