import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function ProjectSidebarHeader(props) {

    return(
        <div className="card-header">
            <div onClick={() => {
                props.setActiveLayout(null)
                props.projectSidebarToggle();

            }}>
            </div>
            <div>
                {props.activeLayout === 'filter'&& <span>{ props.type === 'map' ? 'Térkép' : 'Projektek'} beállításai</span>}
                {props.activeLayout === 'layer' && <span>Kataszterek láthatósága</span>}
                {props.activeLayout === 'project' && <span>Projektek láthatósága</span>}
                {props.activeLayout === 'tile' && <span>Háttér Rétegek láthatósága</span>}
            </div>
            <i className="}icon close-icon" onClick={() =>
                {
                    props.projectSidebarToggle();
                    props.setActiveLayout(null);

                }}>
                <FontAwesomeIcon icon={['far', 'chevron-right']} />
            </i>
        </div>
    );
}
